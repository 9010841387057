@import "@/assets/scss/main.scss";

.lightbox {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  background: rgba(0, 0, 0, 0.8);
}

.v-zoomer {
  width: 750px;
  height: 750px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.zoom {
  div + div {
    margin-left: 20px;
  }
}

.icon {
  cursor: pointer;
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    color: $blue-mayday;
  }
}

.close-lightbox {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;

  &__icon {
    cursor: pointer;
    color: white;
    width: 2rem;
    height: 2rem;

    &:hover {
      color: $blue-mayday;
    }
  }
}
