@import "@/assets/scss/main.scss";

.select-warning {
  width: 100%;
  padding: 4px;
  background-color: $grey-1-mayday;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
  margin: 8px 0px;
}
