@import "@/assets/scss/main.scss";

.content__list {
  padding-inline-start: 0px;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}
