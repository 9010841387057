@import "@/assets/scss/main.scss";

.version-content-header {
  background-color: white;
  padding-bottom: 8px;
}

.content-version-detail {
  height: 60vh;
  overflow: auto;
}

.version-text {
  border-left: 2px solid $blue-mayday;
  padding: 5px 15px;
}
