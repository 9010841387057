@import "@/assets/scss/main.scss";

:deep() .modal-dialog {
  width: 559px;
}

:deep() .modal-content {
  min-height: 60vh;
  max-height: 70vh;
}

:deep() .modal-body {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 16px 16px 0px 16px;
  overflow: auto;
}

:deep() .modal-header {
  background-color: $grey-1-mayday;
  padding: 16px;
}
:deep() .modal-footer {
  background-color: $grey-1-mayday;
  padding: 16px;
}
.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.attribute-selection-main {
  max-height: 70vh;
  width: 100%;
}

.case-selection-overflow {
  max-height: 25vh;
}

.add-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $green-mayday;
  }
}

.remove-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $red-mayday;
  }
}

.hover:hover {
  color: $blue-mayday;
}

.empty-state-img {
  height: 100px;
}

.empty-state-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 30px;
}

.empty-state-title {
  font-size: 16px;
  font-weight: 700;
}
.empty-state-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}
