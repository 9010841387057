@import "@/assets/scss/main.scss";

.parameters-selection-main {
  max-height: 65vh;
  overflow: auto;
}

.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.attribute-selection-main {
  max-height: 60vh;
  overflow: auto;
}

.case-selection-overflow {
  max-height: 25vh;
  overflow: auto;
}

.add-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $green-mayday;
  }
}

.remove-case {
  :deep() .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $red-mayday;
  }
}
