@import "@/assets/scss/main.scss";

.toast {
  max-width: 250px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $red-mayday;
  z-index: 10001;

  &__body {
    padding: 8px;
    background: rgba($red-mayday, 0.1);
  }
}
