@import "@/assets/scss/main.scss";

.ask-answer-stream {
  text-align: justify;
  padding-right: 22px;
  color: $grey-8-mayday;
  font-size: 14px;
  max-height: fit-content;
  overflow: scroll;
}
