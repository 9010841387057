@import "@/assets/scss/main.scss";

.card-row {
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
}

.icon-div {
  width: 16px;
}

.root-folder-card-icon {
  max-width: 12px;
  max-height: 12px;
}

.item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  max-width: 200px;
}

.isDraft .item-label {
  color: $grey-5-mayday;
}

.node-status {
  position: relative;
  z-index: 1;
  right: 0;
}
