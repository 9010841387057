@import "@/assets/scss/main.scss";

.plus-display {
  background-color: $grey-2-mayday;
  border-radius: 4px;
  height: 18px;
  font-size: 12px;
}
.content-wrapper {
  font-size: 14px;
  line-height: 17px;
}
.content {
  max-width: 100%;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-actions-icon {
  width: 12px;
  height: 12px;
  color: $grey-7-mayday;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
    background-color: $grey-4-mayday;
    border-radius: 4px;

    .expand {
      color: $blue-mayday;
    }
    .trash {
      color: $red-mayday;
    }
  }
}

.checked {
  background-color: $grey-1-mayday;
}

.empty-case {
  color: $grey-6-mayday;
}

.user-column {
  max-width: 70%;
}

.initials-wrapper {
  width: 20px;
  height: 20px;

  border-radius: 2px;

  font-size: 12px;
  font-weight: 700;
  margin-right: 4px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

:deep() .el-checkbox__input {
  display: flex;
  align-items: center;
}

.pending {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  padding: 0 6px;
  background: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 2px;
  margin: 0 auto;
}

.expired {
  background: rgba($red-mayday, 0.1);
  color: $red-mayday;
}

.status {
  display: flex;
  gap: 4px;
  align-items: center;
}
