@import "@/assets/scss/main.scss";

.wrapper {
  padding: 16px;
}
.content-preview-ask-items {
  padding: 4px 18px 12px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
}
.header-wrapper {
  width: 100%;
  display: flex;
}
.header {
  width: 50%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $grey-5-mayday;
  padding: 4px;
  color: $grey-8-mayday;
  font-weight: 700;
  text-wrap: nowrap;
}
.header:hover {
  cursor: pointer;
}
.header-activated {
  border-bottom: 2px solid $purple-5-mayday;
  color: $purple-5-mayday;
}
.content {
  text-align: justify;
  font-size: 14px;
  ul {
    padding-left: 20px;
  }
}
