@import "@/assets/scss/main.scss";

.feedback-indicator {
  background-color: $blue-mayday;
}

.el-popconfirm {
  :deep() p {
    font-size: 10px;
  }
}

.unclickable {
  cursor: auto;
}

.content-label-icon {
  width: 12px;
  color: var(--color);
  background-color: rgba(var(--color), 0.1);
  border-radius: 2px;
}

.tags-for-node {
  width: 8px;
  height: 8px;
  border-radius: 3px;
}
