@import "@/assets/scss/main.scss";

.trigger-slideshow {
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.product-viewer-aside__carousel {
  cursor: pointer;
  width: 100%;
  height: auto;
}
