@import "@/assets/scss/main.scss";

.content-label-icon {
  max-width: 24px;
  font-size: 20px;
  color: var(--label-color);
  background-color: rgba(var(--label-color), 0.1);
  border-radius: 2px;
  padding: 2px;
}

.item-label-name {
  &:hover {
    color: var(--label-color);
  }
}
