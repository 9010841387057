@import "@/assets/scss/main.scss";

.cascader-container {
  width: 100%;
  background: white;
  border-radius: 2px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.cascader-header {
  padding: 14px 10px;
  background-color: $grey-2-mayday;

  &.is-init {
    color: $grey-6-mayday;
  }
}
.option {
  font-size: 12px;
  padding: 12px 10px;
  cursor: pointer;
  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}
.option:not(.is-forbidden):hover {
  color: $blue-mayday !important;
  background-color: $grey-1-mayday;
}
.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &__selector {
    border: 1px solid $blue-mayday;
    border-radius: 1px;
  }
}
.grey-6 {
  color: $grey-6-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
.cascader-footer {
  padding: 8px 16px 8px 16px;
  background-color: $grey-2-mayday;
}
.instruction {
  padding: 12px 14px;
  font-weight: bold;
}
.overflow {
  height: 40vh;
  max-height: 200px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.is-forbidden {
  color: $grey-5-mayday;
  background-color: $grey-1-mayday;
}
.breadcrumb-overflow {
  padding: 0px 10px;
}
.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}

.search-section {
  height: 38px;
  background-color: $grey-2-mayday;
}

.prev-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.75px;
  height: 8.5px;
}

.prev-wrapper:hover {
  color: $blue-mayday;
  cursor: pointer;
}
.chevron {
  width: 80%;
  height: 100%;
}
