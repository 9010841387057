@import "@/assets/scss/main.scss";

.horizontal-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.state-info {
  font-size: 12px;
}

.icon-state-info {
  color: $grey-4-mayday;
}

.active {
  color: $green-mayday !important;
}
