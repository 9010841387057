@import "@/assets/scss/main.scss";

.nav-row {
  padding: 2px 4px;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }

  .nav-row-icon {
    height: 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    color: $grey-5-mayday;
    * {
      width: 20px;
    }
  }

  .nav-row-title {
    font-size: 12px;
    margin-left: 8px;
  }
}
