@import "@/assets/scss/main.scss";

.activation-settings-container {
  width: 100%;
}

.item-status-description {
  margin-left: 4px;
}

.item__icon {
  color: $grey-6-mayday;
}

.item__icon.active {
  color: $blue-5-mayday;
}
