@import "@/assets/scss/main.scss";

label {
  margin-bottom: 0px;
}
.parameter-row {
  padding-right: 0px;

  :deep() .el-select__input {
    margin-left: 0px;
  }
}
