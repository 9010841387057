@import "@/assets/scss/main.scss";

.case-viewer-children {
  .case-viewer-children__list {
    padding-inline-start: 0px;

    .case-viewer-children__item {
      list-style: none;
    }
  }
}
