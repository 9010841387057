@import "@/assets/scss/main.scss";

.plus-display {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 4px;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.entire-label-list {
  width: 100%;
  gap: 4px;
}
.first-display {
  gap: 4px;
  width: 100%;
}
