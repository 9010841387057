@import "@/assets/scss/main.scss";

.ask-header {
  display: flex;
  flex-direction: column;
}
.ask-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 26px;
}

.title-and-filters {
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 50px);
  align-items: center;
  justify-content: flex-start;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  overflow: hidden;
}
.title {
  color: $purple-5-mayday;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.subtitle {
  @extend .title;
  font-weight: 400;
  padding-left: 2px;
}
.ask-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.btn-close {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  justify-content: center;
  background: $purple-5-mayday;
  cursor: pointer;
}
.btn-close-icon {
  color: white;
  height: 12px;
  width: 12px;
}
.btn-filters {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  &__show {
    background-color: $purple-2-mayday;
  }
  :hover {
    background-color: white;
  }
}
.btn-close-icon {
  color: white;
  height: 12px;
  width: 12px;
}
.btn-filters-icon {
  color: $purple-5-mayday;
  height: 10px;
  width: 10px;
}
.cancel-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 14px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}
.cancel-as-esc {
  padding: 2px 5px 2px 5px;
  font-size: 12px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: rgba($purple-5-mayday, 0.2);
  font-weight: 400;
}

.ask-filters {
  padding-top: 16px;
  margin-top: 16px;
}
