@import "@/assets/scss/main.scss";

.subitem-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.state-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $grey-4-mayday;
  display: inline-block;
  &.full {
    background-color: $green-mayday;
  }
  &.partial {
    background-color: $yellow-mayday;
  }
  &.scoped {
    background-color: $blue-mayday;
  }
  margin-right: 2px;
}
.subitem-title {
  font-size: 14px;
}

.subitem-title-hidden {
  filter: grayscale(1);
  opacity: 0.5;
}
