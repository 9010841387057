@import "@/assets/scss/main.scss";

.templates-view-container {
  height: calc(100vh - 68px);
  padding-bottom: 64px;
  background-color: white;
  box-shadow: $shadow-mayday;
  overflow-y: auto;
}

.templates-view-main {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.templates-view-header {
  .templates-view-icon {
    .icon-wrapper {
      color: $grey-4-mayday;
      font-size: 42px;
      border: 4px solid $grey-2-mayday;
      border-radius: 4px;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .templates-view-title {
    margin-top: 4px;
    font-size: 48px;
    font-weight: bold;
    color: black;
  }

  .templates-view-recap {
    display: flex;
    gap: 8px;
    margin-bottom: 32px;
    .recap-item {
      display: flex;
    }
  }
}

.templates-view-empty {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  padding: 42px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .icon {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}
