@import "@/assets/scss/main.scss";

.context-variables-drawer {
  width: 100%;
  &__editor {
    &__variables {
      flex-direction: column;
      align-content: space-between;
      max-height: 70vh;
      overflow: auto;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white;
      }

      &__add {
        width: fit-content;
      }
    }
  }
  &__submission {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
