@import "@/assets/scss/main.scss";

.template-navigation-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 16px;
  height: calc(100vh - 68px);
  background-color: white;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
  border-right: 1px solid $grey-2-mayday;
}

.template-navigation-items {
  flex: 1 1 0%;
}
