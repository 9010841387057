@import "@/assets/scss/main.scss";

.synonyms-table {
  position: relative;
  &-header {
    height: 38px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    gap: 8px;
    background-color: $grey-3-mayday;
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding: 12px 34px;
    &-col-main {
      width: 200px;
      flex: 0 0 auto;
    }
    &-col-synonyms {
      width: 100%;
    }
    &-col-actions {
      justify-content: flex-end;
    }
  }
  &-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: white;
    border-bottom: 1px solid $grey-5-mayday;
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 8px;
        font-size: 14px;
        color: black;
      }
      .description {
        margin-bottom: 8px;
        font-size: 12px;
        color: $grey-7-mayday;
      }
      .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
