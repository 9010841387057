@import "@/assets/scss/main.scss";

.thread__author {
  &-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 2px;
    flex: none;
    &-sm {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
    &-md {
      width: 24px;
      height: 24px;
      font-size: 14px;
    }
  }
}
