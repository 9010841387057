@import "@/assets/scss/main.scss";

.category {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase;

  &__label {
    font-weight: bold;
    font-size: 10px;
  }
  &__tag {
    display: inline-flex;
    align-items: center;
    font-size: 8px;
    margin-left: 4px;
    padding: 0 4px;
    border-radius: 50px;
    background-color: $blue-5-mayday;
    color: white;
  }
}

button.selected {
  border: 1px solid black;
}

.single-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-color: transparent;
  border: 0.8px solid transparent;

  &:hover {
    border-color: $grey-6-mayday;
  }
}

.empty-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  color: $grey-6-mayday;
}
