@import "@/assets/scss/main.scss";

.product-agora-documentation {
  .is-disabled {
    :deep() .el-collapse-item__header {
      color: $grey-6-mayday;
      background-color: $grey-1-mayday;
      display: flex;
      justify-content: space-between;
      padding-right: 9px;

      .el-collapse-item__arrow {
        display: none;
      }
      .product-agora-documentation__tech-ref__disabled {
        display: inline;
      }
    }
  }

  &__tech-ref {
    :deep() .el-collapse {
      border-top: none;
      border-bottom: none;
    }

    :deep() .el-collapse-item__header {
      height: var(--techRefHeight);
      color: $grey-8-mayday;
      font-size: 12px;
      padding-left: 4px;
    }

    &__title {
      height: var(--techRefHeight);
      line-height: 0px;
      display: flex;
      align-items: center;
    }

    &__disabled {
      display: none;
    }
  }

  &__wrapper {
  }

  &__category {
    display: flex;
    flex-direction: column;

    &.is-active {
      :deep() .product-agora-documentation__category__title {
        &-plus {
          display: none;
        }
        &-minus {
          display: inline;
        }
      }
    }

    :deep() .el-collapse-item__wrap {
      border-bottom: none;
    }

    :deep() .el-collapse-item__header {
      border-bottom: none;
      height: var(--categoryHeight);
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 4px 9px 4px 6px;
      margin-bottom: 4px;

      .el-collapse-item__arrow {
        display: none;
      }

      h6 {
        margin: 0px;
        color: inherit;
        font-size: var(--categoryFontSize);
        width: 90%;
        overflow: hidden;

        span {
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }
      }
    }

    &__title {
      &-plus {
        display: inline;
      }
      &-minus {
        display: none;
      }
    }
    &__document {
      height: var(--documentHeight);
      display: flex;
      width: 100%;
      background-color: $blue-mayday;
      padding: 2px 6px;
      border-radius: 4px;
      color: white;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
      &:hover {
        cursor: pointer;
      }

      small {
        font-size: var(--documentFontSize);
        width: 90%;
        overflow: hidden;

        span {
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }
      }
    }
  }
}

.scroll-on-hover {
  &:hover {
    animation: scroll 5s linear infinite;
  }
}

.scroll {
  animation: scroll 5s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* Défilement au début */
  }
  100% {
    transform: translateX(-75%); /* Défilement complet vers le haut */
  }
}
