@import "@/assets/scss/main.scss";

.query-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;
}

.query {
  font-size: 14px;
  font-weight: 600;
  background-color: $purple-5-mayday;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  width: fit-content;

  &.expanded {
    border-radius: 8px 8px 0 0;
    margin-bottom: -8px;
    padding-bottom: 16px;
  }

  &.error {
    background-color: $red-5-mayday;
  }
}

.error-container {
  background-color: $red-1-mayday;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.response-container {
  background-color: $grey-2-mayday;
  border-radius: 8px;
  padding: 8px 16px 16px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.response-text {
  .link {
    color: $purple-5-mayday;
    font-size: 8px;
    border: 1px solid $purple-5-mayday;
    border-radius: 4px;
    padding: 2px 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;

    &.hovered {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}

.response-links {
  gap: 4px;
  display: flex;
  align-items: center;
  width: 100%;

  .links-container {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    overflow: auto;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid $purple-5-mayday;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    color: $purple-5-mayday;
    cursor: pointer;
    transition: all 0.2s ease;
    max-width: fit-content;
    overflow: hidden;
    flex: 1;
    position: relative;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.hovered {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}

.infos-container {
  color: $grey-6-mayday;
  font-size: 10px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
}

.loading-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $grey-5-mayday;
  animation: loading 1s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
