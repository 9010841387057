@import "@/assets/scss/main.scss";

small {
  color: $grey-5-mayday;
}
.inactive:hover {
  color: $blue-mayday;
}
.active {
  color: $blue-mayday;
}
