@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.text-input {
  font-size: 12px;
  height: 24.2px;
}
:deep() .el-input.is-disabled .el-input__inner {
  color: $grey-7-mayday;
}
:deep() .el-input .el-input__inner {
  height: 100%;
}
