@import "@/assets/scss/main.scss";

.mayday-notification-editor {
  padding: 2px 12px;

  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 5px;
  }

  :deep(.mayday-editor__content__ProseMirror__link) {
    padding: 0 !important;
  }

  :deep(.mayday-editor__content__ProseMirror__link-favicon) {
    width: 0 !important;
    margin: 0 !important;
  }
}

.mayday-contribution-editor {
  :deep() div[id^='tippy-'],
  :deep() .tippy-content,
  :deep() .tippy-box,
  :deep() .mayday-editor__bubble-menu-text-selection {
    visibility: hidden !important;
    z-index: 99999;
  }
}
