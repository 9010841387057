@import "@/assets/scss/main.scss";

.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.preview-collapse-wrapper {
  padding-block: 8px;
  padding-inline: 16px;
  border: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
}
.preview-collapse-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.preview-collapse-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview-icon-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: white;
}

.preview-icon {
  padding: 2px;
  font-size: 20px;
}

.collapse-icon {
  &.active {
    transform: rotate(180deg);
  }
}
.preview-collapse-body {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.zendesk-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.preview-collapse-general-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-block: 1px solid $grey-4-mayday;
  padding-block: 8px;
}

.preview-collapse-macro-id-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: rgba($blue-mayday, 0.8);
  }
}
.general-data-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.general-data-row {
  display: flex;
  gap: 16px;
}

.general-data-title {
  font-weight: bold;
  color: $grey-5-mayday;
  width: 80px;
}

.preview-collapse-macro-fields {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actions-preview-title {
  font-weight: bold;
}
.macro-action-row {
  display: flex;
  gap: 8px;
}
.macro-actions-wrapper {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.macro-actions-title {
  font-weight: bold;
  color: $grey-5-mayday;
  text-transform: uppercase;
  width: 130px;
  word-wrap: break-word;
}
.macro-actions-value {
  word-break: break-word;
}
