@import "@/assets/scss/main.scss";

.options_selection {
  max-width: 15px;

  &:hover {
    cursor: not-allowed;
  }
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
}

.role-name {
  width: 250px;
}

.members-count {
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}

.options {
  text-align: center;
  width: 70px;
}
