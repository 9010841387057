@import "@/assets/scss/main.scss";

.contributors__container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  min-width: 0;
}

.initials-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 4px;

  .initials-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 2px solid white;
    margin-left: -6px;
    transition: margin-left 0.2s ease-in-out;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .contributors__more {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    outline: 2px solid white;
    background: $grey-5-mayday;
    margin-left: -6px;
    transition: width 0.2s ease-in-out, margin-left 0.2s ease-in-out;

    .count,
    .see-more {
      transition: display 0.2s ease-in-out;
    }
    .see-more {
      display: none;
    }
  }

  &.reactive:hover {
    .initials-wrapper {
      margin-left: -12px;
      &:first-of-type {
        margin-left: 0;
      }
    }
    .contributors__more {
      width: 42px;
      margin-left: -12px;
      .count {
        display: none;
      }
      .see-more {
        display: block;
      }
    }
  }
}

.author {
  font-weight: 700;
  color: $grey-8-mayday;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.contributors__infos {
  display: flex;
  flex-direction: column;
  color: $grey-7-mayday;
  min-width: 0;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  b {
    font-weight: 700;
    color: $grey-8-mayday;
  }

  span {
    text-overflow: ellipsis;
  }

  .step-details {
    font-size: 12px;
    color: $grey-9-mayday;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    max-width: 100%;
    &:hover {
      color: $blue-mayday;
    }
  }
}
