@import "@/assets/scss/main.scss";

.languages-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1px;
  padding: 6px 8px;
  border-radius: 2px;
  color: black;
  background-color: $grey-1-mayday;
  cursor: pointer;

  &:hover {
    background-color: $grey-2-mayday;
  }

  &.open {
    background-color: $grey-2-mayday;
  }
}

.chevron-icon {
  font-size: 10px;
  width: 11px;
}

.dropdown {
  :deep() .dropdown-menu {
    width: 270px;
    padding: 0px !important;
    border: 1px solid $grey-3-mayday;
    box-shadow: $shadow-mayday;
    border-radius: 4px;
    overflow: hidden;
  }
}

.leading-icon {
  line-height: 12px;
}

.flag-emoji {
  font-size: 18px;
}
