@import "@/assets/scss/main.scss";

.add-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
  gap: 8px;
  border: 1px dashed $grey-2-mayday;
  border-radius: 4px;
  color: $blue-mayday;

  &:hover {
    cursor: pointer;
    border-color: $blue-mayday;
  }
}
