@import "@/assets/scss/main.scss";

#step {
}
.progress-bar {
  width: 100%;
  border-radius: 9999px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 12px;
  background: $grey-4-mayday;
}
.progress-bar > div {
  border-radius: 9999px;
  position: absolute;
  height: 100%;
  background-color: $unknown-blue-3-mayday;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
