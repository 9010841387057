@import "@/assets/scss/main.scss";

.search-stat__search-table {
  width: 100%;
  background: transparent;

  p {
    font-weight: 400;
    color: $grey-6-mayday;
  }

  :deep() .el-table,
  :deep() .el-table th,
  :deep() .el-table tr {
    background-color: transparent;
  }

  :deep() .el-table th {
    border-top: 0;
    font-weight: 700;
    font-size: 0.9em;
    color: $grey-6-mayday;
  }

  :deep() .el-table tr {
    font-weight: 600;
    color: $unknown-blue-3-mayday;
  }
}

.search-stat-card__table-illustration {
  width: 100%;
}

.icon-button {
  color: $blue-mayday;
  border: none;
}
