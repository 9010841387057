@import "@/assets/scss/main.scss";

.version-left-side {
  border-right: 1px solid $grey-4-mayday;
  padding-left: 35px;
  padding-right: 35px;
}

.loader-state {
  height: 60vh;
  width: 100%;
}

.content-version-modal {
  :deep() .modal-body {
    overflow-y: hidden;
  }
}
