@import "@/assets/scss/main.scss";

.drawer-header {
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    padding: 4px;
    &_diag {
      border: 1px solid $blue-mayday;
      background-color: rgba($blue-mayday, 0.1);
    }

    &_article {
      border: 1px solid $yellow-mayday;
      background-color: rgba($yellow-mayday, 0.1);
    }

    img {
      width: 16px !important;
      height: 16px !important;
    }
  }
  .info {
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.2;
    }
  }
}
