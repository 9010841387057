@import "@/assets/scss/main.scss";

.group-user-count {
  max-width: 75px;
  cursor: pointer;
}
.flag-icon {
  max-width: 12px !important;
}

.group-card {
  :deep() .card-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.main-icon {
  border: 1px solid;
  border-color: $grey-6-mayday;
  color: $grey-6-mayday;
  background-color: #7d89a01a;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding: 5px;
}

.group-card:hover {
  :deep() .main-icon {
    border-color: $blue-mayday;
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}

.small-icon {
  height: 14px;
  color: $grey-5-mayday;
}

.settings-icon {
  height: 18px;
  width: 18px;
  color: $grey-5-mayday;
  padding: 2px;
  border: 1px solid transparent;
}

.settings-icon:hover {
  border-radius: 4px;
  border-color: $blue-mayday;
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
}

.restriction-display {
  margin-left: -8px;
}
