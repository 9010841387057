@import "@/assets/scss/main.scss";

.icon-wrapper {
  margin-top: 1px;
}
.icon {
  width: 20px;
  height: 20px;
}
.step-description {
  font-size: 14px;
  color: $grey-6-mayday;
}
.step-title {
  font-weight: bold;
}
.steps {
  padding: 15px;
}
.step {
  margin-bottom: 10px;
}
.todo {
  color: $grey-4-mayday;
}
.failed {
  color: $red-mayday;
}
.passed {
  color: $blue-mayday;
}
.loading {
  color: $blue-mayday;
}
.impossible {
  color: $grey-4-mayday;
}
.header {
  padding: 11px;
  border-bottom: 1px solid $grey-4-mayday;
  margin-bottom: 10px;
}
.header-date {
  font-size: 12px;
  color: $grey-6-mayday;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
}
.tag {
  box-sizing: border-box;
  border-radius: 2px;

  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}
.doing {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  color: $blue-mayday;
}
.failed {
  background-color: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  color: $red-mayday;
}
.released {
  background-color: rgba($green-mayday, 0.1);
  border: 1px solid $green-mayday;
  color: $green-mayday;
}
.https-warning {
  margin: 0px 15px;
  border-radius: 4px;
  padding: 8px;
  &.error {
    background-color: rgba($red-mayday, 0.1);
    color: $red-mayday;
  }
  &.loading {
    background-color: rgba($blue-mayday, 0.1);
    color: $blue-mayday;
  }
}

.https-warning-icon {
  &.error {
    color: $red-mayday;
  }
  &.loading {
    color: $blue-mayday;
  }
}
