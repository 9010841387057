@import "@/assets/scss/main.scss";

.hub-drawer-header {
  max-height: 8.5rem;
  background-color: $grey-1-mayday;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 200px;
  flex: 0 0 auto;
}

.hub-drawer-header-info {
  width: 100%;
  .title {
    color: black;
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;
    padding-right: 24px;
    border-radius: 8px;

    &.error {
      border: 1px solid red;
    }
  }
  .description {
    color: $grey-8-mayday;
    font-size: 75%;
    &.error {
      color: $red-5-mayday;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.hub-drawer-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  box-shadow: $shadow-sm-mayday;
  left: 30px;
  z-index: 1;

  .integration-icon {
    object-fit: contain;
    color: $grey-6-mayday;
    font-size: 80px;
  }
}
