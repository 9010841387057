@import "@/assets/scss/main.scss";

.analytics-usage-stats-card {
  margin-bottom: 24px;

  .adoption-rate-chart-overview-container {
    text-align: right;
    cursor: pointer;
    max-height: 60px;
    &:hover {
      filter: saturate(2.2);
      color: $blue-mayday;
    }
    .adoption-rate-chart-modal-link {
      position: absolute;
      margin-left: auto;
      max-height: 12px;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  // ! TEMPORARY
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 25%;
  }
}
.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}
.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}
.header-icon {
  color: $grey-5-mayday;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
