@import "@/assets/scss/main.scss";

.preview-window {
  max-height: 40vh;
  overflow: auto;
}
.preview-label {
  font-size: 12px;
  color: $grey-5-mayday;
  font-weight: bold;
}
.preview-image {
  max-width: 280px;
  border: 0.5px solid $grey-5-mayday;
  border-radius: 4px;
}
