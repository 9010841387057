@import "@/assets/scss/main.scss";

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.explain-collapse :deep() {
  border: none;
  .no-arrow {
    .el-collapse-item__arrow {
      display: none;
    }
    .el-collapse-item__header {
      color: $grey-9-mayday;
      cursor: default;
      &:hover {
        color: $grey-9-mayday;
      }
    }
  }
  .el-collapse-item__header {
    border: none;

    height: 24px;
    &:hover {
      color: $blue-mayday;
    }

    .collapse-title {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      align-items: center;
      display: inline-flex;
      gap: 8px;
    }
  }

  .el-collapse-item__content {
    float: right;
    width: 100%;
    padding-left: 8px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
.close-icon {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
