@import "@/assets/scss/main.scss";

.first-column-header {
  width: 80%;
}
.channel-icon {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-4-mayday;
  color: white;
}

tr.space-under > th {
  padding-bottom: 1em;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.preferences-table {
  width: 100%;
  margin-top: -48px;
}

.checkbox-wrapper {
  display: flex;
  justify-content: center;
}

.checkbox-button {
  display: inline;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
