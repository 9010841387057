@import "@/assets/scss/main.scss";

.quick-filter-wrapper {
  gap: 8px;
  align-items: center;
  max-width: calc(100% - 150px);
  overflow: scroll;
  scrollbar-width: thin;
}
.quick-filter {
  gap: 8px;
  padding: 2px 6px;
  border-radius: 8px;
  background-color: $grey-2-mayday;
  height: 25px;
  font-size: 12px;
  border: 1px solid transparent;
  &__selected {
    color: $blue-mayday;
    border: 1px solid $blue-mayday;
  }
}
.filter-title {
  height: 25px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}
.icon {
  height: 25px;
}
