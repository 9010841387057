@import "@/assets/scss/main.scss";

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.product__list {
  margin-left: 24px;
  padding-left: 0px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__attached__collapsable,
.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    border-left: 2px solid $grey-4-mayday;
    padding: 4px 12px;
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    &:hover {
      border-color: $yellow-mayday;
      background-color: $grey-1-mayday;
    }
  }
}

.content-icon {
  max-height: 16px;
  max-width: 16px;
}

.empty-state-alert {
  color: $grey-5-mayday;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}
