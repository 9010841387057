@import "@/assets/scss/main.scss";

.hub-views-viewer {
  display: flex;
  flex-direction: column;
  :deep() .el-select__input {
    margin-left: 0;
  }
  :deep() .el-select__tags {
    padding-left: 32px;
  }

  :deep() .el-tag {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.icon-wrapper {
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.view-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  div {
    height: fit-content;
  }
  .view-title {
    font-size: 14px;
  }
  .view-description {
    font-size: 12px;
    margin-top: -16px;
    color: $grey-5-mayday;
  }
}
