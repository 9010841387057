@import "@/assets/scss/main.scss";

.product__list__item-row {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    & .product-icon {
      color: $blue-mayday;
    }
  }
  .product-icon {
    height: 16px;
    width: 16px;
  }
}

.product-label-span {
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.min-width-0 {
  min-width: 0;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
}

.product_item__result__link {
  padding: 0px 12px 0px 8px;

  &__extra-padding {
    padding: 0px 24px 0px 30px;
  }
}
