@import "@/assets/scss/main.scss";

.thread__message {
  animation: fade-in 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;

  &-focus {
    animation: blink 1000ms linear;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }
  &-content {
    padding-left: 26px;
    position: relative;
    &-response::before {
      content: '';
      height: 100%;
      width: 1px;
      background-color: $grey-4-mayday;
      position: absolute;
      left: 10px;
    }
    &-last::before {
      background-color: transparent !important;
    }
  }
  &-response {
    padding-left: 26px;
  }

  &-votes {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    line-height: 1;
    height: 12px;
    padding: 0;
    color: $grey-8-mayday;
    margin-bottom: 8px;

    &-active:hover {
      color: $blue-mayday;
    }

    &-me {
      color: $blue-mayday;
    }

    &-disabled {
      margin-bottom: 4px !important;
    }

    &-empty {
      color: $grey-6-mayday;
    }

    &-deleted:hover {
      color: $grey-8-mayday;
      cursor: not-allowed;
    }
  }

  &-delete {
    position: absolute;
    inset: 0;
    background-color: $grey-1-mayday;
    color: $red-mayday;
    animation: fade-in 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &-loader {
      font-size: 24px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fade-in 250ms ease-in-out;
    }
    &-confirm {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin-bottom: 8px;
    }
  }
}

.thread__body {
  padding: 8px 0;
  font-size: 12px;
  line-height: 14px;
  color: black;
}

.thread__message:hover .thread__options {
  opacity: 1;
}

.thread__options {
  display: flex;
  gap: 4px;
  align-items: center;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.thread__edit {
  display: flex;
  gap: 4px;
  align-items: center;
  transition: opacity 250ms ease-in-out;
}

.thread__action {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-8-mayday;
  height: 24px;
  width: 24px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: $grey-3-mayday !important;
  }
  &:active {
    background-color: $grey-4-mayday !important;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $grey-2-mayday !important;
  color: $grey-7-mayday;
  opacity: 0.8;
  &:hover {
    background-color: $grey-2-mayday !important;
  }
  &:active {
    background-color: $grey-2-mayday !important;
  }
}

.thread__attachments-list {
  margin-top: -4px;
  margin-bottom: 4px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: $grey-2-mayday;
  }
  100% {
    background-color: transparent;
  }
}
