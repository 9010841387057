@import "@/assets/scss/main.scss";

.paragraph-title {
  font-size: 12px;
  color: $grey-6-mayday;
}

.email {
  width: 80% !important;
  min-width: 280px;
}

.small-input {
  :deep() .el-input--small {
    width: 100% !important;
  }
}

.el-select--small {
  min-width: 280px;
}

:deep() .el-input {
  height: 32px;
}

:deep() .el-select .el-input .el-input__inner {
  height: 100%;
}

.option-group-title {
  display: flex;
  margin-left: 12px;
  .option-icon {
    margin-right: 4px;
  }
}

.input-wrapper {
  width: 55%;
}

.refresh-icon {
  width: 12px;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.callout-wrapper {
  min-width: 280px;
}
