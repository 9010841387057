@import "@/assets/scss/main.scss";

.container {
  padding-inline: 0px;
}
.title {
  font-weight: 700;
  border-bottom: 1px solid $grey-4-mayday;
  width: 100%;
  margin-bottom: 8px;
}
