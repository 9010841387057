@import "@/assets/scss/main.scss";

.category {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .description {
    font-size: 16px;
    color: $grey-8-mayday;
    margin-bottom: 17px;
    max-width: 350px;
  }
  .select {
    width: 60%;
  }
  .el-select {
    height: 32px;
  }
  :deep() .el-input {
    height: 100%;
  }
  :deep() .el-input__inner {
    height: 100%;
  }
  :deep() .el-input__suffix {
    display: flex;
    align-items: center;
  }
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
  }
  .flag-wrapper {
    width: 23px;
    height: 21px;
    background-color: $grey-4-mayday;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .el-input {
    height: 32px;
    width: 350px;
  }
  :deep() .el-color-picker {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep() .el-color-picker__trigger {
    border-radius: 50%;
    padding: 0px;
    width: 30px;
    height: 30px;
  }

  :deep() .el-color-picker__color {
    border-radius: 50%;
    border: none;
  }

  :deep() .el-color-picker__color-inner {
    border-radius: 50%;
  }
  .placeholder-value {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}

.chosen-language {
  :deep() .el-input__inner {
    padding-left: (50px);
  }
}

.validity-message {
  height: 14px;
  font-size: 12px;
  .error-hostname {
    color: $red-mayday;
  }

  .valid-hostname {
    color: $green-mayday;
  }
}

.is-not-valid {
  :deep() .el-input__inner {
    border-color: $red-mayday;
  }
}
.select-language {
  :deep() .el-input__inner {
    height: 30px;
    width: 140px;
    padding-left: 48px;
  }
  :deep() .el-input__prefix {
    width: 40px;
  }
}
.option-container {
  display: flex;
  column-gap: 16px;
  letter-spacing: 0.02rem;
}
