@import "@/assets/scss/main.scss";

:deep() .el-input__inner {
  padding: 4px 8px;
  height: 28px;
  background-color: $grey-1-mayday;
  border-color: $grey-2-mayday;
  color: $grey-7-mayday;
}

:deep() .el-textarea__inner {
  padding: 4px 8px;
}

:deep() .el-input-group__append {
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: white;
}

.copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-7-mayday;
  width: 100%;
  padding: 0 8px;
  font-size: 12px;
  * {
    width: 14px;
    height: 18px;
  }
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.1);
    color: $blue-mayday;
  }
}

.click-bounce {
  animation: bounce 500ms ease;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.settings__public-item {
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.reveal {
  width: 18px;
  height: 18px;
  transform-origin: center;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  &:hover {
    color: $blue-mayday;
    background: rgba($blue-mayday, 0.1);
  }

  * {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
  }
  .open-reveal {
    transform: rotate(90deg);
  }
}
