@import "@/assets/scss/main.scss";

.icon {
  width: 21px;
  height: 21px;
}
.tab-delete {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: $grey-5-mayday;
  cursor: pointer;
}
.blue-mayday {
  color: $blue-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.el-tabs__item span {
  color: $grey-5-mayday;
}
.el-tabs__item.is-active span {
  color: $blue-3-mayday;
}

.one-tab-display {
  :deep() .el-tabs__header {
    display: none;
  }
}

.one-tab-display-delete {
  display: none;
}
