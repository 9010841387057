@import "@/assets/scss/main.scss";

.header {
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: $grey-9-mayday;
}
.total {
  font-size: 8px;
  padding: 2px 4px;
  border: 1px solid $grey-9-mayday;
  border-radius: 8px;
}

.user-row {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 8px;
  padding-left: 0;
  border-bottom: 1px solid $grey-4-mayday;
}

.user-row__infos {
}

.initials-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.user-row__infos {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 100%;
}

.user-row__username {
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: $grey-9-mayday;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-row__role {
  font-size: 11px;
  font-weight: 500;
  color: $grey-6-mayday;
}

:deep() .time-ago {
  font-weight: 700;
}

.user-row__last-update {
  height: 12px;
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  margin: 0 auto;
}

.pagination__button {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: $grey-1-mayday;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: $blue-mayday;
  }

  &.selected {
    background: $blue-mayday;
    color: white;
  }

  &.disabled {
    cursor: not-allowed;
    color: $grey-4-mayday;
  }
}

.pagination__pages {
  display: flex;
  align-items: center;
  gap: 8px;
}
