@import "@/assets/scss/main.scss";

.column-container {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
}

.initials-wrapper {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $grey-4-mayday;
  color: white;
  border-radius: 2px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-wrapper {
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: white;
  border-radius: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4px;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.edit-text {
  margin-left: 5px;
}
