@import "@/assets/scss/main.scss";

.navigator-bar-wrapper {
  width: fit-content;
  height: 100vh;
  margin-left: 2px;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  img {
    max-width: 20px;
    height: 20px;
  }
}

.navigation-bar {
  width: 320px;
  min-height: 100vh;
}

// SMALL
.small-navigation-bar {
  width: 60px;
}

.small-navigation-bar-body {
  margin-top: 68px;
  height: calc(100vh - 68px);
  background-color: white;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
  border-right: 1px solid $grey-2-mayday;
}

.position-reduce {
  left: 368px;
}

.focus-element-icon {
  width: 32px;
  padding: 16px 0;
}

.reduce-navigator-icon {
  width: 100%;
}

// ENLARGE
.enlarge-navigation-bar {
  cursor: pointer;
  position: fixed;
  z-index: 10;
  top: 84px;
  background-color: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  font-size: 16px;
  border: 1px solid $grey-1-mayday;
  box-shadow: $shadow-sm-mayday;
  color: $grey-4-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.position-enlarge {
  left: 108px;
}

// TRANSITION EFFECT

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
