@import "@/assets/scss/main.scss";

.summary-tabs__tabs {
  display: flex;
  width: 100%;

  .summary-tabs__tab {
    width: 100%;
    background-color: white;
    border-bottom: 1px solid white;
    color: $grey-5-mayday;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    padding: 3px;

    &:hover {
      border-color: $purple-1-mayday;
      color: $purple-5-mayday;
    }

    &.active {
      border-color: $purple-5-mayday;
      color: $purple-5-mayday;
    }

    .summary-tabs__infos {
      font-size: 12px;
      color: $grey-5-mayday;
    }
  }
}
