@import "@/assets/scss/main.scss";

.settings-drawer {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__main {
    flex: 1;
    overflow-y: auto;
    display: flex;
    margin-bottom: 8px;
    padding-top: 12px;
    padding-left: 30px;
    padding-right: 30px;
    &__left-col {
      padding-top: 60px;
      width: 170px;
      padding-right: 30px;
    }
    &__wrapper {
      flex: 1;
      & > div {
        margin-bottom: 16px;
      }
    }
  }

  &__footer {
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    background-color: $grey-1-mayday;
  }
}
