@import "@/assets/scss/main.scss";

.section-title {
  display: flex;
}

.analytics-workflow-session-table-search {
  margin-left: auto;
  width: 320px;
  height: 40px;

  :deep() .el-input__prefix {
    padding-left: 5px;
    .search-icon {
      width: 20px;
      height: 20px;
    }
  }
}
.table-container {
  max-height: 80vh;
  overflow-y: scroll;
}

.analytics-download-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
}

.routers {
  .router-wrapper {
    width: 77px;
    height: 34px;
  }
  .router-link-active {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    color: $blue-mayday !important;
    padding: 5px;
  }
  .router-link {
    width: 100%;
    height: 100%;
    color: $grey-4-mayday;
  }
}

.action-button-wrapper {
  background: none;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  width: 16px;
  height: 21px;
}

.action-button {
  &:hover {
    color: $red-mayday;
    cursor: pointer;
  }
}
.cell-class {
  background-color: $grey-1-mayday;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-cell-class {
  background-color: $grey-1-mayday;
  border-top: none;
}

.cell .wrapper {
  display: block;
}
.page-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.page-section {
  margin-top: 20px;
}

.download-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.card-container {
}
