@import "@/assets/scss/main.scss";

.add-condition-enable {
  cursor: pointer;
}

.add-condition-enable:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.add-condition-disable {
  cursor: not-allowed;
  font-size: 14px;
  color: $grey-5-mayday;
}
a {
  color: $blue-mayday !important;
}
.rule-section {
  color: $grey-5-mayday;
}

.collapse-actions {
  color: $unknown-blue-1-mayday;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
.collapse-condition-content {
  padding: 10px;
  border: 1px solid $grey-3-mayday;
  background: $grey-1-mayday;
  border-radius: 5px;
  width: 100%;
}
.and-operator {
  color: $blue-mayday;
}
.logic-select {
  :deep() .el-input__inner {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__inner:focus {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__icon {
    line-height: 20px;
  }
}

.logic-select {
  :deep() .el-input__inner:disabled {
    padding-left: 1px;
  }
}

.logic-operator {
  width: 54px;
  border-right: 1px solid $grey-4-mayday;
}

.add-attribute-btn {
  margin-left: 54px;
}
