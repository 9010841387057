@import "@/assets/scss/main.scss";

button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__tabs-container {
  border-bottom: 1px solid $grey-5-mayday;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.thread__tabs {
  display: flex;
  align-items: center;

  &-actions {
    margin-right: 10px;
  }

  .thread__tab {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    position: relative;

    &-disabled {
      cursor: not-allowed !important;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey-6-mayday;
      transition: color 250ms ease-in-out;
      width: 14px;
      height: 14px;
    }
    &-label {
      font-weight: 700;
      color: $grey-6-mayday;
      transition: color 250ms ease-in-out;
    }
    &-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-color: $grey-4-mayday;
      color: white;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      transition: background-color 250ms ease-in-out;
    }
    &-badge-open {
      width: 100%;
      border-radius: 100px;
      padding: 0 4px;
      gap: 2px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background-color: transparent;
      bottom: -1px;
      right: 0px;
      transition: background-color 250ms ease-in-out;
    }
  }

  .thread__focus-tab {
    .thread__tab-badge {
      background-color: $blue-mayday !important;
    }
    .thread__tab-icon {
      color: $blue-mayday !important;
    }
    .thread__tab-label {
      color: $blue-mayday !important;
    }
  }

  .thread__tab:hover {
    .thread__tab-icon {
      color: $grey-7-mayday;
    }
    .thread__tab-label {
      color: $grey-7-mayday;
    }
  }

  .thread__tab:hover::after {
    background-color: $grey-6-mayday !important;
  }

  .thread__focus-tab::after,
  .thread__focus-tab:hover::after {
    background-color: $blue-mayday !important;
  }
}
