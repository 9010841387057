@import "@/assets/scss/main.scss";

.clone-modal {
  :deep() .modal-dialog {
    max-width: 550px;
  }
}
.language-match-error {
  padding: 18px;
  border: 1px solid $red-mayday;
  border-radius: 2px;
  font-weight: bold;
  color: $red-mayday;
  margin-top: 12px;
  text-align: center;
}
.language-match-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.language-match-row {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.arrow-icon {
  flex: 0 1 10%;
}

.translation-dropdown {
  flex: 0 1 45%;
}
.header-footer {
  padding: 8px;
  background: white;
  border-radius: 2px;

  .icon {
    width: 24px;
    height: 24px;
    color: $grey-5-mayday;
  }

  .icon-background {
    padding: 16px 16px;
    background-color: $grey-1-mayday;
    border: 0.5px solid $grey-4-mayday;
    border-radius: 2px;
  }

  .label {
    font-weight: bold;
  }

  .sub-label {
    font-size: 8px;
    font-weight: bold;
    color: $grey-5-mayday;
  }
}
.clone-zone {
  padding: 18px;
  border: 1px solid $blue-mayday;
  border-radius: 2px;

  .label {
    font-weight: bold;
  }
}
.case-input {
  :deep() .form-control {
    font-size: 1rem;
    font-weight: bold;
    color: $grey-8-mayday;
  }
  :deep() .form-control::placeholder {
    font-weight: bold;
    color: $grey-5-mayday;
  }
}
