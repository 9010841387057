@import "@/assets/scss/main.scss";

.attribute-table {
  :deep() .el-table__body-wrapper {
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
  }
}

.attribute-table {
  :deep() .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.attribute-table {
  :deep() .cell:active {
    white-space: unset;
    word-break: break-word;
    cursor: pointer;
  }
}

.attribute-table {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
