@import "@/assets/scss/main.scss";

.info-icon {
  color: black;
  font-size: 12px;
}

.search-option-popover {
  display: flex;
  flex-direction: column;
}

.header {
  font-weight: 700;
}
.is-active {
  color: $blue-mayday;
}
.switch-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 0px 8px 4px;
}
