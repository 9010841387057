@import "@/assets/scss/main.scss";

.permission-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  &.divider {
    border-bottom: 1px solid $grey-4-mayday;
  }
}

.permission-container-hidden {
  opacity: 0.5;
  cursor: not-allowed;
}
