@import "@/assets/scss/main.scss";

.title-icon {
  color: $grey-6-mayday;
}

.title-icon-background {
  background: $grey-1-mayday;
  border-radius: 2px;
}

.tag {
  display: block;
  color: $grey-6-mayday;
  font-size: xx-small;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 4px;
}

.deleted-tag {
  background-color: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $red-mayday;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 4px;
}

.knowledge-base-label {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0.02em;
  overflow: hidden;
}

h4 {
  margin-bottom: 0px;
}

.title-icon-background :deep() .logo-container {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  display: flex;
}

.knowledge-base-input-old {
  :deep() .item-editor__input--category {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: inherit;
    text-transform: none;
    text-align: left;
  }
}

.knowledge-manage-settings-row-header-left {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
  align-items: center;
}
