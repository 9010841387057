@import "@/assets/scss/main.scss";

.delete-modal {
  :deep() .modal-body {
    max-height: 65vh;
    overflow: auto;
  }
  :deep() .modal-footer {
    justify-content: center;
    padding-top: 0;
  }
}
.deletion-warning-icon {
  max-height: 60px;
  margin-top: -30px;
}
.content-danger-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($red-mayday, 0.05);
}
.content-linked-text-container {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid $red-mayday;
  border-radius: 4px;
}
