@import "@/assets/scss/main.scss";

:deep() .el-input__inner {
  padding: 4px 8px;
  height: 28px;
  background-color: $grey-1-mayday;
  border-color: $grey-2-mayday;
  color: $grey-7-mayday;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.draggable {
  overflow: hidden;
  cursor: grab;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &:hover .remove-related {
    display: flex;
  }
  &:hover .drag-icon {
    color: $grey-6-mayday;
    display: flex;
  }

  &:not(:hover) .content-img {
    display: flex !important;
  }
}

.drag-icon {
  display: none;
  font-size: 14px;
  margin: 0 4px 0 2px;
}

.remove-related {
  cursor: pointer !important;
  background: $grey-1-mayday;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-left: 4px;
  color: $grey-9-mayday;
  * {
    margin: 2px;
    height: 12px;
    width: 12px;
    font-size: 12px;
  }
  &:hover {
    background: $grey-3-mayday;
  }
}

.related-container {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  font-size: 14px;
  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 4px 8px;
  }

  .related-list {
    padding: 4px 8px;
    max-height: 200px;
    overflow: auto;
    border-top: 1px solid $grey-3-mayday;
  }

  .related-placeholder {
    padding: 4px 8px;
    max-height: 200px;
    color: $grey-5-mayday;
  }
}

.search-wrapper {
  border-top: 1px solid $grey-3-mayday;
  overflow: auto;
  max-height: 200px;
  padding: 4px 8px;
}

.search-item {
  max-width: 100%;
  min-width: 0;
  font-size: 14px;
  display: flex;
  padding: 2px 0 2px 0;
  border-radius: 4px;

  .content-icon {
    margin-top: 4px;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 14px;
    }
    .content-img {
      display: none;
    }
    .content-search-img {
      display: flex;
    }
  }
}

.search-item-hovered {
  background-color: $grey-1-mayday !important;
}

.pointer {
  cursor: pointer;
}

.published-tooltip {
  cursor: default;
  margin-top: 6px;
}

.settings__public-item {
  margin-bottom: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.reveal {
  width: 18px;
  height: 18px;
  transform-origin: center;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  &:hover {
    color: $blue-mayday;
    background: rgba($blue-mayday, 0.1);
  }

  * {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
  }
  .open-reveal {
    transform: rotate(90deg);
  }
}
