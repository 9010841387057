@import "@/assets/scss/main.scss";

.collections {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: center;
  gap: 16px;
}

.collection-select {
  width: 50%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.categories-collapse {
  gap: 4px;
  display: flex;
  flex-direction: column;
  border: none;
}

.categories-no-collapse {
  display: flex;
  flex-direction: column;
  border: none;
  :deep() .el-collapse-item__header {
    display: none;
  }
}

.collection-title-wrapper {
  padding: 8px 15px 8px 15px;
  font-size: 16px;
  font-weight: 700;
  color: $blue-mayday;
  background-color: $grey-1-mayday;
}

.info-icon {
  color: $grey-6-mayday;
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.search {
  height: 34px;
  max-width: 300px;
  min-width: 150px;
  width: 100%;
  :deep() .el-input__inner {
    height: 100%;
  }
}

:deep() .el-collapse-item__header {
  padding: 8px 12px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border: none;
  font-size: 14px;
  font-weight: 700;
  height: 34px;
}

:deep() .el-collapse-item__wrap {
  border: none;
}

.checkbox-group {
  padding-left: 12px;
  &__to-collapse {
    margin-top: 8px;
    padding-left: 30px;
  }
}

.checkbox-item {
  height: 21px;
}

.attribute-title {
  color: $grey-5-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.is-checked {
  .attribute-title {
    color: $blue-mayday;
  }
}

.title-wrapper {
  max-width: 195px;
}

.add {
  :deep() .el-tag {
    background-color: $green-1-mayday;
    border-color: $green-2-mayday;
    color: $green-mayday;
    .el-tag__close.el-icon-close {
      color: $green-mayday !important;
    }
  }
}

.remove {
  :deep() .el-tag {
    background-color: $red-1-mayday;
    border-color: $red-2-mayday;
    color: $red-mayday;
    .el-tag__close.el-icon-close {
      color: $red-mayday !important;
    }
  }
}

.select-all-remove {
  height: 34px;
  padding: 8px 12px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 14px;
  font-weight: 700;
}

:deep() .checkbox-item__is-remove > .is-checked > .el-checkbox__inner {
  background-color: $red-mayday;
  border-color: $red-mayday;
}
