@import "@/assets/scss/main.scss";

.draft-article {
  color: $grey-5-mayday;
}

.imported-logo {
  width: 14px;
}

.selected-logo {
  width: 14px;
  margin-top: 14px !important;
}

.import-url {
  padding: 8px;
  margin: auto 0;
}
