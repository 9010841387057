@import "@/assets/scss/main.scss";

.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
