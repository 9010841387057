@import "@/assets/scss/main.scss";

.hub-filter-container {
  display: flex;
  flex-wrap: wrap;
}

.hub-filter-category {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 40%;
  margin-right: 10px;
  margin-bottom: 8px;
}

.hub-filter-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey-4-mayday;
}

.hub-filters-container {
  border: none !important;
  :deep(.el-collapse-item) {
    .el-collapse-item__wrap {
      border: none !important;
    }
    .el-collapse-item__content {
      padding: 0 !important;
    }
    .el-collapse-item__header {
      border: none !important;
      height: 30px;
    }
    &.disabled {
      pointer-events: none !important;

      .el-collapse-item__arrow {
        display: none !important;
      }
    }
  }
}

.hub-filter {
  display: flex;
  align-items: center;
}

.hub-filter-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 4px;

  &.included {
    background-color: $green-5-mayday;
  }
  &.excluded {
    background-color: $red-4-mayday;
  }
}

.hub-filter-label {
  font-size: 14px;
  line-height: 17px;
  color: #3c424c;

  .hub-filter-count {
    font-size: 12px;
    line-height: 15px;
    color: $grey-6-mayday;
  }
}

.hub-filter-values-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .hub-filter-value {
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: $grey-2-mayday;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 11px;
  }
}
