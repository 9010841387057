@import "@/assets/scss/main.scss";

.workflow-section-title {
  &__header {
    &__title {
      font-weight: bold;
      color: black;
    }
  }
}
