@import "@/assets/scss/main.scss";

.key-step-icon {
  margin-right: 10px;
}

.breadcrumb-step {
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 200;
  border-radius: 3px;
  color: $blue-mayday;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.05);
}

.breadcrumb-last-step {
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 200;
  border-radius: 3px;
}

.breadcrumb-separator {
  max-width: 4px;
  color: $blue-mayday;
}

.magic-icon {
  max-width: 25px;
}

.children-previous-steps {
  min-width: 150px;
  margin-bottom: 5px;
  padding-left: 5px;
}

.children-previous-steps:hover {
  background-color: rgba($blue-mayday, 0.05);
  cursor: pointer;
}
