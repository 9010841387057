@import "@/assets/scss/main.scss";

button:focus {
  outline: none;
}

.error {
  :deep() input {
    border: 1px solid $red-mayday !important;
  }
}

.content-icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.content-icon {
  width: 14px;
  height: 14px;
}

.redirection-url {
  gap: 8px;
  width: 25vw;
}

.clearable {
  cursor: pointer;
  &:hover {
    color: $red-mayday;
  }
}
