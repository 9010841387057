@import "@/assets/scss/main.scss";

:deep() .el-select {
  .el-input .el-input__inner {
    width: 110px;
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }
}

:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.clear-button {
  color: $grey-4-mayday;
  cursor: pointer;
}

.main-option-item {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 24px;

  padding: 4px 0px 4px 4px;
}
