@import "@/assets/scss/main.scss";

.ask-box {
  border-radius: 4px;
  border: 1px solid;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-wrapper {
  background-color: $purple-1-mayday;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: -10px;
  left: 12px;
  height: 15px;
  .title {
    font-size: 12px;
    font-weight: 700;
  }
}

.ask-box-slot-wrapper {
  height: fit-content;
  max-height: 100%;
  overflow: auto;
}
