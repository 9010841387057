@import "@/assets/scss/main.scss";

.navigation-section {
  .section-title {
    color: $grey-7-mayday;
  }
}

.collection-navigation {
  .collection-item {
    cursor: pointer;
    &:hover {
      background-color: $grey-1-mayday;
    }
    .collection-icon {
      color: $grey-4-mayday;
    }
  }
}
