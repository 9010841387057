@import "@/assets/scss/main.scss";

.borderless-input-error {
  &::placeholder {
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &::-webkit-input-placeholder {
    /* Chrome */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $red-5-mayday;
    opacity: 0.7;
  }
  &:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $red-5-mayday;
    opacity: 0.7;
  }

  &::-ms-input-placeholder {
    color: $red-5-mayday;
    opacity: 0.7;
  }
}

.btn-cancel {
  background: none;
  border: none;
  color: $grey-5-mayday;
  cursor: pointer;
}

.btn-create {
  background-color: $blue-mayday;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
