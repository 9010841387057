@import "@/assets/scss/main.scss";

.table-of-contents-header {
  text-transform: uppercase;
  h5 {
    color: $grey-5-mayday;
  }
}

.routes-list-item {
  width: 100%;
}

.route-title {
  color: $grey-9-mayday;
  line-height: 24px;
  &:hover,
  .is-focused {
    color: $blue-mayday;
  }
}
.sub-routes-list {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sub-route-item {
  color: $grey-9-mayday;
  padding-left: 10px;
  border-left: 1px solid $grey-4-mayday;
  line-height: 24px;
  &:hover,
  .is-focused {
    color: $blue-mayday;
    border-color: $blue-mayday;
  }
  &:hover{
    background-color: $grey-1-mayday;
  }
}
