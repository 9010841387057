@import "@/assets/scss/main.scss";

.add-item-into-collection-row {
  color: $grey-5-mayday;
  border: 1px solid;
  border-color: transparent;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  transition: all 0.3s;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .plus-icon {
    border: 1px solid transparent;
    padding: 1px;
    width: 15px;
    height: 15px;
  }
}

.case-viewer {
  height: calc(100vh - 68px);
  overflow: auto;
  max-width: 100%;
  background-color: white;
  box-shadow: $shadow-mayday;
  margin: 0 !important;
}

.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}

.toast-position {
  top: 45px;
  right: 12px;
}

.overflow {
  overflow: initial !important;
}
