@import "@/assets/scss/main.scss";

.header {
  padding: 16px 16px 0px 16px;
}
.title {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.label {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
  cursor: pointer;

  &:hover {
    color: $grey-7-mayday;
  }
}
