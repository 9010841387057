@import "@/assets/scss/main.scss";

.label-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
}

.label-editor {
  margin-left: 5px;
}

.label-container .label-options {
  visibility: hidden;
  display: contents;
}

.label-container:hover .label-options {
  visibility: visible;
}

.label-options {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.options-icon {
  color: $grey-7-mayday;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  cursor: pointer;
  &_trash:hover {
    color: $red-mayday;
  }
  &_edit:hover {
    color: $blue-mayday;
  }
  * {
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}
