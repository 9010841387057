@import "@/assets/scss/main.scss";

.upload-zone {
  max-height: 60vh;
  overflow: auto;
}

.test:hover {
  background-color: $blue-mayday;
}

.ellipsis-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.import-article-icon {
  color: $grey-4-mayday;
  max-width: 12%;
  cursor: pointer;
}

.import-article-icon:hover {
  color: $blue-mayday;
  font-size: 110%;
}

.import-article-modal {
  :deep() .modal-body {
    max-height: 600px;
    overflow: auto;
  }
}

.search-article-input {
  :deep() input {
    border: 0px;
    border-left: 1px solid $grey-4-mayday !important;
    border-radius: 0px 5px 0px 0px;
    height: 43px;
  }
}

.change-plateform {
  :deep() input {
    border: 0px;
    border-radius: 5px 5px 0px 0px;
  }
}

.change-plateform {
  :deep() .prefix {
    margin-top: 12px;
    margin-left: 3px;
  }
}

.change-plateform {
  :deep() .el-input__inner {
    margin-left: 5px;
  }
}

.change-plateform {
  :deep() input:focus {
    border: 0px !important;
    border-radius: 5px 5px 0px 0px;
  }
}

.modal-search-header {
  border-bottom: 1px solid $grey-4-mayday;
}

.search-article-input {
  :deep() .el-icon-close {
    cursor: pointer !important;
  }
}

.search-article-input {
  :deep() .el-icon-search {
    cursor: pointer !important;
  }
}

.my-card {
  :deep() .card-body {
    padding: 10px;
    border-radius: 0px !important;
  }
}

.modal-upload-header {
  max-width: 100%;
}
