@import "@/assets/scss/main.scss";

.concepts-list {
  display: inline-flex;
  gap: 5px;
  flex-wrap: wrap;
}

.show {
  font-size: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $blue-5-mayday;
  }
}

.empty {
  font-size: 10px;
}
