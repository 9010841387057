@import "@/assets/scss/main.scss";

.hub-settings-drawer {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hub-settings-drawer-content-wrapper {
  flex: 1;
  overflow-y: auto;
}

.hub-settings-drawer-footer {
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
  background-color: $grey-1-mayday;
}
