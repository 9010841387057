@import "@/assets/scss/main.scss";

.content-main-title {
  line-height: 1.2;
  word-break: break-word;
}
.article-title:hover .edit-title {
  visibility: visible;
}

.diag-title:hover .edit-title {
  visibility: visible;
}

.folder-title:hover .edit-title {
  visibility: visible;
}

.edit-title {
  visibility: hidden;
}

.title-input {
  margin-bottom: 8px;
  line-height: 1.2;
}

.title-input:focus {
  outline: none;
  border: none;
}

.modify-icon {
  max-width: 20px;
  cursor: pointer;
  color: $grey-5-mayday;
  padding: 2px;
  &:hover {
    color: $blue-mayday;
  }
}

.modify-icon:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}
