@import "@/assets/scss/main.scss";

.thread__author {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 20px;

  &-text {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
  &-username {
    font-size: 14px;
    color: $grey-8-mayday;
    font-weight: 700;
  }
  &-deleted {
    color: $grey-5-mayday;
  }
  &-date {
    font-size: 10px;
    color: $grey-6-mayday;
  }
}
