@import "@/assets/scss/main.scss";

.node-item {
  padding-left: 4px;
  padding-right: 4px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  width: 100%;
}

.node-item .focus {
  z-index: 0;
  color: $blue-mayday;
}

.nodeLabel {
  width: 100%;
  font-weight: bold;
}

.inputNodeLabel {
  color: $grey-6-mayday;
  background-color: $grey-3-mayday;
  padding-left: 5px;
  border: 0px;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  height: 20px;
  line-height: 1.7;
}

.magic-badge {
  margin-left: 10px;
  color: $yellow-mayday;
  margin-top: -15px;
}

/* END NEW STYLE */

.isDraft .inputNode {
  color: $grey-5-mayday;
}

.node-item .focus {
  z-index: 0;
}

.focus {
  background-color: white;
  border-radius: 2px;
}
