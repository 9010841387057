@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.label-name {
  font-size: 14px;
}

.label__span {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid;
  margin-right: 8px;
  &:hover {
    box-shadow: $shadow-mayday;
    cursor: pointer;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.settings-card {
  width: 70%;
}

.settings-card-header-description {
  color: $grey-6-mayday;
  font-size: 80%;
}

.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.label {
  height: 28px;
}

.label {
  height: 28px;
}

.category .option,
.label .option {
  visibility: hidden;
  display: contents;
}

.category:hover .option,
.label:hover .option {
  visibility: visible;
}

.empty-category-info {
  font-style: italic;
  color: $grey-5-mayday;
  font-weight: bold;
  font-size: 12px;
}

.option__button {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.trash {
  &:hover {
    color: $red-mayday;
  }
}

.add-label {
  color: $grey-5-mayday;

  &:hover {
    color: $blue-mayday;
  }
}

.label--input {
  border: none;
  color: $grey-9-mayday;
  padding: 0;
  &::placeholder {
    color: $grey-5-mayday;
  }
}

.main {
  min-height: 150px;
}

.title-icon-background {
  background: $grey-1-mayday;
  border-radius: 2px;
}

.title-icon-background :deep() .logo-container {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  display: flex;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
}
.content-state {
  background-color: $grey-1-mayday;
  padding: 2px 6px;
  border: solid 1px $grey-2-mayday;
  border-radius: 4px;
}

.content-state-label {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.content-state-header {
  border-bottom: 1px solid $grey-2-mayday;
  padding-bottom: 2px;
}

.sub-header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

.label-tags {
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  box-sizing: border-box;
}
