@import "@/assets/scss/main.scss";

.title-container {
  width: 100%;
  padding-left: 16%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.translation-container {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.update-translate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: $purple-5-mayday;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}
.content-title {
  border-left: 3px solid $grey-4-mayday;
  width: 100%;
}

.article-title {
  border-color: $yellow-mayday;
}

.diag-title {
  border-color: $blue-mayday;
}

.folder-title {
  border-color: $grey-4-mayday;
}

.content-stamp {
  border: 1px solid;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft {
  color: $grey-5-mayday;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  border-color: 1px solid $grey-4-mayday;
}

.published {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $blue-mayday;
}

.upper-title-text {
  white-space: pre-wrap;
}

.upper-title-text-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
}
