@import "@/assets/scss/main.scss";

.list-item-source {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  &:hover {
    box-shadow: $shadow-mayday;
    cursor: pointer;
    .action-icon-wrapper {
      display: block;
    }
    .link-id {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}
.hovered {
  border: 1px solid $blue-mayday;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(100% - 90px);
  height: 100%;
  .icon-wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 18px;
    height: 18px;
  }
  .title {
    color: $grey-8-mayday;
    width: 100%;
    height: 22px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 100%;
}

.action-icon-wrapper {
  display: none;
  height: 100%;
  .action-icon {
    height: 14px;
    width: 14px;
  }
  & :hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}

.link-id {
  font-size: 12px;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 4px;
  color: $purple-5-mayday;
  background-color: $purple-2-mayday;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}

.obsolete-icon-container {
  flex: none;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background: $red-5-mayday;
  justify-content: center;
  display: flex;
}

.obsolete-icon {
  color: white;
  height: 8px;
  width: 8px;
  cursor: pointer;
}
