@import "@/assets/scss/main.scss";

.analytics-views-controls-container {
  display: flex;
  align-items: center;

  .graph-type-menu {
    margin-left: 33px;
  }
}
