@import "@/assets/scss/main.scss";

.ask-answer-sources {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
  width: 100%;
}
