@import "@/assets/scss/main.scss";

.content-filter-select {
  :deep() .el-input > .el-input__inner {
    height: 30px;
    max-width: 140px;
    background-color: $grey-1-mayday;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $grey-6-mayday;
    border: unset;
  }
}
