@import "@/assets/scss/main.scss";

.brain-task-toast {
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  color: $purple-5-mayday;
  border: rgba($purple-5-mayday, 0.5) 1px solid;
}
