@import "@/assets/scss/main.scss";

.content-label-span {
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label-row {
  display: flex;
  align-items: center;
}

.explain-prompt {
  display: inline-flex;
  line-height: 1px;
}

.content-breadcrumb {
  margin-top: 0px;
}

.content__list__item-row {
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.content-icon {
  width: 16px;
  max-height: 16px;

  &.blue {
    color: $blue-mayday;
  }
}

.options-selection {
  visibility: hidden;
}

.content__list__item-row {
  &:hover {
    .options-selection {
      visibility: visible;
    }
    .checkbox {
      :deep() .el-checkbox__input {
        visibility: visible;
      }
    }
  }
}

.checkbox {
  :deep() .el-checkbox__input {
    margin: auto;
    width: 14px;
    height: 14px;
  }
  :deep() .is-checked {
    visibility: visible;
  }
  :deep() .el-checkbox__inner {
    height: 14px;
    width: 14px;
  }
}

.check-space {
  width: 14px;
}

.min-width-0 {
  min-width: 0;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
}

.is-hidden-sticker {
  background-color: red;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 12px;
  top: -3px;
  width: 5px;
}
