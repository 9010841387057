@import "@/assets/scss/main.scss";

.hub-settings,
.hub-settings-header,
.hub-settings-content {
  width: 100%;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  width: 100%;
  max-width: calc(100% - 32px);
  overflow: hidden;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.hub-settings-container {
  width: 100%;
  overflow: hidden;
  &.fill-height {
    min-height: calc(5 * 58px);
  }
  .main-container {
    width: 100%;
    :deep(.content-container) {
      width: calc(100% - 32px);
    }
  }
}

.hub-dropdown-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 40px;
}

.hub-view-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.hub-view-ellipsis {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  overflow: hidden;
  font-size: 14px;
  color: $grey-8-mayday;

  .hub-view-title {
    font-weight: 700;
    font-size: 16px;
    color: black;
  }
}

.hub-dropdown {
  margin: 0;
  :deep(.title-container) {
    margin: 0;
  }
}

.popover-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $grey-1-mayday;
  }
}

.hub-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  color: $grey-8-mayday;
  margin-right: 8px;
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  &:hover {
    background-color: $grey-2-mayday;
    color: $blue-5-mayday;
  }
  &.delete {
    border-top: 1px solid $grey-2-mayday;
    .button-icon,
    &:hover {
      color: $red-5-mayday;
    }
  }
  .button-icon {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $grey-8-mayday;
  }
}
.hub-dropdowns-container {
  min-height: 50px;
}

:deep(.el-pagination) {
  margin: 10px auto 0;
  width: fit-content;
}
