@import "@/assets/scss/main.scss";

:deep() .el-input__inner {
  padding: 0 2px;
  height: 28px;
}

:deep() .el-textarea__inner {
  padding: 0 2px;
}

:deep() .el-input-group__append {
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: white;
}

.copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    width: 14px;
    height: 18px;
  }
}

.settings__public-item {
  margin-bottom: 8px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.settings__public-add {
  cursor: pointer;
  align-self: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  color: $grey-5-mayday;
  padding: 4px;
  i {
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}
