@import "@/assets/scss/main.scss";

:deep() .el-input__inner {
  padding: 4px 8px;
  height: 28px;
  background-color: $grey-1-mayday;
  border-color: $grey-2-mayday;
  color: $grey-7-mayday;
}

:deep() .el-textarea__inner {
  padding: 4px 8px;
}

:deep() .el-input-group__append {
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: white;
}

.copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-7-mayday;
  width: 100%;
  padding: 0 8px;
  font-size: 12px;
  * {
    width: 14px;
    height: 18px;
  }
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.1);
    color: $blue-mayday;
  }
}

.click-bounce {
  animation: bounce 500ms ease;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.settings__public-item {
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}

:deep() .el-switch__core {
  height: 16px;
  width: 28px !important;
}

:deep() .el-switch__core:after {
  width: 12px;
  height: 12px;
}

:deep() .el-switch.is-checked .el-switch__core::after {
  margin-left: -13px;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.cover-wrapper {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  .cover-img {
    height: 100px;
    object-fit: contain;
  }
}

.img-placeholder-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  border: 1px $grey-4-mayday solid;
}
.img-placeholder-wrapper-empty {
  height: 100px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
}

.img-placeholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 12px 0;
  margin-left: -6px;
}

.img-placeholder-hover {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-placeholder-wrapper-preview {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.reveal {
  width: 18px;
  height: 18px;
  transform-origin: center;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  &:hover {
    color: $blue-mayday;
    background: rgba($blue-mayday, 0.1);
  }

  * {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
  }
  .open-reveal {
    transform: rotate(90deg);
  }
}
