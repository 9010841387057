@import "@/assets/scss/main.scss";

.item-editor__input {
  border: none;
  padding: 0;
  margin-left: -5px;

  &::placeholder {
    color: $grey-5-mayday;
  }
}

.item-editor__input--label {
  color: $grey-9-mayday;
}

.item-editor__input--category {
  font-size: 1rem;
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}
