@import "@/assets/scss/main.scss";

.settings__public-item {
  margin-bottom: 12px;
  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__ai-btn {
      background-color: $purple-5-mayday;
      color: white;
      border-color: $purple-5-mayday;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        background-color: rgba($purple-5-mayday, 0.7);
        border-color: rgba($purple-5-mayday, 0.7);
      }
    }
  }
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.img-placeholder-wrapper-empty {
  height: 100px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
}

.img-placeholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 12px 0;
  margin-left: -6px;
}

.img-placeholder-hover {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-placeholder-wrapper-preview {
  max-width: 100%;
  display: flex;
  align-items: center;
}
