@import "@/assets/scss/main.scss";

.users-administrate-header {
  background-color: $grey-1-mayday;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
  z-index: 2 !important;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.select-wrapper {
  height: 32px;
  :deep() .el-select {
    width: 200px;
  }
  :deep() .el-select .el-input .el-input__inner {
    height: 32px !important;
    line-height: 32px;
    width: 100%;
    background-color: $blue-mayday;
    border: none;
    &::placeholder {
      color: white !important;
    }
  }

  :deep() .el-select .el-input .el-select__caret {
    color: white;
  }
}
.search {
  height: 32px;
  max-width: 300px;
  min-width: 150px;
  width: 100%;
  :deep() .el-input__inner {
    height: 100%;
  }
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trash {
  &:hover {
    color: $red-mayday;
    cursor: pointer;
  }
}

.quit-redirection {
  color: $grey-7-mayday;

  &:hover {
    color: $blue-mayday;

    border-radius: 4px;
  }
}

.quit-wrapper {
  padding: 5px;
}

.select-bulk-edit {
  color: white;
}

:deep() .indicator-popconfirm {
  &__delete-user {
    height: 32px;
  }
}
