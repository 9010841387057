@import "@/assets/scss/main.scss";

.product-search {
  margin-top: -64px;
  position: relative;
}

.characteristic-button {
  button {
    font-size: 14px;
  }
}

.icon-picture {
  font-size: 16px;
}
