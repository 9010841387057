@import "@/assets/scss/main.scss";

.feedbacks-container {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: white;
  overflow: hidden;
  border: 1px solid $grey-2-mayday;
  border-radius: 8px;
  bottom: -8px;
  right: 8px;
  font-size: 10px;
  color: $grey-6-mayday;

  &.disabled {
    pointer-events: none;
  }

  .upvote {
    cursor: pointer;
    padding: 2px 4px 2px 6px;
    &:hover,
    &.selected {
      color: $green-5-mayday;
    }
  }

  .downvote {
    cursor: pointer;
    padding: 2px 6px 2px 4px;

    &:hover,
    &.selected {
      color: $red-5-mayday;
    }
  }
}
.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
}
