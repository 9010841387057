@import "@/assets/scss/main.scss";

.view-container {
  width: fit-content;
  padding-right: 8px;
}
.analytics-contents-table-container {
  .header {
    display: flex;
  }

  .analytics-contents-table {
    margin-top: 20px;

    :deep() .el-table__body-wrapper {
      max-height: 40vh;
    }

    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .article-icon,
      .diagnostic-icon,
      .analyze-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .content-icon {
        display: inline-block;
      }
      .article-icon {
        background-image: url('~@/assets/article-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }
      .diagnostic-icon {
        background-image: url('~@/assets/diag-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .collection-title.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .collections > .cell {
    white-space: nowrap;
  }
}
