@import "@/assets/scss/main.scss";

.button-cascader {
  width: 100%;
  background-color: white;
  padding: 10px 8px;
  border: 1px solid $grey-4-mayday;
  color: $grey-5-mayday;
  border-radius: 4px;
  font-weight: bold;
}
.button-cascader:hover {
  border-color: $grey-5-mayday;
}
.button-cascader:focus {
  border-color: $blue-mayday;
}
.z-index {
  z-index: 10;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}
.icon {
  width: 16px;
  height: 16px;
}
.grey-4 {
  color: $grey-4-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.grey-8 {
  color: $grey-8-mayday;
}
