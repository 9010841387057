@import "@/assets/scss/main.scss";

.tooltip-container {
  position: relative !important;
  min-width: 0 !important;
  font-size: 12px !important;

  &:hover .tooltip-text {
    visibility: visible !important;
  }
}
.overflow {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  &-active {
    cursor: default !important;
  }
}

.tooltip-text {
  font-size: 10px !important;
  color: white !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
  z-index: 100 !important;
  padding: 4px !important;
  background-color: black !important;
  position: fixed !important;
  visibility: hidden !important;
  box-shadow: $shadow-mayday !important;
  &-bottom {
    bottom: 16px !important;
  }

  &::before {
    content: ' ' !important;
    position: absolute !important;
    bottom: 100% !important; /* At the top of the tooltip */
    margin-left: -8px !important;
    border-width: 8px !important;
    border-style: solid !important;
    border-color: transparent transparent black transparent !important;
    box-shadow: $shadow-mayday !important;
    &:hover .tooltip-text {
      visibility: visible !important;
    }
  }
  &-right::before {
    right: 25% !important;
  }
  &-left::before {
    left: 25% !important;
  }

  &:hover {
    visibility: visible !important;
  }
}
