@import "@/assets/scss/main.scss";

.staged-push-icon {
  color: $grey-4-mayday;

  &:hover {
    color: $blue-5-mayday;
  }
}
.version {
  flex-grow: 1;
}
.version-title {
  font-size: 20px;
  font-weight: 600;
}

.version-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}

.content-version {
  display: flex;
  padding: 4px;
  border-radius: 2px;
}

.content-version-history {
  height: 60vh;
  overflow: auto;
}

.content-version:hover {
  background-color: $grey-1-mayday;
  cursor: pointer;
}

.selected {
  background-color: $grey-2-mayday;
}

.tooltip-wrapper {
  height: fit-content;
}
