@import "@/assets/scss/main.scss";

.analytics-content-container {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .analytics-content-graph {
    height: 250px;
    position: relative;
  }

  .empty-state-container {
    text-align: center;
  }
}
