@import "@/assets/scss/main.scss";

.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}
