@import "@/assets/scss/main.scss";

.status-indicator {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex: none;
}

.no-status {
  border: 1px solid $grey-6-mayday;
  background-color: $grey-3-mayday;
  .indicator {
    color: $grey-6-mayday;
  }
}

.published {
  border: 1px solid $blue-mayday;
  background-color: $blue-mayday;
  .indicator {
    color: white;
  }
}

.loading {
  .indicator {
    color: $purple-5-mayday;
    width: 20px;
    height: 20px;
  }
}

.draft {
  border: 1px solid $grey-6-mayday;
  background-color: $grey-3-mayday;
  .indicator {
    color: $grey-6-mayday;
  }
}
