@import "@/assets/scss/main.scss";

.answer-editor-card {
  min-height: 250px;
}

.answer-editor-card {
  :deep() .card-body {
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
  }
}

.answer-editor-card {
  :deep() .card-header {
    padding: 15px;
  }
}

.diagnostic-editor-card {
  :deep() .card-body {
    padding: 0px;
  }
}

.diagnostic-editor-card {
  :deep() .card-header {
    padding: 15px;
  }
}

.title-input {
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 8px;
}

.title-input:focus {
  outline: none;
  border: none;
}

.subject-input {
  font-size: 24px;
  line-height: 1.5;
  background-color: transparent;
  border: none;
}

.subject-input:focus {
  outline: none;
}

.add-step-button {
  border: 1px solid lightgrey;
  border-radius: 2px;
  color: $grey-5-mayday;
}

.add-step-button:hover {
  color: white;
}

.add-step-button:hover img {
  filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(201deg) brightness(104%)
    contrast(100%);
}

.add-step-btn-icon {
  max-width: 15px;
  filter: invert(85%) sepia(12%) saturate(189%) hue-rotate(169deg)
    brightness(84%) contrast(88%);
  margin-right: 15px;
  margin-bottom: 2px;
}

.add-step-input {
  letter-spacing: 0.025em;
  display: block;
  width: 190px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  color: $grey-5-mayday;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.add-step-input::placeholder {
  color: lightgrey;
}
.add-step-input:focus {
  outline: none;
  border-color: $blue-mayday;
  color: $blue-mayday;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}

.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding-right: 2px;
}

.modify-step:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}
.diag-steps {
  display: block;
}

.diag-title:hover .edit-title {
  visibility: visible;
}

.edit-title {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.diagnostic-builder-main {
  margin-bottom: 40vh;
}
