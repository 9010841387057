@import "@/assets/scss/main.scss";

.empty-state {
  padding-top: 40px;

  &-icon {
    width: 100px;
    height: 100px;
    background-image: url('~@/assets/empty-state-data.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  &-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: black;
  }

  &-text {
    margin-bottom: unset;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-5-mayday;
  }
}
