@import "@/assets/scss/main.scss";

.search-user-input {
  padding: 8px;
  padding-bottom: 0;
  margin-bottom: 4px;
}
:deep() .el-input__inner {
  border-radius: 8px;
}

.user-list-wrapper {
  max-height: 200px;
  overflow: scroll;
}
.user-list-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  &:hover {
    background-color: $grey-3-mayday;
    cursor: pointer;
  }
  &__selected {
    background-color: $grey-3-mayday;
    color: $blue-mayday;
  }
}
.icon-wrapper {
  border-radius: 50%;
  border: 1px solid $grey-2-mayday;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 8px;
}
.icon {
  height: 20px;
}
.username {
  max-width: calc(100% - 32px);
  height: 100%;
  display: flex;
  justify-content: center;
}
.bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  color: $grey-5-mayday;
  font-size: 12px;
}
.show-more-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
}
.user-loading {
  width: 100%;
  justify-content: center;
  display: flex;
  color: $blue-mayday;
  height: 50px;
  align-items: center;
  font-size: 25px;
}
.el-input__icon {
  margin-left: 4px;
}
