@import "@/assets/scss/main.scss";

.icon-selector-wrapper {
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-2-mayday;
  }
}
