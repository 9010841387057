@import "@/assets/scss/main.scss";

.option__button-edit {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.option__button-trash {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $red-mayday;
  }
}
.label {
  height: 28px;
  margin: 2px 0;
}

.label .option {
  visibility: hidden;
  display: contents;
}

.label:hover .option {
  visibility: visible;
}
