@import "@/assets/scss/main.scss";

.nav-item {
  border: 1px solid $grey-2-mayday;
  border-radius: 3px;
}

.vertical-divider {
  max-height: 18px;
}

.flag-icon {
  height: 18px;
  width: 18px;
}

.input-group-alternative {
  :deep() .main-input {
    border: 1px solid $grey-2-mayday;
    border-radius: 4px;
  }
}
