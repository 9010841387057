@import "@/assets/scss/main.scss";

.graph-type-menu {
  border-bottom: unset;

  .el-menu-item,
  .el-menu-item:hover,
  .el-menu-item:focus {
    border-bottom: none;

    &:focus,
    &.is-active {
      border-bottom: 5px solid rgba($blue-mayday, 0.8);
      border-radius: 1px;
      color: $blue-mayday;
    }
  }

  .menu-icon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
