@import "@/assets/scss/main.scss";

.ask-container {
  display: flex !important;
  height: 100vh;
  width: 100%;
}

.chat_container {
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin: 0 auto 0 0;
  background-color: white;
  box-shadow: $shadow-sm-mayday;
}

.messages_container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.message_empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 0 40px;

  h1 {
    display: flex;
  }

  span {
    white-space: pre-wrap;
  }
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background-color: $grey-2-mayday;
  max-width: 60%;
  width: fit-content;

  &.question {
    align-self: flex-end;
    background-color: $purple-5-mayday;
    color: white;
    animation: question-enter 0.4s ease-in-out;
  }

  &.answer {
    align-self: flex-start;
    background-color: $grey-4-mayday;
    animation: answer-enter 0.4s ease-in-out;
  }

  &.error {
    align-self: flex-start;
    background-color: rgba($red-mayday, 0.75);
    animation: answer-enter 0.4s ease-in-out;
    color: white;
  }
}

.error_details {
  background-color: rgba($grey-4-mayday, 0.5);
  border-radius: 4px;
  padding: 8px;
  list-style: none;
  cursor: pointer;
}

.error_details summary {
  font-size: 12px;
  list-style: none;
  cursor: pointer;
}

.error_traceback {
  border-radius: 4px;
  font-size: 0.7em;
  padding: 12px;
  background-color: rgba($grey-4-mayday, 0.5);
  margin-top: 4px;
  margin-bottom: 0px;
}

.citation_list {
  background-color: rgba(white, 0.4);
  border-radius: 4px;
  padding: 8px;
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(white, 0.2);
  }
}

.citation_list summary {
  list-style: none;
  cursor: pointer;
}

.message_author {
  font-weight: bold;
  font-size: 0.7em;
  text-align: right;
}

.message_date {
  font-size: 0.5em;
  text-align: right;
}

.search-result-button {
  display: flex;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 8px;
  border-radius: 4px;
  flex: none;
  padding: 4px;
  border: solid 1px $purple-5-mayday;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: white;
    color: $blue-5-mayday;
    cursor: pointer;
  }
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }
}

.search_item {
  padding: 6px 12px;
  margin-bottom: 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $grey-2-mayday;
}
.score-text {
  color: $grey-6-mayday;
  font-size: 10px;
}

.context-str {
  border: solid 1px $grey-6-mayday;
  border-radius: 4px;
  padding: 4px;
}

.input_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  input {
    width: 100%;
    padding: 10px;
    margin-right: 8px;
    border: 1px solid $grey-2-mayday;
    border-radius: 5px;
  }

  .icon-button {
    margin-right: 4px;
  }

  button {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }
}
.el-tag a {
  color: $grey-2-mayday;
}

@keyframes answer-enter {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes question-enter {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.message_feedback {
  display: flex;
}

.thumbs-icon {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-8-mayday;
  height: 24px;
  width: 24px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: $grey-3-mayday !important;
  }
  &:active {
    background-color: $grey-4-mayday !important;
  }
}

.ask-side {
  width: 20%;
  display: flex;
  flex-direction: column;
}
