@import "@/assets/scss/main.scss";

.category {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 6px;
    max-width: 390px;
  }
  .description {
    font-size: 16px;
    color: $grey-8-mayday;
    margin-bottom: 18px;
    max-width: 350px;
  }

  .select {
    width: 60%;
  }

  .el-select {
    height: 32px;
  }
  :deep() .el-input {
    height: 100%;
  }
  :deep() .el-input__inner {
    height: 100%;
  }
  :deep() .el-input__suffix {
    display: flex;
    align-items: center;
  }
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
  }

  :deep() .el-select__tags {
    max-width: 100% !important;
  }
  .flag-wrapper {
    width: 23px;
    height: 21px;
    background-color: $grey-4-mayday;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .el-input {
    height: 32px;
    width: 350px;
  }
  .placeholder-value {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}
