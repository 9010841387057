@import "@/assets/scss/main.scss";

.empty-category-info {
  font-size: 14px;
}

.category-labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
