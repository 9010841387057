@import "@/assets/scss/main.scss";

.el-table__header-wrapper {
  position: fixed !important;
  height: 100px !important;
}

.mayday-table {
  :deep() table {
    width: 100% !important;
  }
}

.mayday-table {
  :deep() .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.mayday-table {
  :deep() .el-table-column--selection {
    padding-left: 15px;
  }
}

.mayday-table {
  :deep() .cell:active {
    white-space: unset;
    word-break: break-word;
    cursor: pointer;
  }
}

.item-count {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $unknown-blue-1-mayday;
}

.icon-button {
  color: $blue-mayday;
  border: none;
}
