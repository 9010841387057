@import "@/assets/scss/main.scss";

.notification-popover-container {
  position: fixed;
  bottom: 90px;
  left: 70px;
  top: 4px;
  z-index: 50;
  width: 350px;
  overflow: auto;
  border-radius: 4px;
  background-color: white;
  box-shadow: $shadow-mayday;
}

:deep() * {
  word-break: normal;
}
