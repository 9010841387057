@import "@/assets/scss/main.scss";

.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}
.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}

.analytics-contents-public-stats-card {
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 33%;
  }
}

.content-title-container {
  margin-right: 15px;
}

.analytics-views-stats-container {
  display: flex;
}

.analytics-views-container {
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
}

.analytics-views-graph {
  padding-right: 16px;
}

.footer-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.analytics-knowledge-stats-card:last-child {
  border-top: 2px solid $grey-2-mayday;
  padding-top: 15px;
}

.stat-cards-container {
  width: 25%;
  padding: 0px 16px;
  border-left: 2px solid $grey-2-mayday;
}

.article-icon {
  background-image: url('~@/assets/article-icon-v2.1.svg');
  background-size: cover;
}
.diagnostic-icon {
  background-image: url('~@/assets/diag-icon-v2.1.svg');
  background-size: cover;
}

.analytics-content-table,
.analytics-contents-search-stats {
  margin-top: 32px;
}
