@import "@/assets/scss/main.scss";

.base-action-wrapper {
  display: flex;
  gap: 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 8px;
  background-color: $grey-1-mayday;
}

.icon-container {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 8px;
}

.right-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.base-action-create-form-header {
  background-color: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  height: 28px;
  font-size: 14px;
  font-weight: 600;
  padding-block: 3px;
  padding-left: 12px;
}

.base-action-create-form-body {
  background-color: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 12px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;

  :deep() .workflow-input-wrapper {
    padding: 0px;
  }

  :deep() .workflow-section-title__header {
    margin-bottom: 4px;
  }
}

.divider {
  margin-inline: -12px;
  margin-top: 16px;
}

.base-action-create-form-footer {
  display: flex;
  justify-content: flex-end;
}
