@import "@/assets/scss/main.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-2-enter-active,
.fade-2-leave-active {
  transition: opacity 0.5s;
}
.fade-2-enter,
.fade-2-leave-to {
  opacity: 0;
}

.floating-button {
  position: fixed;
  color: white;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 90px;
  background-color: $blue-mayday;
  border-radius: 50px;
  text-align: center;
  font-size: 40px;
  z-index: 1000;
}

.plus-icon {
  cursor: pointer;
  transition: transform 200ms ease;
}

.plus-icon:hover {
  transform: scale(1.1);
}
