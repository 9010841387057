@import "@/assets/scss/main.scss";

.diag-steps {
  display: block;
}

.add-step-input {
  letter-spacing: 0.025em;
  display: block;
  width: 190px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  color: $grey-5-mayday;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid $grey-3-mayday;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.add-step-input::placeholder {
  color: lightgrey;
}

.add-step-input:focus {
  outline: none;
  border-color: $blue-mayday;
  color: $blue-mayday;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}
