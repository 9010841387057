@import "@/assets/scss/main.scss";

.ask-skeleton-loader {
  height: 100%;
  width: 100%;
}
.loader-bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-loader-gradient {
  width: 100%;
  height: 16px;
  border-radius: 4px;
}

span:nth-of-type(odd) {
  background: linear-gradient(
    to right,
    $purple-1-mayday,
    rgba($purple-5-mayday, 0.2) 50%,
    rgba($purple-5-mayday, 0.2) 80%
  );

  animation: grow 1.5s forwards infinite alternate;
}
span:nth-of-type(even) {
  background: linear-gradient(
      to right,
      $purple-1-mayday,
      rgba($purple-5-mayday, 0.2) 50% rgba($purple-5-mayday, 0.2) 80%
    ),
    rgba($purple-5-mayday, 0.2);
  animation: reveal 1.5s forwards infinite alternate;
}
@keyframes grow {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
