@import "@/assets/scss/main.scss";

.update-translations-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  background-color: $purple-2-mayday;
  height: 30px;
  position: relative;
  border-radius: 8px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: none;
  max-width: 100%;
}
.redirect-here {
  color: $purple-5-mayday;
  font-weight: 700;
  cursor: pointer;
  margin: 0px 3px;
}
.close-icon {
  position: absolute;
  right: 20px;
  color: $grey-7-mayday;
  height: 12px;
  &:hover {
    color: $grey-9-mayday;
    cursor: pointer;
  }
}
.banner-text {
  font-size: 14px;
  max-width: calc(100% - 80px);
  display: flex;
  flex-direction: row;
}
.last-bit {
  max-width: calc(100% - 370px);
  text-overflow: ellipsis;
  overflow: hidden;
}
