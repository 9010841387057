@import "@/assets/scss/main.scss";

.toast {
  width: 380px;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  z-index: 999;
  box-shadow: $shadow-mayday;
  color: $purple-5-mayday;
  &__header {
    padding: 0px 8px 0px 8px;
    background: $grey-1-mayday;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    height: 40px;
    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 8px;
    }
  }
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    &__done {
      color: $grey-8-mayday;
      height: 80px;
      flex-direction: column;
    }
    &__pending {
      gap: 8px;
      padding: 12px 0px 12px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &__failed {
      width: 100%;
      flex-direction: column;
    }
  }
  &__footer {
    justify-content: end;
    padding-right: 8px;
    height: 20px;
    display: flex;
    align-items: center;
  }
}
.pending-button {
  border-radius: 4px;
  font-size: 10px;
  height: 14px;
  width: 25px;
  background-color: $purple-2-mayday;
  color: $purple-5-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: white;
    border: 1px solid $purple-5-mayday;
    cursor: pointer;
  }
}
.button {
  border-radius: 4px;
  height: 100%;
  line-height: 20px;
  background-color: $purple-5-mayday;
  cursor: pointer;
  color: white;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}
.close {
  color: $grey-8-mayday;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.loading-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.failed-title {
  color: $red-mayday;
}
.button-wrapper {
  display: flex;
  justify-content: end;
}
