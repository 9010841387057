@import "@/assets/scss/main.scss";

.item-editor__input {
  border: none;
  padding: 0;

  &::placeholder {
    color: $grey-5-mayday;
  }
}

.item-editor__input--label {
  color: $grey-9-mayday;
}

.item-editor__input--category {
  color: $grey-5-mayday;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 600;
}
