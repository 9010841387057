@import "@/assets/scss/main.scss";

.navigator-header {
  padding-left: 8px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigator-header-title {
  font-weight: 700;
  color: black;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
  flex: 1 1 0%;
  margin-right: 8px;
}

.navigator-header-options {
  flex: none;
  display: flex;
  align-items: center;
  z-index: 50;
  height: 24px;
  border-right: 1px solid $grey-2-mayday;
  padding-right: 12px;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
