@import "@/assets/scss/main.scss";

.ask-feedback-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: fit-content;
}
.thumb-container {
  color: $purple-5-mayday;
  background-color: white;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__hovered {
    background-color: $purple-5-mayday;
    color: white;
    cursor: pointer;
  }
}
.thumbs-down {
  font-size: 14px;
}
.thumbs-up {
  font-size: 14px;
}
