@import "@/assets/scss/main.scss";

.breadcrumb-container {
  position: relative;
  left: 8px;
}

.breadcrumb-wrapper {
  margin-top: 10px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  width: 100% !important;
}

.breadcrumb-home:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.breadcrumb-elem {
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  color: $grey-7-mayday !important;
  font-weight: 700 !important;
  &:last-child {
    .breadcrumb-label {
      color: $blue-mayday !important;
      cursor: auto !important;
    }
  }
  &:hover:not(:last-child) {
    cursor: pointer !important;
    .breadcrumb-label {
      color: $blue-mayday !important;
    }
  }

  .breadcrumb-divider {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: $grey-4-mayday !important;
    font-size: 12px !important;
    margin: auto 8px;
  }
}
