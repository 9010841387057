@import "@/assets/scss/main.scss";

.title-wrapper {
  display: flex;
  align-items: center;

  .title-icon {
    color: $blue-mayday;
  }

  .title {
    align-self: flex-end;
    margin-left: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: $unknown-blue-1-mayday;
  }
}

.adoption-rate-modal-container {
  padding-top: 25px;

  .date-icon {
    color: $blue-mayday;
    margin-right: 16px;
  }

  .chart-wrapper {
    padding-top: 32px;

    .adoption-rate-chart {
      margin-top: 21px;
    }
  }
}
