@import "@/assets/scss/main.scss";

form {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  flex-wrap: nowrap;
}

label {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-bottom: 4px;
  :first-child {
    margin-right: 6px;
  }
}
