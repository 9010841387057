@import "@/assets/scss/main.scss";

.case-viewer-header-count {
  margin-left: 16px;

  .icon {
    color: $grey-5-mayday;
    margin-right: 8px;
    max-width: 16px;
    max-height: 16px;
    cursor: pointer;
  }
}
