@import "@/assets/scss/main.scss";

.collections-navigator-breadcrumb {
  font-size: 16px;
  color: $grey-6-mayday;
  .chevron-icon {
    font-size: 12px;
  }
  .last-parent {
    color: $blue-mayday;
  }
}

.parent-breadcrumb {
  &:hover {
    text-decoration: underline;
  }
}
