@import "@/assets/scss/main.scss";

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  min-height: 150px;
}
