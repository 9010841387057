@import "@/assets/scss/main.scss";

.side-container {
  width: max-content;
}

.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;

  span {
    margin-left: 10px;
  }
}

.icon-green {
  color: $green-mayday;
}

.icon-grey {
  color: $grey-4-mayday;
}
