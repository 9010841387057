@import "@/assets/scss/main.scss";

.settings-container {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-left: 2px;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.settings-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
  overflow: hidden;
}

.settings-menu-wrapper {
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 32px;
  border-right: $grey-2-mayday 1px solid;
  flex: none;
}

.settings-list-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}
