@import "@/assets/scss/main.scss";

@media only screen and (max-device-width: 992px) {
  .side-pannel {
    display: none;
  }
}

.side-pannel {
  z-index: 0;
}

.new-article-editor {
  margin-bottom: 30vh;
}
