@import "@/assets/scss/main.scss";

.role-header {
  height: 130px;
  background-color: $grey-1-mayday;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 250px;
  flex: 0 0 auto;
}

.role-header-info {
  margin-right: 30px;
  .title {
    color: black;
    font-size: 28px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
  .description {
    color: $grey-8-mayday;
    font-size: 75%;
  }
}

.role-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: $shadow-lg-mayday;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 30px;
  z-index: 1;

  .role-icon {
    object-fit: contain;
    width: 6.5rem;
    height: 6.5rem;
  }
}

.modify-icon {
  max-width: 20px;
  cursor: pointer;
  color: $grey-5-mayday;
  padding: 2px;
  &:hover {
    border-radius: 3px;
    background-color: $grey-2-mayday;
    color: $blue-mayday;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
}

.title-input {
  padding: 0px;
  outline: none;
  border: none;
  background-color: $grey-1-mayday;
}
