@import "@/assets/scss/main.scss";

.settings {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    &__title {
        font-weight: bold;
    }

    &__model {
        &__attribute {
            &__input {
                padding: 4px 6px;
                border: 1px solid $grey-5-mayday;
                border-radius: 4px;
                width: 95%;
            }
        }
    }

    &__submission {
        display: flex;
        justify-content: flex-end;
        &__delete {
            border: 1px solid transparent;
            background-color: transparent;
            color: $red-mayday;
            border-radius: 4px;
            &:hover {
                border: 1px solid $red-mayday;
            }
        }
        &__save {
            border: none;
            background-color: $blue-mayday;
            color: white;
            border-radius: 4px;
        }
    }

}
