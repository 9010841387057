@import "@/assets/scss/main.scss";

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.search-wrapper {
  overflow: auto;
  height: 200px;
  padding: 4px 0px;
}

.search-item {
  max-width: 100%;
  min-width: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 2px 0 2px 0;
  border-radius: 4px;

  .content-icon {
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 14px;
    }
    .content-img {
      display: none;
    }
    .content-search-img {
      display: flex;
    }
  }

  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}

.search-item-hovered {
  background-color: rgba($blue-mayday, 0.1);
}

.pointer {
  cursor: pointer;
}

.published-tooltip {
  cursor: default;
  margin-top: 6px;
}

.settings__public-item {
  margin-bottom: 12px;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.loader {
  width: 32px;
  height: 32px;
  color: $blue-mayday;
}

.chevron-wrapper:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.chevron {
  width: 3.75px;
  height: 7, 5px;
}
