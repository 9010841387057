@import "@/assets/scss/main.scss";

.outdated-warning {
  color: $red-5-mayday;
}

.outdated-marker-container {
  width: 8px;
  height: 8px;
  position: relative;
  display: inline-block;
  margin-left: 4px;
}

.red-circle {
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 1px;
  bottom: 1px;
}

.label-tooltip {
  max-width: 300px;
}
