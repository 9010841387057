@import "@/assets/scss/main.scss";

.count-badge {
  padding: 4px;
  padding-inline: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: $blue-mayday;
  color: white;
  border-radius: 9999px;
  font-size: 10px;
  line-height: 14px;
}

.popover-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-open-link {
  color: $blue-mayday;
  height: 34px;
  display: inline-flex;
  gap: 4px;
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  border: 1px solid $blue-mayday;
  transition: min-width 0.2s ease-in-out;
  font-size: 14px;

  .dashboard-filters-icon {
    margin-right: 4px;
  }

  &:hover {
    background-color: $blue-mayday;
    color: white;
    cursor: pointer;
  }

  &:disabled {
    background-color: rgba($grey-8-mayday, 0.1);
    color: $grey-8-mayday;
    border: none;
    cursor: progress;
  }

  :deep() .el-badge__content {
    background-color: white;
    color: $blue-mayday;
    border-color: $blue-mayday;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
  }
}

.option-group-title {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.checkbox-group-wrapper {
  max-height: 50px;
  overflow-y: auto;
}

.collapse-wrapper :deep() {
  .el-collapse {
    border: none;
  }
  .el-collapse-item__arrow {
    color: black;
  }
  .el-collapse-item__header {
    border: none;
    padding-left: 8px;

    .collapse-title {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      align-items: center;
      display: inline-flex;
      gap: 8px;
    }

    .collapse-badge .el-badge__content {
      background-color: $blue-mayday;
      color: white;
      display: flex;
      top: 0;
      align-items: center;
      justify-content: center;
    }
  }

  .el-collapse-item__content {
    float: right;
    width: 100%;
    padding-left: 12px;
    padding-bottom: 0px !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }

  .el-switch {
    width: 100%;
    justify-content: space-between;
    .el-switch__label.is-active {
      color: $grey-8-mayday;
    }
    .el-switch__label {
      color: $blue-mayday;
    }
  }

  .el-checkbox-group,
  .el-radio-group {
    max-height: 300px;
    overflow-y: auto;
  }
}

.initials-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

:deep() {
  .el-radio-group {
    width: 100%;
  }
}

.checkbox-wrapper {
  display: block;
  width: 100%;
  border-bottom: 1px solid $grey-2-mayday;
  &:last-of-type {
    border-bottom: none;
  }

  .checkbox-label {
    font-family: 'Lato', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  :deep() {
    .el-checkbox,
    .el-radio {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 8px 8px 8px 4px;
      margin: 0;
      &:hover {
        background-color: $grey-1-mayday;
      }
    }
    .el-checkbox__label,
    .el-radio__label {
      padding: 0;
      font-size: 12px;
    }
  }
}

.reset-button {
  color: $grey-7-mayday;
  border: none;
  float: right;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding-right: 12px;
  padding-left: 12px;
  letter-spacing: 0.02em;

  .reset-button-label {
    margin-left: 8px;
  }

  &:disabled {
    color: $grey-4-mayday;
    cursor: not-allowed;
  }
}

.filter-icon {
  width: 16px;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.search {
  height: 34px;
  width: 100%;
  margin-bottom: 8px;
  :deep() .el-input__inner {
    height: 100%;
  }
}

.empty-message {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-7-mayday;
  margin-top: 8px;
  margin-bottom: 8px;
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-container {
  position: relative;
  :deep() .el-date-editor {
    width: 100%;
  }
  .calendar-clear-button {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 20px;
    height: 20px;
    color: $grey-7-mayday;
    z-index: 10;
    cursor: pointer;
    &:hover {
      color: $blue-mayday;
    }
  }
}
