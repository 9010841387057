@import "@/assets/scss/main.scss";

.automations-main {
  width: 100%;
  border: 1px solid $grey-2-mayday;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
}

.section-description {
  color: $grey-5-mayday;
}
.divider {
  border-bottom: 1px solid $grey-2-mayday;
}
.no-rules-icon {
  height: 24px;
  width: 24px;
  margin-right: 1em;
}

.label-tag {
  background-color: $blue-0_5-mayday;
  border: 1px solid $blue-5-mayday;
  color: $blue-5-mayday;
  overflow: hidden;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
