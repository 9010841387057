@import "@/assets/scss/main.scss";

.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.salesforce-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.salesforce-attributes-wrapper {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.salesforce-attributes-add-button {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding-inline: 8px;
  color: $blue-mayday;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  cursor: pointer;
}
