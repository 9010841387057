@import "@/assets/scss/main.scss";

.case-title {
  cursor: pointer;
  padding: 4px;
  &:hover {
    .case-icon {
      background-color: $grey-2-mayday;
    }
    .case-label {
      background-color: $grey-2-mayday;
    }
    .case-count {
      color: $blue-mayday;
      background-color: rgba($blue-mayday, 0.1);
    }
  }
}
.case-count {
  padding: 2px;
  line-height: 20px;
  border-radius: 4px;
}

.case-label {
  border-radius: 2px;
  padding: 2px;
  line-height: 20px;
}

.case-section {
  margin-left: 1.5rem;
}

.case-icon {
  color: $grey-6-mayday;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 2px;
}

.active-case {
  background-color: rgba($blue-mayday, 0.1);
  .case-icon {
    color: $blue-mayday;
  }
}

.case-collapse {
  padding: 0px 0px;
  border-left: 2px solid $grey-4-mayday;
  &:hover {
    border-color: $blue-mayday;
    background-color: $grey-1-mayday;
  }
  :deep() .el-collapse-item__header {
    border-bottom: 1px solid transparent !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :deep() .el-collapse__main {
    border: none;
  }
  :deep() .text-edition {
    padding: 8px !important;
  }
  :deep() .editor__content {
    margin: 16px 0px;
    border: 1px solid $grey-2-mayday;
    border-radius: 4px;
    background-color: $grey-1-mayday;
  }
}

.el-collapse__main {
  border-left: 2px solid $grey-2-mayday;
  padding-left: 16px;
}
