@import "@/assets/scss/main.scss";

.brain-taskt-toast {
  width: 380px;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  z-index: 999;
  box-shadow: $shadow-mayday;
  color: $purple-5-mayday;
}
