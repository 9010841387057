@import "@/assets/scss/main.scss";

.hosting-content-row {
  height: calc(100vh - 68px);
  overflow: auto;
  position: relative;
  margin-right: 0px;
  background-color: white;
  box-shadow: $shadow-mayday;
}

.untranslate-content {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.content-blur {
  width: 100%;
  height: 100%;
  background: rgba(246, 249, 252, 0.5);
}

.collapse-enter-active {
  animation: collapse-enter 0.4s ease-in-out;
}

.collapse-leave-active {
  animation: collapse-enter 0.4s ease-in-out reverse;
}

@keyframes collapse-enter {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0);
  }
}
.translation-banner {
  position: absolute;
  width: 100%;
  padding: 12px 20px 0 20px;
}

.toast-enter-active,
.toast-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.toast-enter {
  transform: translateX(100%);
  opacity: 0;
}
.toast-enter-to {
  transform: translateX(0);
  opacity: 1;
}
.toast-leave {
  transform: translateX(0);
  opacity: 1;
}
.toast-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.toasts-wrapper {
  position: fixed;
  right: 60px; // sidebar + padding
  top: 78px; //header + padding
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
  & > * {
    margin-bottom: 8px;
  }
}
