@import "@/assets/scss/main.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.update-content-spinner-main {
  color: $grey-6-mayday;
}

.update-content-spinner {
  display: flex;
}

.updating-label {
  font-size: small;
}
