@import "@/assets/scss/main.scss";

.user-header {
  height: 130px;
  background-color: $grey-1-mayday;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 250px;
  flex: 0 0 auto;
}

.user-header-info {
  margin-right: 30px;
  font-size: 28px;
  font-weight: 700;
}

.user-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: $shadow-lg-mayday;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 30px;
  z-index: 1;
}

.initials-wrapper {
  object-fit: contain;
  width: 6.5rem;
  height: 6.5rem;

  padding: 20px;
  border-radius: 4px;

  font-size: 40px;
  font-weight: 700;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
}
