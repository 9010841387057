@import "@/assets/scss/main.scss";

.content__list {
  padding-inline-start: 0px;
}

.list-item {
  list-style: none;
  margin: 0px;
}
