@import "@/assets/scss/main.scss";

.product__list {
  margin-left: 24px;
  padding-left: 0px !important;
  list-style-type: none;
}

.contents-wrapper {
  border-left: 4px solid $grey-2-mayday;
}

.section-title {
  color: $grey-7-mayday;
  text-transform: uppercase;
}

.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    border-left: 2px solid $grey-2-mayday;
    &:hover {
      border-color: $blue-mayday;
      background-color: $grey-1-mayday;
    }
  }
}

.content-icon {
  max-height: 16px;
  max-width: 16px;
}
