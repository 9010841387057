@import "@/assets/scss/main.scss";

.results {
  height: 100%;
  flex: 1;
  overflow-y: auto;
}

.accordion-item > * > * > * {
  padding-left: 10px;
}

.search-result-button {
  display: flex;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 8px;
  border-radius: 4px;
  flex: none;
  padding: 4px;
  border: solid 1px $purple-5-mayday;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: white;
    color: $purple-5-mayday;
    cursor: pointer;
  }
}

.score-text {
  color: $grey-6-mayday;
  font-size: 10px;
}

.context-str {
  border: solid 1px $grey-6-mayday;
  border-radius: 4px;
  padding: 4px;
  white-space: pre-wrap;
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }
}

.search-item {
  padding: 6px 12px;
  margin-bottom: 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $grey-2-mayday;
}
