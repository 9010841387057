@import "@/assets/scss/main.scss";

.ask-answer {
  display: flex;
  flex-direction: row;
  align-items: top;
  width: 100%;
  max-height: 350px;
  padding-top: 20px;
  overflow: hidden;
  padding-bottom: 10px;
}
.ask-result-with-answer {
  display: flex;
  flex-direction: row;
  max-height: 90%;
}

.decline {
  width: 100%;
}

.is-decline-answer {
  padding: 16px;
  color: $grey-8-mayday;
  font-size: 14px;
  padding-bottom: 10px;
}
.is-error-answer {
  padding: 16px;
  color: $red-5-mayday;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.error-retry-wrapper {
  width: 100%;
  justify-content: right;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
.error-retry {
  width: fit-content;
  border: 1px solid $grey-5-mayday;
  border-radius: 4px;
  padding: 3px 10px 3px 10px;
  color: $grey-8-mayday;
}

.action-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 5px;
}
.copy-container {
  color: $purple-5-mayday;
  background-color: white;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  font-size: 14px;
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
    cursor: pointer;
  }
}
.source-loading-icon {
  font-size: xxx-large;
  color: $purple-5-mayday;
}
