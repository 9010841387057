@import "@/assets/scss/main.scss";

.administrate-button {
  border: 1px solid;
  border-radius: 4px;
  height: 28px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    color: white;
    background-color: $blue-mayday;
  }
}

.invite-button {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-count {
  font-size: 40px;
  font-weight: 700;
  line-height: 35px;
  width: fit-content;
}

.user-title {
  color: $grey-6-mayday;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.stat-user-card {
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
  justify-content: space-between;
  transition: all 200ms ease;
  &:hover {
    box-shadow: $shadow-mayday;
  }
}
