@import "@/assets/scss/main.scss";

.el-dialog__wrapper {
  top: 2%;
  left: 60px;

  :deep() .el-dialog.is-fullscreen {
    border-radius: 8px;
    margin: auto;
    width: 95%;
    height: 98%;
  }

  :deep() .el-dialog__header {
    border-radius: 8px 8px 0px 0px;
    background-color: white;
    height: 70px;
    padding: unset;
    border-bottom: 2px solid $grey-2-mayday;
    display: flex;
    align-items: center;
    padding-left: 25px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
  }

  :deep() .el-dialog__body {
    overflow: auto;
    min-height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    height: fit-content;
    background: $body-bg;
    border-radius: 0px 0px 8px 8px;
    padding: unset;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
  }
}
