@import "@/assets/scss/main.scss";

.tag-container {
  display: inline-block;
  border-radius: 2px;
  padding: 2px 6px;

  .icon-display {
    width: 12;
    height: 12px;
    margin-right: 8px;
  }
}
