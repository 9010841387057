@import "@/assets/scss/main.scss";

.brain-task-toast__body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-height: 64px;
  height: auto;
  &__done {
    color: $grey-8-mayday;
    flex-direction: column;
  }
  &__pending,
  &__loading {
    gap: 8px;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__description {
      max-width: 75%;
    }
  }
  &__failed {
    width: 100%;
    flex-direction: column;
    &__title {
      width: 100%;
      color: $red-mayday;
    }
  }
}

.button {
  border-radius: 4px;
  height: 100%;
  line-height: 20px;
  background-color: $purple-5-mayday;
  cursor: pointer;
  color: white;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}

.loading-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.button-wrapper {
  display: flex;
  justify-content: end;
  width: 100%;
}
