@import "@/assets/scss/main.scss";

:deep() p {
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() li {
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() .mayday-editor__content__ProseMirror .alert,
.mayday-editor__content__ProseMirror .box,
.mayday-editor__content__ProseMirror .footnotes,
.mayday-editor__content__ProseMirror .note,
.mayday-editor__content__ProseMirror blockquote,
.mayday-editor__content__ProseMirror ol,
.mayday-editor__content__ProseMirror p,
.mayday-editor__content__ProseMirror pre,
.mayday-editor__content__ProseMirror ul {
  margin: 2px 0 !important;
}

:deep() .mayday-editor__content__ProseMirror code {
  padding: 1px 2px;
  border-radius: 4px;
  margin: 0 1px;
  font-size: 12px !important;
}

:deep() .mayday-editor__inline-content {
  padding: 1px 2px !important;
  font-size: 12px !important;
  line-height: 14px !important;
  margin: 0 !important;
}

:deep() .mayday-editor__inline-content__icon {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.message-editor {
  padding: 0;
  :deep() .velmld-parent {
    position: unset !important;
    width: 100%;
  }

  :deep() .mayday-editor {
    padding: 0px;
  }
}

:deep() .mayday-editor__content__ProseMirror > * + * {
  margin-top: 2px !important;
}
