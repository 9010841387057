@import "@/assets/scss/main.scss";

.satisfaction-rates {
  border-left: 2px solid $grey-4-mayday;
  display: grid;
  padding: 0px 10px;
  color: $grey-6-mayday;

  .fa {
    color: $grey-5-mayday;
  }
}

.normal-text {
  font-size: 1em;
}

.is-success {
  color: $blue-mayday !important;
}

.is-danger {
  color: $red-mayday !important;
}

.thumbs-up-count {
  font-size: 70%;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
