@import "@/assets/scss/main.scss";

.md-avatar {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  background: $grey-4-mayday;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-weight: bold;
}
.md-avatar-img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
