@import "@/assets/scss/main.scss";

.options-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .options {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      .section_title {
        font-size: 18px;
        font-weight: 700;
        color: $grey-8-mayday;
        border-top: 1px solid lightgray;
      }
      .option_title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }
      .option_content {
        display: flex;
        flex-direction: column;
        select {
          padding: 5px;
          border: 1px solid $grey-2-mayday;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          background-color: white;
        }
      }
    }
  }
}
