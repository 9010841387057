@import "@/assets/scss/main.scss";

.options-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0;
    padding: 4px 0;
    &:hover {
      color: $blue-2-mayday;
    }
  }
}
