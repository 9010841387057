@import "@/assets/scss/main.scss";

.template-item {
  width: 100%;
}

.template-item-wrapper {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
  transition: background-color 250ms;
  &:hover {
    background-color: rgba($grey-5-mayday, 0.15);
  }
  &:active {
    background-color: rgba($grey-5-mayday, 0.3);
  }
  .template-label {
    min-width: 0;
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.template-focused {
  background-color: white;
}
