@import "@/assets/scss/main.scss";

.case-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 2px;
}
.case-option {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px;
  .case-option-label {
    padding: 4px;
    line-height: 16px;
    font-size: 0.9rem;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.case-icon {
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    color: $blue-mayday;
  }
}

.checked-children-count {
  color: $blue-mayday;
  font-weight: 700;
  font-size: 0.9rem;
}
.case-node-navigator-label {
  :deep() .el-checkbox__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
