@import "@/assets/scss/main.scss";

.filters-drawer {
  :deep() .el-drawer__header {
    padding: 0;
    .drawer-header {
      display: flex;
      justify-content: space-between;
      padding: 28px 32px;

      .title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 129.8%;
        letter-spacing: 1px;
        color: black;
        margin: unset;
      }

      .save-cta-link {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: $blue-mayday;
        align-self: center;
      }

      .close-link-anchor {
        cursor: pointer;
      }
    }
  }

  .drawer-container {
    margin: 0 32px;
  }
}
