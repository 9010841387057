@import "@/assets/scss/main.scss";

.integration-card {
  background-color: transparent;
  padding: 10px 12px;
  border: 1px solid $grey-2-mayday;
  border-radius: 8px;
  transition: all 200ms ease;
  display: flex;
  justify-content: space-between;
}

.integration-card:hover {
  border: 1px solid $grey-3-mayday;
  cursor: pointer;
}

.integration-card-logo {
  border: 1px solid $grey-2-mayday;
  background-color: white;
  border-radius: 8px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .integration-logo {
    object-fit: contain;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.integration-card-info {
  .title {
    color: $grey-9-mayday;
    font-size: 14px;
    font-weight: bold;
  }
  .description {
    color: $grey-7-mayday;
    font-size: 12px;
  }
}

.integration-card-status {
  display: flex;

  * {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 2px;
    font-size: 12px;
  }
  .active {
    background: rgba($green-mayday, 0.1);
    border: 1px solid $green-mayday;
    color: $green-mayday;
  }
  .inactive {
    background: rgba($red-mayday, 0.1);
    border: 1px solid $red-mayday;
    color: $red-mayday;
  }
}
