@import "@/assets/scss/main.scss";

.modal-header {
  width: 100%;
  border-bottom: 1px solid $grey-4-mayday;
}
.close-icon-notification {
  cursor: pointer;
  color: $grey-4-mayday;
  &:hover {
    color: $grey-5-mayday;
  }
}
