@import "@/assets/scss/main.scss";

.case-children {
  overflow-x: hidden;
}
.without_bullet {
  list-style: none;
}
.case-icon {
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
