@import "@/assets/scss/main.scss";

.upload-tab {
  max-height: calc(90vh - 150px);
  overflow: auto;
}
.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}
