@import "@/assets/scss/main.scss";

.language-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 49px;
  padding: 0px 18px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  color: black;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-3-mayday;
  }

  &:hover {
    background-color: $grey-1-mayday;

    .label {
      transition: transform 250ms;
      transform: translateX(24px);

      .flag-emoji {
        visibility: visible;
        opacity: 1;
        transition: visibility 0ms, opacity 150ms linear;
      }
    }

    .item-label {
      width: 200px;
      transition: all 0.15ms linear;
    }

    .edit-action-btn {
      visibility: visible;
      opacity: 1;
      transform: translateX(-21px);
      transition: transform 150ms, visibility 0ms, opacity 150ms linear;
    }
  }

  &:active {
    background-color: $grey-2-mayday;
    transition: 0s;
  }
  &__disable {
    cursor: not-allowed !important;
  }
}

.flag-emoji {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  font-size: 16px;
  margin-right: 4px;
  width: 20px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 250ms, opacity 150ms linear;
}

.edit-action-icon {
  font-size: 12px;
  color: $grey-6-mayday;
}

.edit-action-btn {
  padding: 6px;
  line-height: 6px;
  border-radius: 2px;
  margin-right: -22px;
  visibility: hidden;
  opacity: 0;
  transition: transform 250ms, visibility 250ms, opacity 150ms linear;
}

.label {
  display: flex;
  align-items: center;
  margin-left: -24px;
  transition: transform 250ms;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-label {
  font-size: 12px;
  line-height: 14px;
  color: $grey-7-mayday;
  font-weight: 700;
  width: 231px;
}

.indicator {
  min-width: 8px;
  height: 8px;
  border-radius: 2px;
  border: 0.5px solid $grey-5-mayday;
  background-color: $grey-5-mayday;
}

.shrink {
  .item-label {
    width: 203px;
  }
}
