@import "@/assets/scss/main.scss";

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  overflow: hidden;
}
.title-container {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.dropdown-container {
  transition: all 0.3s ease;
  padding: 0 8px;
  max-height: 0;
  border-top: 0 solid $grey-4-mayday;
  &.opened {
    border-top: 1px solid $grey-4-mayday;
    max-height: 500px;
    padding: 8px 8px;
    overflow: auto;
  }
}
.chevron-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transition: transform 0.3s;
}
.popover-icon {
  width: 20px;
  height: 20px;
}
.open-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &.opened {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: $grey-2-mayday;
    color: $blue-5-mayday;
  }
  :first-child {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.filter-count {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-left: 4px;

  div {
    background-color: $blue-5-mayday;
    color: white;
    border-radius: 7px;
    height: 14px;
    margin-top: 2px;
    font-size: 10px;
    min-width: 14px;
    line-height: 13px;
    font-weight: bold;
  }
}
