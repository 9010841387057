@import "@/assets/scss/main.scss";

.completely-hidden {
  display: none;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
