@import "@/assets/scss/main.scss";

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.description-list {
  padding-inline-start: 16px;
}

.description-item {
  font-size: 80%;
  list-style-type: circle;
}

@media only screen and (max-device-width: 768px) {
  .product__header {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 16px;
    border: none;
    text-align: center;
    padding: 16px;
    word-break: break-word;
  }
  .product__subtitle {
    padding: 8px;
  }
}

@media only screen and (min-device-width: 768px) {
  .product__header {
    border-left: 3px solid $grey-4-mayday;
    .collapse-parameter-icon {
      color: $blue-mayday;
    }
    .product__subtitle {
      font-size: 32px;
    }
  }
}

.product__subtitle {
  font-weight: 500;
  color: $grey-7-mayday;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__manual__collapsable,
.product__manual__item-row {
  width: 100%;

  a .product__manual__link {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    color: $grey-9-mayday;

    &:hover {
      background-color: $grey-1-mayday;
      border-radius: 2px;
    }
  }
}

.border-codic {
  border-left: 1px solid $blue-mayday;
}
