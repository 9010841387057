@import "@/assets/scss/main.scss";

.search-on-focus-case {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.icon-wrapper {
  height: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  background-color: $blue-mayday;
  justify-content: center;
  margin-right: 6px;
  border-radius: 4px;
}
.icon {
  height: 14px;
  width: 14px;
  color: white;
}
