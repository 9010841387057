@import "@/assets/scss/main.scss";

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 90%;
  }
}

.contribution-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }
}

.contribution-button--selected {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  h4 {
    color: $blue-mayday;
  }
}
