@import "@/assets/scss/main.scss";

.parameters-collapse {
  margin: 16px 0px;
}

.collapse-parameter-icon {
  max-width: 14px;
  max-height: 14px;
  margin: 8px 0px;
}

.collapse-parameter-label {
  color: $grey-9-mayday;
}

.collapse-info-label {
  word-break: break-word;
}

.collapse-info-icon {
  color: $grey-5-mayday;
}

.info-section {
  margin: 8px 0px;
}

.sat-rate-component {
}

.parameters-side-bar {
  top: 24px;
}
