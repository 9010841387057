@import "@/assets/scss/main.scss";

.content-list-item-label {
  color: var(--label-color);
  background-color: var(--label-background-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 17px;
}
.content-label-icon {
  width: 10px;
  height: 10px;
}
.label-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
