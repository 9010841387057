@import "@/assets/scss/main.scss";

.filters-group-container {
  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $grey-6-mayday;
      text-transform: uppercase;
    }

    .info-icon {
      margin-left: 7px;
      color: $grey-5-mayday;
    }
  }

  .group-underline {
    height: 1px;
    background-color: $grey-2-mayday;
    border: none;
    margin: 6px 0 12px;
  }
}
