@import "@/assets/scss/main.scss";

.dropdown {
  padding: 0px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}

.fai_icon {
  width: 20px;
  color: $grey-5-mayday;
  * {
    font-size: 20px;
    height: 16px;
  }
}

.options_selection {
  max-width: 15px;
  color: $grey-5-mayday;
}

.options_selection:hover {
  color: $blue-mayday;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  margin: auto;
}
