@import "@/assets/scss/main.scss";

:deep() .el-select .el-select__tags {
  padding-left: 2rem !important;
}

:deep() .el-input--prefix .el-input__inner {
  padding-left: 2rem !important;
}

:deep() .el-input__prefix {
  left: 0;
  width: 2.2rem;
  height: 2rem;
  padding: 0;
  margin-top: 12px;
}
.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.select-icon-group {
  color: $grey-4-mayday;
  font-size: 14px;
  margin: auto;
  line-height: 0;
}

.icon-selector__select {
  width: 100%;
  cursor: pointer;
}

.read-icon {
  color: $grey-6-mayday;
  margin-right: 8px;
}

.overflow-group {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.content-view-setting__groups-area {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
}
