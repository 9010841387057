@import "@/assets/scss/main.scss";

.menu-collapse {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  overflow-x: hidden;
}
.menu-collapse-header {
  width: 100%;
  cursor: pointer;
}
.menu-collapse-header {
  .menu-collapse-title {
    color: $grey-5-mayday;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .menu-collapse-icon {
    color: $grey-5-mayday !important;
  }
}
.menu-collapse-title-open {
  .menu-collapse-title {
    color: $blue-mayday !important;
  }
  .menu-collapse-icon {
    color: $blue-mayday !important;
  }
}

.menu-collapse-content {
  width: 100%;
  overflow-x: hidden;
}
