@import "@/assets/scss/main.scss";

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.labels-placeholder {
  line-height: 1;
  font-size: 12px;
  color: $grey-7-mayday;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 90%;
  }
}

.icon-selector__select {
  width: 100%;
  cursor: pointer;
}

:deep() .el-select .el-select__tags {
  padding-left: 2rem !important;
}

:deep() .el-select .el-select__tags .el-tag {
  opacity: 1;
}

:deep() .el-input--prefix .el-input__inner {
  padding-left: 2rem !important;
}

:deep() .el-input__prefix {
  left: 0;
  width: 2.2rem;
  height: 2rem;
  padding: 0;
  margin-top: 10px;
}

:deep() .el-tag__close {
  color: $grey-6-mayday !important;
  border: 1px solid $grey-6-mayday !important;
  height: 16px;
  width: 16px;
  font-size: 12px;
  line-height: 1;
}

:deep() .el-tag__close:hover {
  color: white !important;
  background-color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
}

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.select-icon-label {
  color: $grey-5-mayday;
  font-size: 14px;
  margin-top: -4px;
  margin: auto;
  line-height: 0;
}

.overflow-item {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
