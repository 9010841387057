@import "@/assets/scss/main.scss";

.side-option {
  width: fit-content;
}

.item-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-badge__container {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 1;
}
