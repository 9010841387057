@import "@/assets/scss/main.scss";

.option-card {
  width: 278px;
  min-height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 19px;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  transition: border 250ms ease-in-out;
  &:hover {
    border: 1px solid $blue-mayday;
    cursor: pointer;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    min-height: 466px;
    &-disabled {
      cursor: not-allowed;
      &:hover .option-card {
        border: 1px solid $grey-7-mayday;
      }
    }
  }
  &-disabled {
    pointer-events: none;
  }
}

.chosen {
  border: 1px solid $blue-mayday;
  background: rgba($blue-mayday, 0.1);
}

.chosenIcon {
  color: $blue-mayday;
}

.title {
  font-size: 24px;
  font-weight: 700;
}
.warning {
  color: $grey-7-mayday;
  font-size: 12px;
}

.divider {
  width: 50%;
}

.icon-wrapper {
  width: 22px;
  height: 22px;
  padding: 4px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: $grey-7-mayday;
}

.icon {
  height: 100%;
  width: 100%;
}

.picture {
  height: 81px;
}

:deep() .el-radio__label {
  display: none;
}
