@import "@/assets/scss/main.scss";

.analytics-content-modal-stats-card {
  :deep() .stat-container:last-child {
    border-top: 2px solid $grey-2-mayday;
    padding-top: 24px;
  }
}

.footer-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.content-modal-container {
  .content-modal-base-metrics-container {
    padding-top: 21px;
    display: flex;
    justify-content: space-between;

    .content-modal-chart-card {
      flex-basis: 70%;
    }
  }

  .content-modal-overview-stats-card {
    width: 28%;
    :deep() .analytics-card-body-container {
      padding: 16px;
    }
    :deep() .stat-container {
      padding-bottom: 16px;
    }
  }

  .diagnostic-flow-chart-container {
    margin-top: 24px;
    .diagnostic-flow-chart {
      padding: 24px;
      display: flex;
      justify-content: center;
      :deep() #flowy-tree {
        max-width: 100%;
      }
      :deep() .items-center {
        padding: 4px 8px !important;
      }
      :deep() .font-bold.text-md.text-dark-blue {
        word-break: break-word;
      }
      :deep() .icon {
        max-width: 16px;
        max-height: 16px;
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
        }
      }
      :deep() .justify-between {
        justify-content: space-between;
      }
      :deep() img {
        display: none;
      }
      :deep() button {
        border: unset;
        &:focus {
          border: unset;
          outline: none;
        }
      }
    }
  }
  .trend-badge {
    width: 90%;
    height: 90%;

    &.up {
      transform: rotate(-45deg);
    }
    &.down {
      transform: rotate(45deg);
    }
  }
}

.header-title {
  justify-content: start;
  width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: bold;
}

.search-icon {
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
}
