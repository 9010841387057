@import "@/assets/scss/main.scss";

.new-label-container {
  display: flex;
  align-items: center;
}

.new-label {
  :deep() .item-editor__input {
    margin-left: 0px;
  }
}
