@import "@/assets/scss/main.scss";

.search-input {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  padding: 8px;
}
.overflow {
  max-height: 58vh;
  overflow: auto;
}
.search-results {
  background: white;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}
.is-dropdown {
  position: absolute;
  width: 100%;
  box-shadow: $shadow-lg-mayday;
  -webkit-box-shadow: $shadow-lg-mayday;
  z-index: 20;
}
.search-filters {
  padding: 0px 16px 16px 16px;
}
