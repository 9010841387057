@import "@/assets/scss/main.scss";

.floating-button {
  z-index: 100;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 90px;
}
.search-field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}
.archive-button {
  position: fixed;
  bottom: 144px;
  left: 84px;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.knowledge-card {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-radius: 0px;
}

/* SIDEBAR */
.small-navigation-bar {
  margin-left: 2px;
  margin-top: 68px;
  width: 60px;
  height: calc(100vh - 68px);
  overflow-y: auto;
  background-color: white;
  box-shadow: $shadow-sm-mayday;
  border-top-left-radius: 16px;
  border-right: 1px solid $grey-2-mayday;
}

.focus-element-icon {
  margin-top: calc(2.2rem + 60px);
  width: 32px;
}

.reduce-navigator-icon {
  width: 100%;
}

/* MAIN */
.main-edition-card {
  width: 100%;
  flex-shrink: 30;
  overflow: auto;
}

.editor-navigator-vue-header {
  height: 68px;
  display: flex;
}

.whole-card-pannel {
  width: calc(100vw - 60px);
  height: calc(100vh);
  border-radius: 0px;
}

.content-wrapper {
  min-width: 0;
  width: 100%;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.hosting-content-row {
  height: calc(100vh - 68px);
  overflow: auto;
  background-color: white;
  box-shadow: $shadow-sm-mayday;
}

.breadcrumb-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.page-title {
  font-size: 12px;
  font-weight: bold;
  color: $grey-6-mayday;
}

.translation-row {
  margin-top: -12px;
  padding-right: 12px;
}

.drawer-wrapper {
  overflow: hidden;
  height: 100%;
}

.collapse-enter-active {
  animation: collapse-enter 0.4s ease-in-out;
}

.collapse-leave-active {
  animation: collapse-enter 0.4s ease-in-out reverse;
}

@keyframes collapse-enter {
  0% {
    width: 0px;
  }
  100% {
    width: 400px;
  }
}
