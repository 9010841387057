@import "@/assets/scss/main.scss";

.tag {
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 10px;
  height: 17px;
  border: solid 1px;
  padding: 4px;
}

.auto {
  color: $purple-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $purple-1-mayday;
  }
}

.validated {
  color: $green-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $green-1-mayday;
  }
}

.rejected {
  color: $red-5-mayday;
  background-color: transparent;

  &:hover {
    background-color: $red-1-mayday;
  }
}

.tag-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.action-button {
  margin-left: 5px;
  pointer-events: all;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    color: white;
    opacity: 0.5;
  }
}
