@import "@/assets/scss/main.scss";

.footer-container {
  gap: 8px;
  margin-right: 4px;
}

.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 26px;
  height: 26px;
}

.input-label {
  font-weight: 700;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rounded-input {
  border: 1px solid $grey-5-mayday;
  padding: 4px 0px;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 4px 8px;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    width: 100px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-size: 12px;
  }
}
:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}
