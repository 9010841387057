@import "@/assets/scss/main.scss";

.ip-restriction-settings-drawer {
  width: 100%;
  &__ip-ranges {
    flex-direction: column;
    align-content: space-between;
    max-height: 75vh;
    overflow: auto;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      position: sticky;
      top: 0px;
      z-index: 1;
      background-color: white;
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &__header {
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: white;
      }

      &__column {
      }
      &__input {
        padding: 0 8px 0 0;
        :deep() .el-input__inner {
          padding: 0 8px;
        }
        &-invalid {
          :deep() .el-input__inner {
            border: 1px solid $red-mayday;
          }
        }
      }
      &__delete {
        padding-bottom: 16px;
        &__btn {
          &:hover {
            cursor: pointer;
            color: $red-mayday;
          }
        }
      }
    }

    &__add {
      width: fit-content;
    }

    &__submission {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
