@import "@/assets/scss/main.scss";

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.group-selector__label__icon {
  color: $grey-4-mayday;
}
