@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
/* Drawer style */
.hosting-container {
  padding-left: 20px;
}

.role-drawer {
  :deep() span:focus {
    outline: none;
  }
}
.role-drawer {
  :deep() .el-drawer__close-btn:focus {
    outline: none;
  }
}

.role-drawer {
  :deep() .el-drawer__header {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.role-feature-icon {
  color: #32325d;
  max-width: 14px;
}

.small-icon {
  max-width: 20px;
}

.cant-do-feature {
  color: $grey-4-mayday;
}

.can-do-feature {
  color: $grey-6-mayday;
}
/* End drawer style */

.sub-item {
  color: $grey-5-mayday;
}

.user-groups-table {
  :deep() .card {
    border: none;
  }
}

.small-icon-hoverable {
  width: 15px;
  height: 15px;
  padding: 2px;
  color: $grey-6-mayday;
  margin-left: 10px;
}
.small-icon-hoverable:hover {
  border-radius: 2px;
  background-color: $grey-2-mayday;
  cursor: pointer;
}

.settings-card-header-description {
  color: $grey-6-mayday;
  font-size: 80%;
}

.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.right-divider {
  border-top: 1px solid$grey-2-mayday;
}

.send-invitation {
  max-height: 50vh;
  overflow: auto;
  overflow-x: hidden;
}

.el-dropdown-link {
  cursor: pointer;
  color: $blue-3-mayday;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.vertical-line {
  border-left: 1px solid lightgrey;
}

.plus-icon {
  padding: 5px;
  border: 1px solid $blue-mayday;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.plus-icon:hover {
  background-color: rgba($blue-mayday, 0.4);
  color: white;
  cursor: pointer;
}
.plus-icon {
  padding: 5px;
  border: 1px solid $blue-mayday;
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.plus-icon:hover {
  background-color: rgba($blue-mayday, 0.4);
  color: white;
  cursor: pointer;
}

.main-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.menubar__input {
  margin: 0px;
}

.menububble__form {
  display: inline-block;
}

.menubar__button {
  background-color: Transparent;
  outline: none;
  border: none;
  border-radius: 5px;
}

.menubar__button:hover {
  background-color: #d5dae3;
}

.empty-placeholder {
  color: $grey-4-mayday;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: $unknown-blue-1-mayday;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: $grey-6-mayday;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
