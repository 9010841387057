@import "@/assets/scss/main.scss";

.redirection-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.redirection-view-icon {
  font-size: 5rem;
  color: $blue-mayday;
}

.redirection-view-btn {
  font-size: 16px;
  color: white;
  background: $blue-mayday;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  outline: none;
}
