@import "@/assets/scss/main.scss";

.summary-section {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  padding: 12px 18px;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.summary-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .summary-section__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;

    .summary-section__switch {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 12px;
      color: $grey-6-mayday;
      font-weight: 500;

      :deep() .el-switch__core {
        height: 16px;
        width: 28px !important;
      }

      :deep() .el-switch__core:after {
        width: 12px;
        height: 12px;
      }

      :deep() .el-switch.is-checked .el-switch__core::after {
        margin-left: -13px;
      }

      :deep() .is-checked .el-switch__core {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
      }
    }
  }

  .summary-section__actions {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: $grey-6-mayday;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $grey-2-mayday;
        pointer-events: none;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .dropdown-item__icon {
        font-size: 14px;
      }
    }

    .summary-section__date {
      padding: 2px 4px;
      border-radius: 4px;
      display: flex;
      gap: 4px;
      align-items: center;
      &.expired {
        color: $yellow-mayday;
        background-color: rgba($yellow-mayday, 0.1);
      }
    }

    .summary-section__button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-7-mayday;
      cursor: pointer;
      font-size: 16px;
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 4px;

      svg {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: $purple-5-mayday;
        background-color: $grey-3-mayday;
      }
    }
  }
}

.summary-section__body-container {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, height 0.4s ease-in-out;
  max-height: 200px;
  height: 100%;
}

.summary-section__body {
  transition: height 0.4s ease-in-out, min-height 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;

  &.empty {
    button {
      background-color: $purple-5-mayday;
      border-color: $purple-5-mayday;
      svg {
        margin-right: 4px;
      }
    }
    span:first-of-type {
      font-weight: 700;
      font-size: 16px;
      color: black;
    }
    span {
      font-size: 14px;
      color: $grey-6-mayday;
      font-weight: 400;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.loading {
    overflow: auto;
  }
}

.summary-section__deletion_container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;

  svg {
    font-size: 24px;
    color: $red-mayday;
  }
  span {
    font-size: 16px;
    color: $grey-6-mayday;
    font-weight: 700;
  }
}

.summary-section__textarea-container {
  position: relative;
  background-color: white;
  font-size: 14px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  transition: border-width 0.2s ease-in-out, padding 0.2s ease-in-out;
  padding: 4px;
  height: 100%;

  &:focus-within {
    border-color: $blue-mayday;
  }

  &.disabled {
    background-color: $grey-1-mayday;
    padding: 0;
    border: none;

    .summary-section__textarea {
      background-color: $grey-1-mayday;
    }
  }

  &.exceeded {
    border-color: $red-mayday;

    .summary-section__textarea-count span {
      color: $red-mayday;
    }
  }

  .summary-section__textarea-count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 8px;
    color: $grey-5-mayday;
  }

  .summary-section__textarea {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    border-radius: 4px;

    .summary-section__textarea-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .btn {
        color: $purple-5-mayday;
      }

      .btn-primary {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
        color: white;
      }
    }
  }

  .summary-section__error {
    color: $red-mayday;
    font-size: 12px;
  }
}
