@import "@/assets/scss/main.scss";

.page-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 24px;
}

.page-section {
  margin-top: 20px;
}

.download-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
