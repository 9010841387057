@import "@/assets/scss/main.scss";

.search-stat-card__main-stat {
  margin: 0;

  .search-stat-card__main-stat__text {
    line-height: 1;
  }
}

.search-stat-card__main-empty {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .empty-state-icon {
    width: 40px;
    height: 40px;
    background-image: url('~@/assets/empty-state-search.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  .empty-state-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: $grey-6-mayday;
  }
}

.search-stat-card__info-icon {
  width: 16px;
  &:hover {
    color: $blue-mayday;
  }
}

.search-stat-card__trend-icon--success {
  color: $green-mayday;
  transform: rotate(45deg);
}
.search-stat-card__trend-icon--fail {
  color: $red-mayday;
  transform: rotate(-45deg);
}
.search-stat-card__trend-icon {
  color: $blue-mayday;
}
.search-stat-card__trend-icon-wrapper {
  margin: auto;
}

.search-stat-card__link {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: $blue-mayday;
  font-size: 0.8em;
  padding: 0px;
}

.search-stat-card__icon {
  font-size: 2em;
}

.search-stat-card__button-wrapper--active {
  border-bottom: 4px solid $blue-mayday;
}

.search-stat-card__display-table-button {
  font-size: 1.3em;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.search-stat-card__display-table-button--active {
  font-size: 1.3em;
  cursor: pointer;
  color: $blue-mayday;
}

.search-stat-card__smart-fusion {
  margin-top: 8px;
  margin-left: -16px;
}

.search-stat-card__info-tooltip {
  color: $grey-5-mayday;
  font-size: 12px;
  margin-left: 8px;
}
