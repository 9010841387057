@import "@/assets/scss/main.scss";

.search-sidebar-content-preview {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.content-preview-wrapper {
  height: 100%;
  overflow-x: hidden;
}
.content-preview-title {
  padding: 0px 16px 0px 16px;
}
.mayday-editor-wrapper {
  zoom: 80%;
  padding-top: 8px !important;
  padding-left: 22px !important;
}
.steps-wrapper {
  padding: 0px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.return-button {
  width: 35px;
  height: 24px;
  background-color: $grey-3-mayday;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 10px;
}
.return-button:hover {
  cursor: pointer;
  background-color: $blue-mayday;
  color: white;
}
.breadcrumb-wrapper {
  padding-left: 12px;
  margin-bottom: 8px;
}
