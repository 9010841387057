@import "@/assets/scss/main.scss";

/* durations and timing functions.              */
.slide-fade-enter-active .slide-fade-leave-active {
  transition: all 0.05s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}

.search-field__input {
  border-radius: 4px;

  :deep() .form-control {
    height: 26px;
  }
  :deep() .input-group-text {
    padding: 0 8px;
  }
}

.search-field__button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: $grey-7-mayday;
}

.search-field__button--active {
  color: $blue-mayday;
}

.search-field__trigger-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: $grey-2-mayday;
  }

  .search-field__trigger {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}
