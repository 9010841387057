@import "@/assets/scss/main.scss";

.filtered-results-number {
  color: $blue-mayday;
  font-weight: 900;
}

.filtered-result-card {
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
}
