@import "@/assets/scss/main.scss";

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
:deep() .el-switch__core {
  height: 16px;
  width: 28px !important;
}

:deep() .el-switch__core:after {
  width: 12px;
  height: 12px;
}

:deep() .el-switch.is-checked .el-switch__core::after {
  margin-left: -13px;
}

.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
