@import "@/assets/scss/main.scss";

.title {
  padding-left: 16px;
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.content-icon {
  max-width: 16px;
  max-height: 16px;
}
