@import "@/assets/scss/main.scss";

.section {
  margin-bottom: 1rem;
  .section-title {
    color: $grey-4-mayday;
    text-transform: uppercase;
  }
}

.notification__editor {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
}
