@import "@/assets/scss/main.scss";

.export-container {
  border: solid lightgray 1px;
  border-radius: 5px;
  padding: 16px;
}

.type-name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-8-mayday;
  margin-bottom: unset;
}

.type-description {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.picker-container {
  width: 300px;
  margin-bottom: 16px;
}

.picker-container > div {
  width: 300px;
}

.el-dropdown-link {
  cursor: pointer;
  color: $blue-3-mayday;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.title-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.start-button {
  padding: 10px;
}
