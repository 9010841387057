@import "@/assets/scss/main.scss";

.public-knowledge-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.public-knowledge-header {
  padding: 16px 16px 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  flex: none;
  width: 100%;
}

.public-knowledge-layout-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding-left: 2px;
  padding-top: 2px;
}

.public-knowledge-layout {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  align-items: stretch;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
}

.public-knowledge-menu-wrapper {
  width: 280px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 32px;
  border-right: $grey-2-mayday 1px solid;
}

.public-knowledge-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}

.el-dropdown-link:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.el-dropdown-menu {
  left: 122px !important;
  top: 53px !important;
}

.status {
  position: absolute;
  left: 30%;
  width: 40%;
  top: 96px;
  background-color: white;
  width: 40%;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
}

.dropdown-links {
  color: $grey-7-mayday;
}

:deep() .dropdown-links:hover {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

:deep() .router-link-active {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

:deep() .el-loading-spinner {
  height: 100%;
  margin-top: -15px;
  .circular {
    width: 100%;
    height: 100%;
  }
}
