@import "@/assets/scss/main.scss";

.plugin-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.plugin-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.icon-state-info {
  color: $grey-4-mayday;
}
.active {
  color: $green-mayday !important;
}
.state-info {
  font-size: 12px;
}
