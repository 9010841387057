@import "@/assets/scss/main.scss";

:deep() .mayday-editor {
  padding: 0;
}

:deep().mayday-editor__slash-menu-command-button {
  z-index: 1;
}

.image-pointer {
  :deep() .mayday-editor {
    &__image-wrapper {
      img {
        cursor: pointer;
      }
    }
  }
}

:deep() summary {
  color: black;
}
