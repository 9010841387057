@import "@/assets/scss/main.scss";

.settings__plugin-item {
  margin-bottom: 12px;
}
.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
.toggle {
  position: absolute;
  right: 0;
  top: 0;
}
