@import "@/assets/scss/main.scss";

.salesforce-input-row-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  :deep() .workflow-input-wrapper {
    width: 45%;
  }
}

.separator-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  aspect-ratio: 1 / 1;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
}

.separator-icon {
  padding: 2px;
  color: $blue-mayday;
}
.delete-icon-wrapper {
  width: 10px;
}
.delete-icon {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
