@import "@/assets/scss/main.scss";

.container {
  display: flex;
  flex-direction: row;
}
.side {
  padding-top: 6rem;
  width: 200px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.main {
  flex: 0 0 auto;
  width: calc(100% - 230px);
  :deep() .el-tabs__item {
    color: $grey-5-mayday;
  }
  :deep() .el-tabs__item.is-active {
    color: $blue-mayday;
  }
}
