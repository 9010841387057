@import "@/assets/scss/main.scss";

.search-field {
  :deep() .form-control {
    font-size: 16px;
    font-weight: 500;
  }
  :deep() .form-control::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
}
