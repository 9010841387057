@import "@/assets/scss/main.scss";

#options_selection {
  max-width: 15px;
}

#dropdown-button {
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin: auto;
}

.dropdown {
  padding: 0px;
}

.dropdown-item:hover {
  background-color: rgba($blue-mayday, 0.05);
}

.dropdown-item :hover i {
  color: $blue-mayday;
}

.dropdown-item p {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item span {
  margin-left: 6px;
}

.dropdown-item h5 {
  display: flex;
  align-items: center;
}

.not-allowed {
  &:hover {
    cursor: not-allowed;
  }
}

.archive-item:hover {
  background-color: rgba($yellow-mayday, 0.1);
}

.soft-delete {
  background-color: rgba($red-mayday, 0.1);
  small,
  .edit-node-icon {
    color: $red-mayday;
  }
  &:hover {
    background-color: $red-mayday;
    small,
    .edit-node-icon {
      color: white;
    }
  }
}

.dropdown-item.soft-delete-disabled {
  background-color: $grey-1-mayday;
  & .edit-node-icon {
    color: $red-mayday;
  }
  & small {
    color: $grey-5-mayday;
  }
}
.dropdown-item.archive-disabled {
  & small {
    color: $grey-5-mayday;
  }
  &:hover {
    background-color: transparent !important;
  }
}

:deep() #dropdown {
  padding-bottom: 0;
}
