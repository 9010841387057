@import "@/assets/scss/main.scss";

.set-language-item {
  color: $grey-6-mayday;
  background-color: $grey-2-mayday;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-6-mayday;
  }

  &:hover {
    color: $grey-9-mayday;
    background-color: $grey-3-mayday;
  }

  .item-label {
    color: $grey-7-mayday;
  }
}
