@import "@/assets/scss/main.scss";

.header {
  background-color: $grey-1-mayday;
  border-bottom: 2px solid $grey-4-mayday;
  font-weight: bold;
}

.state-explanation {
  word-break: keep-all;
  padding-inline: 24px;
  padding-bottom: 20px;
}

:deep() .el-radio-button__inner {
  width: 100% !important;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday !important;
}

.user-guiding {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: $blue-mayday;
}

.quit {
  color: $red-6-mayday;
}

.icon-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  width: 48px;
  height: 48px;
}

.base-dialog-footer {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}
