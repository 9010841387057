@import "@/assets/scss/main.scss";

.knowledge-manage-settings-row-header {
  border-bottom: 1px solid $grey-2-mayday;
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.actions {
  width: 100px;
}

.public-settings {
  color: $grey-7-mayday;
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}

.public-settings-icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
