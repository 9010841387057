@import "@/assets/scss/main.scss";

:deep() .el-tabs__item {
  color: $grey-5-mayday;
}
:deep() .el-tabs__item.is-active {
  color: $blue-mayday;
}

.tab-selector {
  display: flex;
  gap: 8px;
}

.error-icon {
  color: $red-mayday;
}

.tab-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
