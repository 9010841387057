@import "@/assets/scss/main.scss";

input {
  font-size: 24px;
  line-height: 29px;
  border: none;
  z-index: 1;
  background: transparent;
}
input::placeholder {
  font-weight: 100;
  color: $grey-5-mayday;
}
.autocomplete-container {
  font-size: 24px;
  height: 29px;
  border: none;
  font-weight: 100;
  color: $grey-4-mayday;
  position: absolute;
  left: var(--custom-left-position);
  display: flex;
  align-items: center;
  max-width: calc(500px - var(--custom-left-position));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  #dummy {
    visibility: hidden;
    display: flex;
    align-items: center;
    max-height: 100%;
    white-space: nowrap;
  }
  .autocomplete-label {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.search-input-wrapper {
  background: white;
  padding: 12px;
  border-radius: 2px;
  position: relative;
}
.search-icon {
  padding-right: 8px;
  font-size: 21px;
  color: $grey-6-mayday;
  width: 32px;
}
.btn-delete {
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  color: $grey-6-mayday;
  background: $grey-1-mayday;
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
}
.btn-delete-icon {
  margin-right: 3px;
}

@media only screen and (max-device-width: 768px) {
  input {
    font-size: 16px;
    line-height: 29px;
  }
}
