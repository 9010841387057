@import "@/assets/scss/main.scss";

.template-viewer {
  height: calc(100vh - 68px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  box-shadow: $shadow-mayday;
}

.toast-position {
  top: 45px;
  right: 12px;
}
