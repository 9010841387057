@import "@/assets/scss/main.scss";

.select-input {
  width: 100px;
}

.workflow-input {
  width: 100%;
  :deep() .el-input__inner {
    height: 43px;
  }
}
