@import "@/assets/scss/main.scss";

.automations-menu {
  width: 350px;
}

.delete-btn {
  width: 20px;
  color: $grey-5-mayday;
  &:hover {
    color: $red-mayday;
  }
}
.linking {
  min-width: 350px;
}

.link-search-bar {
  min-width: 250px;
}

.linked-btn {
  min-width: 250px;
}

.attached-file {
  word-break: break-word;
}

.max-width {
  max-width: 330px;
  margin: 0 auto;
}
