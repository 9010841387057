@import "@/assets/scss/main.scss";

.publish-content {
  margin: 8px;
}

.delete-popover-title {
  font-size: 16px;
  font-weight: bold;
}
.delete-popover-description {
  font-size: 12px;
  color: $grey-7-mayday;
  margin-bottom: 14px;
}
.changes {
  margin-bottom: 10px;
}

.cancel-button {
  border: none;
}
