@import "@/assets/scss/main.scss";

.action-items-section__empty {
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $grey-1-mayday;
  text-align: center;
  border-radius: 4px;

  &.expanded {
    height: 188px;
    gap: 8px;
    padding: 12px 18px;
  }
  .btn {
    background-color: $purple-5-mayday;
    border-color: $purple-5-mayday;
    svg {
      margin-right: 4px;
    }
  }

  .action-items-section__empty-title {
    font-size: 16px;
    font-weight: 700;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  .action-items-section__empty-description {
    font-size: 14px;
    font-weight: 400;
    color: $grey-6-mayday;
  }

  .ai-drawer__badge-container {
    margin-top: -4px;
  }

  .action-items-section__infos {
    font-size: 12px;
    color: $grey-6-mayday;
  }
}

.action-items-section__body {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 0;
  overflow: hidden;
  font-size: 14px;

  &.loading {
    padding: 8px;
    height: 188px;
  }

  &.expanded {
    padding: 12px 18px;
    height: 188px;
  }

  &.editing {
    padding: 12px 18px;
    height: 188px;
  }

  .action-items-section__loader {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;
  }

  .action-items-section__deleting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      svg {
        font-size: 24px;
        color: $red-mayday;
      }
      span {
        font-size: 16px;
        color: $grey-6-mayday;
        font-weight: 700;
      }
    }
  }
}

.action-items-section__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .action-items-section__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    color: $purple-5-mayday;
    font-size: 16px;

    .action-items-section__switch {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 12px;
      color: $grey-6-mayday;
      font-weight: 500;

      :deep() .el-switch__core {
        height: 16px;
        width: 28px !important;
      }

      :deep() .el-switch__core:after {
        width: 12px;
        height: 12px;
      }

      :deep() .el-switch.is-checked .el-switch__core::after {
        margin-left: -13px;
      }

      :deep() .is-checked .el-switch__core {
        background-color: $purple-5-mayday;
        border-color: $purple-5-mayday;
      }
    }
  }

  .action-items-section__actions {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: $grey-6-mayday;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $grey-2-mayday;
        pointer-events: none;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .dropdown-item__icon {
        font-size: 14px;
      }
    }
  }

  .action-items-section__date {
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
    &.expired {
      color: $yellow-mayday;
      background-color: rgba($yellow-mayday, 0.1);
    }
  }
  .action-items-section__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-7-mayday;
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;

    svg {
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      color: $purple-5-mayday;
      background-color: $grey-3-mayday;
    }
  }
}

.action-items-section__editor {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 0.2s ease-in-out;
  align-items: flex-end;
  :deep() .mayday-editor {
    width: 100%;
    padding: 0 0 40px;
    height: 100px;
    overflow-y: auto;
    ul {
      padding: 0 14px;
      margin: 0;
      li {
        margin: 0;
        &::marker {
          color: $purple-5-mayday;
        }
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .btn {
    color: $purple-5-mayday;
  }

  .btn-primary {
    background-color: $purple-5-mayday;
    border-color: $purple-5-mayday;
    color: white;
  }
}

.action-items-section__actions-container {
  max-height: 120px;
  overflow-y: auto;
  :deep() ul {
    padding: 0 14px;
    margin: 0;
    li {
      margin: 0;
      &::marker {
        color: $purple-5-mayday;
      }
      p {
        font-size: 14px !important;
      }
    }
  }
}
