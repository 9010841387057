@import "@/assets/scss/main.scss";

.concepts-search-container {
  padding: 16px;
}

.card {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.loader {
  height: 50px;
  /* Add your loader styles here */
}
.load-more {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.card-number {
  min-width: 30px;
}

.card-numbers-wrapper {
  display: flex;
  gap: 20px;
}

.card-body {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.card-title {
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-description {
  font-size: 0.8em;
  color: darkgray;
}

.validated-number {
  color: $green-5-mayday;
  display: flex;
  gap: 5px;
}

.rejected-number {
  color: $red-5-mayday;
  display: flex;
  gap: 5px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 16px;
}

.search-input {
  flex: 1;
  max-width: 300px;
}

.search-input input {
  width: 100%;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  border: darkgray 1px solid;
}

.total-text {
  font-size: 0.8em;
  color: darkgray;
}

.footer {
  font-size: 0.8em;
  color: darkgray;
}

.el-select {
  height: 32px;
}

.delete-icon-wrapper:hover {
  cursor: pointer;
  .delete-icon {
    color: $blue-mayday;
  }
}

.plus-icon-wrapper {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  .plus-icon {
    font-size: 30px;
  }
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}
