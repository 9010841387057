@import "@/assets/scss/main.scss";

.preference-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}
.preference-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-language {
  :deep() .el-input__inner {
    height: 30px;
    width: 140px;
    padding-left: 48px;
  }
  :deep() .el-input__prefix {
    margin-top: 6px;
    padding-left: 16px;
  }
}

.option-container {
  display: flex;
  column-gap: 16px;
  letter-spacing: 0.02rem;
}
