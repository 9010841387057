@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.img-placeholder-wrapper {
  max-width: 107px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.img-placeholder-wrapper-empty {
  width: 107px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    cursor: pointer;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
  &:hover .img-placeholder {
    transform: translate(2px) scale(1.3);
  }
}

.img-placeholder {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.img-button {
  padding: 4px 8px;
  border-radius: 2px;
  width: 80px;
}

.display-on-hover {
  display: none;
}

.delete-icon-wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $grey-2-mayday;
  }
}

.delete-icon {
  width: 10px;
  height: 12px;
  color: $grey-7-mayday;
}

.download-group {
  padding: 2px;
}
.img-input-advise {
  font-size: 12px;
  color: $grey-6-mayday;
}
