@import "@/assets/scss/main.scss";

.drawer-header {
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.5px;
  }

  .desc {
    font-family: Lato;
    font-style: small;
    font-size: 12px;
    line-height: 1.3;
  }

  .close-icon-notification {
    cursor: pointer;
    color: $grey-4-mayday;
    &:hover {
      color: $grey-6-mayday;
    }
  }
}
