@import "@/assets/scss/main.scss";

.diagnostic-editor-card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 24px;
}

.diagnostic-message-editor-empty-state {
  width: 100%;
  &:hover {
    box-shadow: $shadow-mayday;
    cursor: pointer;
  }
}

.editor-ce-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
