@import "@/assets/scss/main.scss";

.condition-search-button {
  font-size: 12px;
}

.filter-remote-search-input {
  :deep() .el-input__prefix {
    display: flex;
    align-items: center;
    .search-icon {
      margin-left: 8px;
      color: $grey-4-mayday;
    }
  }

  :deep() .el-input__inner {
    // 30 (base) + 15 (figma)
    padding-left: 45px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    ::placeholder {
      color: $grey-5-mayday;
    }
  }
}
