@import "@/assets/scss/main.scss";

.cascader-container {
  width: 100%;
  background: white;
  border: 1px solid $grey-4-mayday;
  border-radius: 2px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.cascader-header {
  padding: 14px 10px;
  background-color: $grey-1-mayday;

  &.is-init {
    color: $grey-6-mayday;
  }
}
.option {
  padding: 12px 10px;
  margin: 1px 0px;
  cursor: pointer;

  &.is-focus {
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.4);
  }
}
.option:not(.is-disabled):hover {
  color: $blue-mayday !important;
  background-color: $grey-1-mayday;
}
.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &__selector {
    border: 1px solid $blue-mayday;
    border-radius: 1px;
  }
}
.grey-6 {
  color: $grey-6-mayday;
}
.grey-5 {
  color: $grey-5-mayday;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
.cascader-footer {
  padding: 12px;
}
.instruction {
  padding: 12px 14px;
  font-weight: bold;
}
.overflow {
  height: 15vh;
  max-height: 150px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.is-disabled {
  cursor: not-allowed;
}
