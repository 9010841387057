@import "@/assets/scss/main.scss";

.empty-state-alert {
  color: $grey-5-mayday;
}

.product-alert {
  :deep() .alert-inner--text {
    width: 100%;
  }
}

.product__alerts__item__details {
  :deep() * {
    margin-bottom: 0px;
  }
}

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.trigger-alert-modal {
  color: $grey-5-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.tooltip-icon {
  cursor: pointer;
}

.modal-menu-icon {
  color: $grey-5-mayday;
  height: 12px;
  visibility: hidden;
  cursor: pointer;
  &:hover {
    color: $red-mayday;
  }
}

.product-alert {
  &:hover {
    .modal-menu-icon {
      visibility: visible;
    }
  }
}

.product__list {
  border-left: 2px solid $grey-4-mayday;
  margin-left: 1.5rem;
  padding-left: 10px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}
