@import "@/assets/scss/main.scss";

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &:deep(.el-input__inner) {
    border-radius: 8px;
    height: 36px;
  }
  &:deep(.el-input__icon) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.category-operator {
  width: 86px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  &:deep(.el-input__inner) {
    padding-right: 16px;
  }
}

.filter-wrapper {
  display: flex;
  align-items: center;
  background-color: $grey-1-mayday;
  padding: 8px;
  width: 100%;
  .delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    border-radius: 4px;
    margin-left: 8px;
    &:hover {
      color: $red-5-mayday;
      background-color: rgba($red-1-mayday, 0.1);
    }
  }
}

.filter-selector-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
}
.filter-operator {
  cursor: pointer;
  margin-right: 8px;
  width: 36px;
  height: 36px;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 0;
  font-weight: bold;
  flex: none;
  background-color: white;
  &:hover {
    background-color: $grey-2-mayday;
  }
}

.filter-type-wrapper {
  padding-left: 42px;
  .button-icon {
    height: 100%;
    font-size: 14px;
    color: $grey-8-mayday;
    margin: 0 4px 0 4px;
  }
}
.filter-selector {
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  &:deep(.el-select__input) {
    margin-left: 6px !important;
  }
}

.button-icon {
  margin-right: 4px;
}

.filter-button {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: black;
  font-size: 12px;
}
