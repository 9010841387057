@import "@/assets/scss/main.scss";

.date-filter-picker {
  background-color: white;
}
.header {
  padding: 4px 8px;
}
.sub-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sub-option {
  padding: 12px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 24px;
  font-size: 14px;
  color: black;
  gap: 4px;
  &:hover {
    background-color: $grey-3-mayday;
    cursor: pointer;
  }
  &__selected {
    background-color: $grey-3-mayday;
    color: $blue-mayday;
  }
}
:deep() .el-select {
  .el-input .el-input__inner {
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }
}

:deep() .el-input__suffix {
  display: flex;
  align-items: center;
}

.clear-button {
  color: $grey-4-mayday;
  cursor: pointer;
}

.main-option-item {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 24px;

  padding: 4px 0px 4px 4px;
}
