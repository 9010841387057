@import "@/assets/scss/main.scss";

.delete-icon {
  cursor: pointer;
  padding-left: 4px;
  width: 12px;
  height: 12px;
}

.delete-icon:hover {
  color: $blue-mayday;
}
