@import "@/assets/scss/main.scss";

.custom-icon {
  width: 42px;
  height: 42px;
}
.contribution-button {
  background-color: transparent;
  border: 1px solid transparent;
  padding-top: 16px;
  padding-bottom: 10px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }
}
.input-label {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.input-alternative {
  box-shadow: none;
  border: 0;
}
.slide-x-left-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-x-left-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-x-left-fade-enter,
.slide-x-left-fade-leave-to {
  transform: translateX(150px);
}
.slide-x-right-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-x-right-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-x-right-fade-enter,
.slide-x-right-fade-leave-to {
  transform: translateX(-150px);
}

.options-divider {
  width: 50%;
  margin: 16px auto;
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.template-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.template-button {
  display: flex;
  margin-top: 8px;
  gap: 12px;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 16px;
  &:hover {
    background-color: $grey-1-mayday;
    border: 1px solid $grey-3-mayday;
    border-radius: 4px;
  }

  .template-icon-wrapper {
    border: 2px solid $grey-2-mayday;
    background-color: white;
    color: $grey-7-mayday;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .template-icon {
      width: 20px;
      height: 20px;
    }
  }

  .template-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    .title {
      font-size: 18px;
    }
    .description {
      font-size: 14px;
      color: $grey-5-mayday;
    }
  }
}
