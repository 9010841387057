@import "@/assets/scss/main.scss";

.parameters {
  margin-left: 2px;
}
.parameter {
  font-size: 10px;
  color: $grey-8-mayday;
  background-color: $grey-2-mayday;
  border: 1px solid $grey-8-mayday;
  padding: 0px 2px;
  margin: 0px 2px;
  border-radius: 1px;
  flex: none;
}

.product-breadcrumb {
  font-size: 12px;
  color: $grey-6-mayday;
  margin-right: 4px;
}
