@import "@/assets/scss/main.scss";

.add-condition-button {
  color: $grey-5-mayday;
  cursor: pointer;
}
.and-operator {
  color: $blue-mayday;
}
.collapse-condition-input {
  width: 50%;
}
.type-icon {
  width: 12px;
  height: 12px;
}
.collapse-title {
  color: $grey-5-mayday;
  text-transform: uppercase;
  border-right: 1px solid $grey-5-mayday;
}
.collapse-condition-content {
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #d5dae3;
  background: $grey-1-mayday;
  border-radius: 5px;
}
.collapse-bc {
  a {
    font-weight: 800;
    color: $grey-5-mayday;
  }
  .collapse-bc-last {
    color: $blue-mayday;
  }
}
.collapse-actions {
  color: $unknown-blue-1-mayday;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
