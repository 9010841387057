@import "@/assets/scss/main.scss";

.empty-state-alert {
  color: $grey-5-mayday;
}

.product-alert {
  :deep() .alert-inner--text {
    width: 100%;
  }
}

.product__alerts__item__details {
  :deep() * {
    margin-bottom: 0px;
  }
}

.tooltip-icon {
  cursor: pointer;
}

.notifications-wrapper {
  border-left: 4px solid $grey-2-mayday;
}
