@import "@/assets/scss/main.scss";

button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__dropdown {
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: $shadow-mayday !important;

  &-focus {
    animation: blink 1000ms linear;
  }

  &-hidden {
    overflow: hidden;
  }

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &-solved::before {
    background-color: $green-mayday;
  }
  &-archived::before {
    background-color: $red-mayday;
  }
  &-open::before {
    background-color: $blue-mayday;
  }

  &-display {
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 12px;
    color: $grey-8-mayday;

    &:hover {
      color: $blue-mayday;
    }
  }

  &-statuses {
    top: 0;
    right: 0;
    z-index: 1;
    position: absolute;
  }

  &-first {
    position: relative;
  }

  &-others {
    max-height: 0;
    overflow: hidden;
    transition: max-height 250m ease-in-out;
    position: relative;

    &-display {
      max-height: 10000px;
    }
  }
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: $grey-2-mayday;
  }
  100% {
    background-color: transparent;
  }
}
