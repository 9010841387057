@import "@/assets/scss/main.scss";

.action {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  &__primary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__cta {
      display: flex;
      align-items: center;

      .green-1 {
        color: $green-mayday;
      }

      .red-1 {
        color: $red-mayday;
      }

      .grey-btn {
        cursor: pointer;
        color: $grey-5-mayday;
        background-color: transparent;
        &:hover {
          color: $blue-mayday;
        }
      }

      &__btn {
        border: none;
        background-color: $blue-mayday;
        color: white;
        border-radius: 4px;
      }
    }
  }

  &__secondary {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__cta {
      cursor: pointer;
      &:hover {
        color: $blue-mayday;
      }
    }

    &__message {
      font-style: italic;
      border-left: 1px solid black;
    }
  }
}
