@import "@/assets/scss/main.scss";

/* NEW STYLE */
.indicator {
  height: 8px;
  width: 8px;
  margin-right: 4px;
  background-color: white;
  border-radius: 1px;
}

.draft {
  background-color: $grey-5-mayday;
}

.main-row {
  height: 32px;
}

.main-row:hover {
  box-shadow: $shadow-sm-mayday;
  border-radius: 1px;
}

.select-checkbox {
  margin-right: 8px;
  margin-left: 8px;
}

.select-checkbox {
  :deep() .el-checkbox__inner {
    width: 10px;
    height: 10px;
  }
}

.select-checkbox {
  :deep() .el-checkbox__inner::after {
    left: 2px;
    top: 0px;
    height: 5px;
  }
}

.select-checkbox {
  :deep() .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    top: 3px;
  }
}

.toggle-btn {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  margin-right: 4px;
}
.toggle-btn:hover {
  background-color: $grey-1-mayday;
}
.collection-focus-toggle-btn {
  background-color: $grey-1-mayday;
}

.node-item {
  padding-left: 8px;
  height: 24px;
}

.node-item .focus {
  z-index: 0;
}

.inputNode {
  position: absolute;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 80%;
  font-weight: 600;
  word-break: break-all;
  border: 0px;
  outline: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  height: 20px;
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.inputNode:focus {
  background-color: white;
  color: $grey-7-mayday;
}

/* END NEW STYLE */

.isDraft .inputNode {
  color: $grey-5-mayday;
}

.cursor {
  cursor: pointer;
}

.nav-item {
  border: 1px solid $grey-2-mayday;
  border-radius: 3px;
  background-color: white;
}

.vertical-divider {
  max-height: 18px;
}

.flag-icon {
  height: 18px;
  width: 18px;
}

.empty-text {
  margin-left: 10px;
  padding-left: 5px;
}

.empty-text p {
  font-size: 12px;
  font-style: italic;
  color: $grey-6-mayday;
}

.sortable-ghost {
  border-top: 2px solid rgba($blue-mayday, 0.1);
}

.inputNodeLabel {
  color: $grey-6-mayday;
  background-color: $grey-1-mayday;
  padding-left: 5px;
  border: 0px;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  height: 20px;
  line-height: 1.7;
}

.empty-folder {
  font-style: italic;
  color: $grey-3-mayday;
}

.focus {
  background-color: $grey-1-mayday;
  border-radius: 2px;
  margin-right: 4px;
}

.no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.toggle-icon {
  max-width: 20px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}

.no-style-button {
  background-color: transparent;
  border: none;
  text-align: center;
}

.icon-comment {
  padding-right: 0px;
}

.enlarge_button {
  right: 30px;
}
/*Navigation*/
.dragArea {
  min-height: 30px;
  padding-left: 20px;
}

.without_bullet {
  list-style: none;
}

.comment {
  margin-top: 5px;
  color: $grey-7-mayday;
  font-size: 12px;
}
.subject {
  color: $blue-9-mayday;
  font-size: 24px;
}

.message {
  color: $grey-7-mayday;
  font-size: 14px;
}

#right_arrow,
#down_arrow,
#options_selection {
  max-width: 15px;
  cursor: pointer;
}

/* Dropdown menu*/

.dropdown {
  padding: 0px;
}

.dropdown-item:hover {
  background-color: rgba($blue-mayday, 0.05);
  cursor: pointer;
}
.dropdown-item :hover i {
  color: $blue-mayday;
}

.dropdown-item p {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item span {
  margin-left: 6px;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  background-color: white;
  margin: auto;
}

.options_selection {
  max-width: 15px;
  color: $grey-5-mayday;
  cursor: pointer;
}

.options_selection:hover {
  color: $blue-mayday;
}

#children {
  padding: 5px;
  background-color: white;
  position: absolute;
}

#parent #children {
  visibility: hidden;
  right: 5px;
}

#parent:hover #children {
  right: 5px;
  position: relative;
  visibility: visible;
}
/* END Dropdown menu*/

/* End navigation*/

.nodeInputLabel {
  margin-left: 10px;
  font-size: 14px;
}

#subjectLabel {
  font-size: 20px;
}

.inputNode input {
  margin-bottom: 10px;
}

#inputComment {
  font-size: 14px;
  font-style: italic;
  color: $grey-7-mayday;
  margin-bottom: 10px;
  max-width: 500px !important;
}

#inputComment:focus {
  background-color: white;
}

#inputSubject {
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

#inputSubject:focus {
  color: $grey-7-mayday;
  background-color: white;
}

#inputUserId {
  font-size: 14px;
}

#inputGroupId {
  font-size: 14px;
}

#inputEmail {
  font-size: 14px;
}

.inputCom {
  margin-bottom: 5px;
  margin-top: 5px;
}

.inputSubject {
  margin-bottom: 5px;
  margin-top: 5px;
}

.inputAnswer {
  margin-bottom: 5px;
  margin-top: 5px;
}

.group-badge {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  padding-right: 2px !important;
  cursor: pointer;
}

.magic-badge {
  margin-left: 10px;
  color: $yellow-mayday;
  margin-top: -15px;
}

.assignee {
  background-color: $grey-2-mayday;
  padding-top: 5px;
  padding-left: 10px;
  color: $grey-6-mayday;
  font-style: italic;
  cursor: pointer;
}

/*.editor{
    font-family: sans-serif !important;
}*/

.modal-content {
  border-top: 2mm solid $blue-mayday;
}

.path_badge {
  font-size: 12px;
}

#secondary {
  border: 1px solid $grey-3-mayday;
}
.path_row {
  margin-left: 0.7rem;
}

.confirmation_button {
  margin: 10px;
}

.pointer {
  cursor: pointer;
}

.checkbox {
  visibility: hidden;
}

.checkbox:hover {
  visibility: visible;
}
