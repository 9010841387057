@import "@/assets/scss/main.scss";

.option-selection-dropdown {
  :deep() .dropdown-menu {
    box-shadow: $shadow-mayday;
  }
}
.max-width {
  max-width: calc(100% - 60px);
}

.item-row {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
    & .product-icon {
      color: $blue-mayday;
    }
  }
  .case-icon {
    height: 16px;
    width: 16px;
  }
}

.case__link {
  padding: 0px 12px 0px 10px;

  &__extra-padding {
    padding: 0px 0px 0px 36px;
  }
}
