@import "@/assets/scss/main.scss";

.analytics-contents-search-stats__container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  height: 100%;
}
.bottom-spacer {
  height: 32px;
}
