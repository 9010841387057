@import "@/assets/scss/main.scss";

.diag-step {
  border-radius: 2px;
}

.key-step-icon {
  margin-right: 10px;
}
.move-to {
  max-width: 18px;
}

.step-button {
  display: flex;
}

.magic-icon {
  max-width: 25px;
  margin-right: 10px;
}

.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding-right: 2px;
  padding: 2px;
}

.modify-step:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}

.diag-next-steps:hover .diag-next-steps-edit-options {
  display: flex;
  visibility: visible;
}

.diag-next-steps-edit-options {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
  gap: 4px;
}
