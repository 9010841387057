@import "@/assets/scss/main.scss";

.card {
  border: 1px solid $grey-4-mayday;
}
.product-viewer-item-card {
  :deep() .card-header {
    padding: 16px;
    background-color: $grey-1-mayday;
    text-transform: uppercase;
    border-bottom: 0px;
    .toggle-icon {
      cursor: pointer;
      color: $blue-mayday;
      .is-collapsed {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .card-body {
    padding: 0px;
    .card-main {
      padding: 16px;
    }
  }
}
