@import "@/assets/scss/main.scss";

.cursor-not-allowed {
  cursor: not-allowed;
}

.dragArea {
  min-height: 30px;
  padding-left: 28px;
}

.collection-one {
  padding-left: 0 !important;
}

.collection-two {
  padding-left: 16px !important;
}

.without_bullet {
  list-style: none;
}
