@import "@/assets/scss/main.scss";

.overview-container {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
