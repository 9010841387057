@import "@/assets/scss/main.scss";

.synonyms-table-row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid $grey-5-mayday;
  background-color: white;
  &__editing {
    background-color: $grey-1-mayday;
  }
  &__deleting {
    opacity: 0.5;
    pointer-events: none;
  }
}

.col-main {
  margin-left: 26px;
  width: 200px;
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 14px;
  transition: all 250ms ease-in-out;
  border-radius: 0px;
  padding: 0px;
  border: 1px solid transparent;
  background-color: transparent;

  &__editing {
    border-radius: 4px;
    padding: 13px 8px;
    margin-bottom: 20px;
    border: 1px solid $grey-5-mayday;
    background-color: white;
  }

  &__search {
    font-weight: 700;
  }

  &__not-search {
    opacity: 0.5;
  }

  &-input {
    outline: none;
    padding: 0px;
    border: none;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    &::placeholder {
      color: $grey-5-mayday;
    }
  }

  &__error {
    border: 1px solid $red-mayday;
  }

  &-label {
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.col-synonyms {
  width: 100%;
}

.synonyms-wrapper {
  width: 100%;
  display: flex;
  transition: all 250ms ease-in-out;
  gap: 8px;
  border-radius: 4px;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0px;
  border: 1px solid transparent;
  background-color: transparent;

  &__editing {
    padding: 8px;
    border: 1px solid $grey-5-mayday;
    background-color: white;
    transition: all 250ms ease-in-out;
    flex-wrap: wrap !important;
  }

  &__error {
    padding: 8px;
    border: 1px solid $red-mayday;
    background-color: white;
  }

  &-hint {
    padding-left: 8px;
    padding-top: 0;
    opacity: 0;
    height: 0;
    font-size: 12px;
    &__editing {
      opacity: 1;
      height: auto;
      padding-top: 4px;
    }
    transition: opacity 250ms ease-in-out, height 250ms ease-in-out,
      padding 250ms ease-in-out;
  }

  .synonyms {
    display: flex;
    align-items: center;

    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    height: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 0px 5px 8px;
    gap: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__editing {
      gap: 8px;
    }
    &__search {
      color: white;
      background-color: $blue-mayday;
    }
    &__not-search {
      opacity: 0.5;
    }
    transition: all 250ms ease-in-out;

    &-input {
      display: flex;
      align-items: center;
      height: 24px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      border: none;
      outline: none;
      &::placeholder {
        color: $grey-5-mayday;
      }
    }

    &-icon {
      cursor: pointer;
      width: 0px;
      opacity: 0;
      height: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      pointer-events: none;
      &__editing {
        pointer-events: auto;
        width: 12px;
        opacity: 1;
      }
      transition: opacity 250ms ease-in-out, width 250ms ease-in-out;
    }
  }
}

.icon-wrapper {
  outline: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  &-edit {
    color: $grey-7-mayday;
    background-color: transparent;
    &:hover {
      background-color: $grey-2-mayday;
    }
  }
  &-cancel {
    color: $red-mayday;
    background-color: rgba($red-mayday, 0.1);
    &:hover {
      color: white;
      background-color: $red-mayday;
    }
  }
  &-validate {
    color: $green-mayday;
    background-color: rgba($green-mayday, 0.1);
    &:hover {
      color: white;
      background-color: $green-mayday;
    }
    &__editing {
      color: white;
      background-color: $green-mayday;
    }
  }
  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.table-actions {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding-right: 20px;
  padding-bottom: 0px;

  &__editing {
    padding-bottom: 20px;
  }
  transition: padding 250ms ease-in-out;
}
