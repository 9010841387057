@import "@/assets/scss/main.scss";

.trust-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 2px 4px;
  height: 22px;
  width: fit-content;
  flex-shrink: 0;
  white-space: nowrap;
}

.trusted {
  color: $green-mayday;
  background-color: $green-1-mayday;
  border-radius: 2px;
  border: 1px solid $green-mayday;
}

.untrusted {
  color: $red-mayday;
  background-color: rgba($red-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $red-mayday;
}

.content-stamp {
  font-size: 10px;
  font-weight: 700;
  width: fit-content;
}

.trust-settings {
  visibility: visible;
  &-error:hover {
    color: $red-mayday;
  }
  &-success:hover {
    color: $green-mayday;
  }
}

.trust-cog-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $grey-5-mayday;
}

.content-stamp,
.trust-button {
  display: flex;
  align-items: center;
  line-height: normal;
}

.text-small {
  font-size: 1.1em;
}

.icon-spinner {
  color: black;
  margin-left: 8px;
}
