@import "@/assets/scss/main.scss";

.context-variables-settings-drawer__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &__column {
    width: 100%;
    padding: 0 8px;
  }

  &__actions {
    min-width: 40px;
  }
}
