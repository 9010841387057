@import "@/assets/scss/main.scss";

.header {
  background-color: $grey-1-mayday;
  border-bottom: 2px solid $grey-4-mayday;
  font-weight: bold;
}

.footer {
  padding: 8px 16px 8px 16px;
  background-color: $grey-2-mayday;
}

:deep() .el-radio-button__inner {
  width: 100% !important;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday !important;
}

.user-guiding {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;
  color: $blue-mayday;
}

.quit {
  width: 32px;
  height: 32px;
  color: $red-mayday;
}

.cancel-button {
  background-color: $grey-2-mayday;
  border: none;
  &:hover {
    color: $blue-mayday !important;
    background-color: $grey-2-mayday !important;
    border: none;
  }
}
