@import "@/assets/scss/main.scss";

.search-footer {
  border-top: 1px solid $grey-4-mayday;
  padding: 6px 4px;
}
.label {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.cursor-pointer {
  cursor: pointer;

  &:hover {
    color: $grey-7-mayday;
  }
}
.info-label {
  font-size: 12px;
  font-weight: 500;
  padding-left: 12px;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
