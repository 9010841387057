<template>
  <div>
    <p class="empty-category-info my-0">
      {{
        $t('settings.card-settings-options.content-parameters.empty-category')
      }}
    </p>

    <base-button
      class="mt-2"
      variant="primary"
      size="sm"
      @click="$emit('new-label')"
      :text="
        $t('settings.card-settings-options.content-parameters.create-label')
      "
    />
  </div>
</template>

<script>
export default {
  name: 'empty-category',
};
</script>

<style scoped>
.empty-category-info {
  font-size: 14px;
}
</style>
