@import "@/assets/scss/main.scss";

.cancel-confirm {
  margin: auto;
}
.body-container {
  overflow: auto;
}
.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 190px);
  min-height: 450px;
}
.side {
  width: 250px;
}
.main {
  flex: 0 0 auto;
  padding-right: 30px;
  width: calc(100% - 250px);
  overflow: auto;
}

.drawer-footer {
  height: 60px;
  padding: 20px;
}
