@import "@/assets/scss/main.scss";

.integration-header {
  max-height: 8.5rem;
  background-color: $grey-1-mayday;
  border-bottom: 1px solid $grey-4-mayday;
  position: relative;
}

.holder {
  width: 200px;
  flex: 0 0 auto;
}

.integration-header-info {
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title {
    color: black;
    font-size: 28px;
    font-weight: bold;
  }
  .description {
    color: $grey-8-mayday;
    font-size: 75%;
  }
}

.integration-header-logo {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: $shadow-lg-mayday;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 30px;
  z-index: 1;

  .integration-icon {
    object-fit: contain;
    color: $grey-6-mayday;
    font-size: 2.5rem;
  }
}

.save-button {
  margin-top: 28px;
  margin-right: 30px;
}
