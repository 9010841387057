@import "@/assets/scss/main.scss";

.content-label-icon {
  width: 16px;
  height: 16px;
  color: var(--label-color);
  border: 1px solid var(--label-color);
  background-color: rgba(var(--label-color), 0.1);
  border-radius: 2px;
  padding: 2px;
}
