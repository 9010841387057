@import "@/assets/scss/main.scss";

.toast {
  position: absolute;
  top: 80px !important;
  max-width: 250px;
  font-size: 14px;
  border-radius: 2px;
  border-left: 4px solid $red-mayday;
  background-color: white;
  overflow: hidden;
  z-index: 999;

  &__body {
    padding: 8px;
    background: $grey-1-mayday;
  }
}
