@import "@/assets/scss/main.scss";

:deep() .modal-dialog {
  margin: 1rem 2rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep() .modal-content {
  height: 80vh;
}

:deep() .modal-body {
  padding: 0.8rem;
}

.main {
  display: flex;
  flex-direction: row;
  height: 70vh;
}

.icon {
  cursor: pointer;
  color: $grey-5-mayday;
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    color: $blue-mayday;
  }
}

.disable {
  pointer-events: none;
  opacity: 0;
}

.selected {
  opacity: 1 !important;
}

.carousel-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 75vh;
  .carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    * {
      max-height: 50%;
      height: 15vh;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
        height: 100%;
        object-fit: contain;
        transition: transform 300ms ease;
        opacity: 0.75;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.gallery-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  .gallery {
    width: 100%;
    height: 100%;
    :deep() .vue-zoomer-gallery {
      height: 100%;
    }
  }
}

.close-icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  .close {
    width: 2rem;
    height: 2rem;
  }
}

.zoom {
  div + div {
    margin-left: 20px;
  }
}

@media only screen and (max-device-width: 768px) {
  :deep() .modal-dialog {
    margin: 0.5rem 1rem;
  }

  .main {
    flex-direction: column;
    height: 70vh;
    margin-top: 24px;
  }
  .carousel-main {
    flex-direction: row;
    width: 100%;
    height: 20vh;
    .carousel-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      * {
        min-width: 20%;
        img {
          cursor: pointer;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .cursor {
    transform: rotate(-90deg);
  }

  .gallery-main {
    height: 100%;
  }
}
