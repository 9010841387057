@import "@/assets/scss/main.scss";

.tag {
  font-size: 14px;
  padding: 5px 8px;
  line-height: 0.93;
  color: $grey-8-mayday;
  background: $grey-1-mayday;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
}
.selected-tag {
  border: 1px solid $blue-mayday;
  background: rgba($blue-mayday, 0.1);
}

.tag-icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
}
