@import "@/assets/scss/main.scss";

.parametric-content-archive-builder {
  margin: 0 !important;
  background-color: white;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}
