@import "@/assets/scss/main.scss";

.node-status {
  position: relative;
  z-index: 1;
  right: 0;
}

.ml-2px {
  margin-left: 2px;
}
.main-row {
  height: 32px;
  padding-left: 4px;
  margin-left: -4px;
}

.main-row:hover {
  box-shadow: $shadow-sm-mayday;
  border-radius: 1px;
}

.isDraft {
  color: $grey-5-mayday;
}

.is-dragged-over {
  border: 1px solid $blue-mayday;
  background-color: rgba($blue-mayday, 0.2);
  border-radius: 2px;
}

.is-being-dragged {
  border-left: 2px solid $blue-mayday;
}
