@import "@/assets/scss/main.scss";

.change {
  width: max-content;
  &:hover {
    .delete-icon {
      display: block;
    }
  }
}
.delete-icon {
  display: none;
  color: $grey-4-mayday;
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-top: 3px;
  &:hover {
    color: $blue-mayday;
  }
}
.delete-icon-wrapper {
  width: 16px;
}
.icon-wrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.icon {
  max-width: 12px;
  height: 12px;
}
