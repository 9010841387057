@import "@/assets/scss/main.scss";

.dashboard-container {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 32px;
  height: 68px;
}

.dashboard-tabs-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  flex: none;

  :not(:first-child) {
    margin-left: 30px;
  }
}

.dashboard-router-wrapper {
  padding-left: 2px;
  padding-top: 2px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard-router {
  background-color: white;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  box-shadow: $shadow-sm-mayday;
  border-top-left-radius: 16px;
}
