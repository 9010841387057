@import "@/assets/scss/main.scss";

.ai-drawer-options__button {
  color: $purple-9-mayday;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    color: $purple-5-mayday;
  }

  &.disabled {
    pointer-events: none;
    color: $grey-3-mayday;
  }

  .fa-chevron-right {
    transition: transform 0.2s ease-in-out;
  }

  &.expanded {
    .fa-chevron-right {
      transform: rotate(90deg);
    }
  }

  &__img-wrapper {
    // gap: 4px !important;
    .ai-drawer-options__button__img {
      height: 16px;
      width: 16px;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
  }
}

.ai-infos {
  background-color: rgba($purple-5-mayday, 0.2);
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 12px;
  font-size: 12px;
  width: 100%;
}

.ai-drawer-options__summaries {
  padding-left: 20px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;

  .ai-drawer-options__button {
    font-weight: 400;
  }

  &.expanded {
    max-height: 150px;
  }
}
