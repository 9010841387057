@import "@/assets/scss/main.scss";

.tags-wrapper {
  margin: 0px -4px;
  overflow: hidden;
  max-width: 450px;
}
.tags-popover {
  position: absolute;
  margin-top: 2px;
  padding: 2px 0px;
  background: white;
  border-radius: 4px;
  border: 0.5px solid $grey-4-mayday;
  z-index: 1000;
}
.max-width {
  width: 450px;
  flex-wrap: wrap;
}
.max-width-62 {
  max-width: 62%;
}
.max-content {
  width: max-content;
}
.tag-wrap {
  padding: 2px 4px;
}
.border-bold {
  border: 1.5px solid $grey-8-mayday !important;
}
.flex-none {
  flex: none;
}
.font-bold {
  font-weight: bold;
}
