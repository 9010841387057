@import "@/assets/scss/main.scss";

.review-icon{
  max-width: 16px;
  max-height: 16px;
}

ul {
  list-style: none;
}
