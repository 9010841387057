@import "@/assets/scss/main.scss";

.settings-label {
  margin-top: 8px;
  .title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }
}
.divider {
  border-bottom: 1px solid $grey-4-mayday;
  margin-bottom: 8px;
}
.info-icon {
  color: $grey-5-mayday;
  font-size: 12px;
}
