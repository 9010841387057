@import "@/assets/scss/main.scss";

.case-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}

.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.content-view-setting-modal__section {
  max-height: 60vh;
  overflow: auto;
}

.info-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}
