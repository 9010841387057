@import "@/assets/scss/main.scss";

.company-synonyms-title {
  font-size: 24px;
  font-weight: bold;
}

.company-synonyms-header {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  z-index: 10 !important;
}

.header-icon-wrapper {
  width: 36px;
  height: 36px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $blue-mayday;
    color: white;
  }
  transition: all 150ms ease-in-out;
}

.invitations-header-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.company-synonyms-footer {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  bottom: 0;
  z-index: 10 !important;
}

.company-synonym-main {
  padding: 64px 0;
  margin: 0 68px;
  overflow-y: auto;
}

.company-synonym-infos {
  margin-top: 16px;
  max-width: 512px;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    color: $grey-5-mayday;
    font-size: 12px;
  }
}

.company-synonym-body {
  margin-top: 16px;
}

.company-synonym-header-end {
  display: flex;
  gap: 8px;
}

.company-synonym-search {
  display: flex;
  align-items: center;
  width: 300px;
  gap: 4px;
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 8px;
  opacity: 0.8;

  &:focus-within {
    opacity: 1;
    box-shadow: $shadow-mayday;
  }

  &-input {
    border: none;
    outline: none;
    width: 100%;
  }

  &-close {
    cursor: pointer;
    color: $grey-7-mayday;
    &:hover {
      color: black;
    }
  }
}
