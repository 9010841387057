@import "@/assets/scss/main.scss";

.tutorial-video {
  border-radius: 5px;
}

.attribute-table-card :deep(.card-body) {
  padding: 0px;
}

.link-button:hover {
  :deep() a {
    color: white;
  }
}

.attribute-table {
  :deep() .el-table__body-wrapper {
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
  }
}

.attribute-table {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
