@import "@/assets/scss/main.scss";

.breadcrumb-hidden {
  cursor: pointer !important;
  position: relative !important;

  .breadcrumb-hidden-button {
    background-color: white;
    border: none;
    cursor: pointer !important;
    &:hover {
      color: $blue-mayday !important;
    }
  }

  .breadcrumb-dropdown {
    position: absolute !important;
    left: -8px !important;
    top: 22px !important;
    padding: 8px !important;
    background-color: white !important;
    border-radius: 4px !important;
    z-index: 10 !important;
    box-shadow: $shadow-mayday !important;

    &-item {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      font-size: 12px !important;
      color: $grey-7-mayday !important;
      font-weight: 500 !important;
      cursor: pointer !important;
      &:hover {
        color: black !important;
        font-weight: 700 !important;
      }
    }

    .breadcrumb-dropdown-item + .breadcrumb-dropdown-item {
      margin-top: 6px;
    }

    @for $i from 0 through 20 {
      $indent: 12px * $i;
      &-item-#{$i} {
        margin-left: $indent;
      }
    }
  }
}
