@import "@/assets/scss/main.scss";

.title-icon {
  color: $grey-6-mayday;
}

.children-count {
  color: $blue-mayday;
}

.parameter-title {
  h4 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
  }
}

.label-tag {
  margin-top: 2px;
  margin-bottom: 2px;
}

.tags {
  display: contents;
}
// TAGS DESIGN
.el-tag {
  height: 16px;
  line-height: 0px;
  border-radius: 2px;
  border-width: 0px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 0px;
  &:hover {
  }
  :deep() .el-icon-close {
    border-radius: 10%;
    position: inherit;
    &:hover {
      background-color: white;
    }
  }
  :deep() .el-tag__close {
    background-color: transparent;
    color: $grey-6-mayday;
    &:hover {
      color: $blue-mayday;
    }
  }
}
