@import "@/assets/scss/main.scss";

.options-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .options {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .section_title {
      font-size: 18px;
      font-weight: 700;
      color: $grey-8-mayday;
      border-top: 1px solid lightgray;
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      .option_content {
        display: flex;
        flex-direction: column;
        select {
          padding: 5px;
          border: 1px solid $grey-2-mayday;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          background-color: white;
        }
      }
    }
  }
}

textarea {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }

  &:disabled {
    border: 1px solid $purple-4-mayday;
    background-color: $purple-4-mayday;
  }
}

.analyze_label {
  font-size: 18px;
  font-weight: 700;
  color: $grey-8-mayday;
}
