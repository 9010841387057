@import "@/assets/scss/main.scss";

.new-knowledge-choose-type {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.main-title {
  margin-top: 50px;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  .title {
    font-size: 28px;
    font-weight: 700;
  }
  .description {
    color: $grey-7-mayday;
  }
}

.disappear {
  opacity: 0;
  pointer-events: none;
}

.option-cards {
  position: relative;
  width: calc(278px * 2 + 80px);
  margin: 0 auto;
}

.new-option-card {
  position: absolute;
  &-left {
    transition: all 250ms ease-in-out;
    right: calc(278px + 80px);
    left: 0;
  }
  &-right {
    transition: all 250ms ease-in-out;
    right: 0;
    left: calc(278px + 80px);
  }
}

.chosen {
  &-left {
    left: 0;
    right: unset;
  }
  &-right {
    left: 0;
    right: unset;
  }
}

.hidden-config {
  transition: all 250ms ease-in-out;
  position: absolute;
  left: calc(278px * 2 + 80px * 2);
  opacity: 0;
  pointer-events: none;
}

.appear-config {
  left: calc(278px + 80px);
  opacity: 1;
  pointer-events: auto;
}

.picture {
  height: 100%;
  width: 100%;
}
