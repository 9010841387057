@import "@/assets/scss/main.scss";

// reset selecteur
:deep() .el-select__input {
  margin-left: 5px;
}
:deep() .el-select .el-select__tags .el-tag {
  padding: 0.5rem;
}
:deep() .el-select .el-tag__close.el-icon-close::before {
  margin-top: 1px;
}
:deep() .el-drawer__body {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-view-setting-drawer__container {
  background-color: white;
  width: 400px;
  height: 100%;
  border-left: 1px solid $grey-3-mayday;
  z-index: 10;
  box-shadow: $shadow-mayday;
  overflow: auto;
  flex: none;
}

.content-view-setting-drawer__wrapper {
  overflow: auto;
  min-height: 80vh;
  max-height: 100vh;
  height: 100%;
}

.content-view-setting-drawer__footer {
  z-index: 1;
}

.content-view-setting-drawer__section {
  padding: 0;
}

:deep() .parameter-row {
  padding-bottom: 0.5rem;
}

.read-groups {
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
  background: $grey-1-mayday;
}
