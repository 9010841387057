@import "@/assets/scss/main.scss";

.color-picker-input {
  display: flex;
  height: 40px;
  border-radius: 4px;
  gap: 4px;
  border: 1px solid $grey-4-mayday;
  transition: border 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid $grey-5-mayday;
  }
}

.error-outline {
  border: 1px solid $red-5-mayday !important;
  border-radius: 4px;
}

.el-input-error-outline {
  :deep() .el-input__inner {
    border: 1px solid $red-mayday;
  }
}

:deep() .el-color-picker__trigger {
  border: none !important;
  pointer-events: none;
}
