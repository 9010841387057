@import "@/assets/scss/main.scss";

.hub-settings-drawer-content {
  display: flex;
  padding-top: 12px;
  padding-left: 30px;
  padding-right: 30px;
}
.hub-settings-drawer-content-left {
  padding-top: 60px;
  width: 170px;
  padding-right: 30px;
}
.hub-settings-drawer-content-right {
  flex: 1;
  & > div {
    margin-bottom: 16px;
  }
}

.filter-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.filter-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 14px;
}

.hub-categories-container {
  & > div {
    margin-bottom: 8px;
  }
}

.button-icon {
  margin-right: 4px;
}

.category-button {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: $grey-2-mayday;
    color: $blue-5-mayday;
  }
  :first-child {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.add-filter-button {
  width: 118px;
}
