@import "@/assets/scss/main.scss";

.reader-label {
  font-weight: bold;
  color: black;
  font-size: 12px;
}
.content-parameter-reader {
  border: 0.5px solid $grey-4-mayday;
  border-radius: 2px;
  background: $grey-1-mayday;
  font-size: 12px;
}

.delete-path {
  cursor: pointer;
  color: $grey-4-mayday;
  &:hover {
    color: $red-mayday;
  }
}
