@import "@/assets/scss/main.scss";

.group-viewer {
  display: flex;
  flex-direction: column;
  padding-block: 4px;
  padding-inline: 8px;
  background-color: $grey-1-mayday;
}
.icon {
  color: white;
  width: 11px;
}
.icon-wrapper {
  background-color: $grey-4-mayday;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  margin-bottom: 0;
  font-size: 13px;
}
.row-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid $grey-4-mayday;
  }
}
.checkbox-button {
  display: flex;
  align-items: center;
  margin: 0px;
  :deep() .el-checkbox__label {
    display: flex;
    gap: 8px;
    color: black;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
