@import "@/assets/scss/main.scss";

.workflow-main {
  padding: 12px;
  background-color: $grey-1-mayday;
}
.add-condition-cta {
  color: $grey-6-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.disabled {
  &:hover {
    color: $grey-6-mayday;
    cursor: not-allowed;
  }
}
