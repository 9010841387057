@import "@/assets/scss/main.scss";

.users-invitations-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 32px;
  width: 100%;
  margin-bottom: 8px;
  align-items: end;
}
.users-invitations-permission {
  display: flex;
}

.users-invitations-email-wrapper {
  margin-right: 16px;
  min-width: 300px;
}

.users-invitations-input-wrapper {
  margin-right: 16px;
  min-width: 200px;
}

.users-invitations-label {
  font-size: 14px;
}

:deep() .el-select .el-input .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.users-invitations-input {
  max-width: 128px !important;
}

.loading-input {
  height: 100%;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  :deep() input {
    border: 1px solid $red-mayday !important;
  }
}

.delete-button {
  margin-top: 20px;
}

.select-category {
  padding: 0 20px;
}
.empty-category {
  padding: 0 20px;
  font-size: 14px;
  color: $grey-5-mayday;
}

:deep() .el-tag__close {
  color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
  height: 16px;
  width: 16px;
  font-size: 12px;
  line-height: 1;
}

:deep() .el-tag__close:hover {
  color: white !important;
  background-color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
}

.multiple-emails {
  margin-left: 16px;
  font-size: 14px;
  color: $grey-7-mayday;
}
.text-error {
  color: $red-mayday;
}
.button-wrapper {
  height: 32px;
}
