@import "@/assets/scss/main.scss";

.prompt-callout {
  text-align: left;
  height: 71px;
  padding: 6px;
  background-color: $grey-2-mayday;
  font-size: 14px;
  word-break: break-word;
  overflow-y: auto;

  .prompt-related {
    display: block;
    font-style: italic;
    font-size: 12px;
  }

  :deep() em {
    font-weight: 700;
  }
}

.prompt-footer {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prompt-count {
  font-size: 12px;
  color: $grey-7-mayday;
}
.explain-occurrence {
  word-break: auto-phrase;
}
