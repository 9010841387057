@import "@/assets/scss/main.scss";

.add {
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 10px;
  height: 17px;
  border: solid 1px;
  padding: 4px;
  color: darkgray;
  background-color: transparent;
}

.add:not(.is-adding):hover {
  cursor: pointer;
  background-color: lightgray;
}

.wrapper {
  position: relative;
}

.search-concept-panel {
  position: absolute;
  top: 100%;
  z-index: 1;
  background-color: white;
  border: 1px solid $grey-4-mayday;
  padding: 10px;
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
}

.search-concept-panel-option {
  font-size: 10px;
  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }
}

.rotated-icon {
  rotate: 45deg;
}

.search-input {
  border: none;
  background-color: transparent;
}
