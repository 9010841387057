@import "@/assets/scss/main.scss";

.workflow-action {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
  &__grip {
    padding-top: 8px;
    &__icon {
      color: $grey-6-mayday;
      &:hover {
        cursor: grab;
        color: $blue-mayday;
      }
    }
  }
  &__main {
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin-left: 4px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0px 4px;

      &:hover {
        cursor: pointer;
      }

      &-expand {
        padding-bottom: 4px;
        margin-bottom: 8px;
      }

      &__title {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        align-items: center;
      }

      &__actions {
        &__icon {
          transition: transform 250ms ease-in-out;
          &:hover {
            color: $blue-mayday;
            cursor: pointer;
          }
          &-rotate {
            transform: rotate(-180deg);
            color: $blue-mayday;
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 8px;
      padding-top: 4px;
      &__actions {
        padding: 8px;
        display: flex;
        &-left {
          width: 100%;
          &-valid {
            :deep() .el-input-group__append {
              background-color: $blue-mayday;
              border: 1px solid $blue-mayday;
              color: white;
            }
          }
        }
      }
      :deep() .el-input__inner {
        padding-left: 8px;
        padding-right: 8px;
      }
      :deep() .el-input-group__append {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}
