@import "@/assets/scss/main.scss";

.context-variables-settings-drawer__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &__column {
    width: 45%;
    :deep() .el-input.is-disabled {
      .el-input__inner {
        background-color: white;
        border-color: white;
        color: black;
        cursor: default;
      }
    }
  }

  &__input {
    width: 100%;
    padding: 0 8px 0 0;
    :deep() .el-input__inner {
      padding: 0 8px;
    }
  }
  &__actions {
    visibility: hidden;
    min-width: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-edit,
    &-hover {
      visibility: visible;
    }

    &__btn {
      &-delete {
        &:hover {
          cursor: pointer;
          color: $red-mayday;
        }
      }
      &-edit {
        &:hover {
          cursor: pointer;
          color: $blue-mayday;
        }
      }
    }
  }
}
