@import "@/assets/scss/main.scss";

.column-header {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $grey-8-mayday;
  display: flex;
  align-items: center;
}

.table {
  height: calc(100% - 33.7px);
  background-color: white;
}

.table-header {
  border-bottom: 1px solid $grey-4-mayday;
}

.rows {
  height: calc(100vh - 48px - 50px);
  background-color: white;
  overflow: auto;
  padding-bottom: 48px;
}

.table-row {
  background-color: white;
  border-bottom: 1px solid $grey-4-mayday;
  align-items: center;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
  }
}

.table-footer {
  background-color: $grey-1-mayday;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 60px;
  z-index: 50;
  bottom: 0;
}

.count {
  font-size: 12px;
}

.el-select {
  width: 60px;
  height: 24px;
}

:deep() .el-input {
  height: 100% !important;
}

:deep() .el-select .el-input .el-input__inner {
  height: 100%;
  padding: 0;
  padding-left: 10px;
}

.empty-filtered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.download-icon {
  &:hover {
    cursor: pointer;
    color: $blue-mayday;
  }
}
