@import "@/assets/scss/main.scss";

.labels-editor {
  max-width: 400px;
  max-height: 300px;
  overflow: auto;
}

.el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: white !important;
  border: 1px solid $grey-3-mayday;
  color: $grey-7-mayday !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid $grey-3-mayday;
}

.input-new-tag {
  width: 120px;
  vertical-align: bottom;
  height: 100%;
}
