@import "@/assets/scss/main.scss";

.settings-dropdown-item-column-preview {
  display: flex;
  flex-wrap: wrap;
  &__category {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 40%;
    margin-right: 10px;
    margin-bottom: 8px;
    &__title {
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      padding-bottom: 4px;
      border-bottom: 1px solid $grey-4-mayday;
    }
    &__items {
      &__details {
        &-error {
          color: $red-mayday;
        }
      }
    }
  }
}
