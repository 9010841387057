@import "@/assets/scss/main.scss";

.modify-step {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.delete {
  &:hover {
    color: $red-mayday;
  }
}

.modify-step:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}

.visible {
  display: flex;
  visibility: visible;
}
