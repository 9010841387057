@import "@/assets/scss/main.scss";

.title-icon {
  color: $grey-6-mayday;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 2px;
  max-width: 16px;
  max-height: 16px;
}

.tag {
  color: $grey-6-mayday;
  font-size: xx-small;
}

.group-icon {
  border: 1.5px solid $grey-6-mayday;
  margin-right: 2px;
  width: 24px;
  height: 24px;
}

.group-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.02em;
}
