@import "@/assets/scss/main.scss";

.animated-text__container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.animated-text {
  display: inline-block;
  font-size: 14px;
  overflow: hidden;
  line-height: 21px;
  white-space: pre-wrap;
}

.animated-text::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 16px;
  background-color: $purple-5-mayday;
  animation: animated-caret 1s step-end infinite;
  animation-fill-mode: forwards;
  margin-left: 2px;
  border-radius: 2px;
  margin-bottom: -2px;
}

@keyframes animated-caret {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: $purple-5-mayday;
  }
}
