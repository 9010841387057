@import "@/assets/scss/main.scss";

.empty-loader {
  width: 100%;
  height: 200px;

  :deep() .el-loading-mask {
    background-color: transparent;
  }
}

.navigator-node-wrapper {
  padding: 16px;
  background-color: white;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
  overflow: auto;
  flex: 1 1 0%;
  border-right: 1px solid $grey-2-mayday;
}

.navigator-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
