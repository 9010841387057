@import "@/assets/scss/main.scss";

.new-case-input {
  min-width: 300px;
  width: fit-content;
  border-radius: 2px;
  border: none;
  line-height: 24px;
  padding: 2px;
  color: $blue-mayday;
}

.case-input {
  display: flex;
  align-items: center;
}

.case-input-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 6px;
  border-radius: 2px;
  color: $grey-5-mayday;
}

.new-case-option {
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-color: $blue-mayday;
    .add-icon {
      color: $blue-mayday;
    }
  }
}

.add-icon {
  max-width: 16px;
  color: $grey-5-mayday;
}
