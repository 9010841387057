@import "@/assets/scss/main.scss";

.content-preview-step-navigator {
  max-width: calc(100% - 120px);
  white-space: nowrap;
}
.label-wrapper {
  max-width: 300px;
  height: 12px;
}
.el-dropdown {
  width: 100%;
  .el-button-group {
    display: flex;
    width: 100%;
    .el-button {
      width: calc(100% - 30px);
    }
  }
}
