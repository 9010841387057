@import "@/assets/scss/main.scss";

.full-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.main-content {
  flex: 1 1 0%;
  max-height: 100vh;
  position: relative;
  overflow: auto;
}

.main-content-wrapper {
  height: 100%;
}

.widen-window {
  height: 100vh;
  width: 100%;
}

.enlarge-gif {
  max-width: 50vw;
}
