@import "@/assets/scss/main.scss";

.templates-navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding-left: 8px;
  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: black;
  }
  .count {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-9-mayday;
    background-color: $grey-1-mayday;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
}

.templates-navigation-footer {
  height: 78px;
}

.add-button {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: white;
    color: $blue-mayday;
  }
}

.templates-navigation-header-options {
  display: flex;
  align-items: center;
  height: 24px;
  border-right: 1px solid $grey-2-mayday;
  padding-right: 12px;
}
