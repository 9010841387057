@import "@/assets/scss/main.scss";

.color-picker {
  background: white;
  box-sizing: border-box;
  border-radius: 2px;
}
.color {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.focused {
  padding: 2px;
  border: 0.5px solid $grey-3-mayday;
}
.unfocused {
  padding: 2px;
  border: 1px solid transparent;
}
.no-color {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: $grey-2-mayday;
  border: 0.5px solid $grey-6-mayday;
  position: relative;
  &__bar {
    position: absolute;
    right: 0px;
    bottom: 4.5px;
    width: 12px;
    border: 0.1px solid $grey-6-mayday;
    transform: rotate(48.01deg);
  }
}
