@import "@/assets/scss/main.scss";

.languages-count-wrapper {
  color: $grey-5-mayday;
  font-size: 12px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: $grey-1-mayday;
}

.addition-header-wrapper {
  font-weight: 500;
  color: $grey-5-mayday;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.message-action-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-zendesk-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message-zendesk-attributes-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block: 12px;
  margin-block: 8px;
  border-block: 1px solid $grey-4-mayday;
}

.message-zendesk-attributes-title {
  font-weight: bold;
  color: black;
}

.message-zendesk-attributes-add-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid black;
  background-color: $grey-1-mayday;
  width: fit-content;
  padding-inline: 6px;
  font-size: 12px;
  border-radius: 4px;
  padding-block: 2px;
  cursor: pointer;
}

.attributes-header {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attribute-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}
.attribute-title {
  width: 60px;
}
.attribute-input {
  border: 0px;
  font-size: 14px;
  background-color: $grey-1-mayday;
}

.attribute-input:focus {
  outline: none;
}
