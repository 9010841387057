@import "@/assets/scss/main.scss";

.description {
  font-size: 12px;
  color: $grey-7-mayday;
  margin-bottom: 8px;
}
.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.badge-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.badge-icon {
  font-size: 14px;
  height: 16px;
  width: 16px;
  &__enable {
    color: $green-5-mayday;
  }
  &__disable {
    color: $grey-5-mayday;
  }
}
.badge-text {
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}
