@import "@/assets/scss/main.scss";

.delete-upload {
  color: $grey-5-mayday;
  max-width: 16px;
  &:hover {
    color: $red-mayday;
  }
}

.word-icon {
  max-width: 16px;
}
