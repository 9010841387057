@import "@/assets/scss/main.scss";

.diagram-tree {
  max-height: 60vh;
  overflow: auto;
  border: 0.5px solid $grey-4-mayday;

  .tree-node-by-flowy {
    background-image: url('../../../src/assets/backgrounds/tile.png');
    background-repeat: repeat;
    background-size: 30px 30px;
    background-color: $grey-1-mayday;
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;

    :deep() .header-content {
      padding: 2px;
    }

    :deep() #flowy-tree {
      max-width: 100%;
    }

    :deep() .icon {
      max-width: 16px;
      max-height: 16px;
      color: $grey-5-mayday;

      &:hover {
        color: $blue-mayday;
      }
    }

    :deep() .icon-grip {
      cursor: grab;
    }

    :deep() button {
      border: unset;

      &:focus {
        border: unset;
        outline: none;
      }
    }
  }
}

:deep() .modal-header {
  align-items: center !important;
}

.to-display-loader {
  width: 300px;
  height: 300px;
}

.step-node-body {
  width: 100%;
  border-top: 1px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
}

.body-content {
  border: solid 1px $grey-4-mayday !important;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  max-width: 282px;
  min-width: 100%;
  font-size: small;
  text-align: left;
}

.body-footer {
  margin-top: 8px;
  justify-content: space-between;
}

.count-wrapper {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-left: 2px;
}

.notifications-count {
  font-family: 'Circular-Loom';
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-icon-active {
  color: $blue-mayday;
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}

.notifications-icon-inactive {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}

.app-header {
  justify-content: space-between;
}

:deep() .el-tooltip__popper {
  border-style: none;
  padding: 3px !important;
}

.label {
  background-color: rgba($blue-mayday, 0.1);
  padding: 2px;
  margin: 1px;
  margin-right: 3px;
  border-radius: 4px;
  border: solid 1px $blue-mayday;
  color: $blue-mayday;
  font-size: small;
  max-width: 85px;
  overflow: hidden;
  max-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label:last-child() {
  margin-right: 0px;
}

.icon-redirect {
  width: 16px;
  height: 16px;
  padding: 3px;
  font-size: 10px;
}

.icon-redirected-content {
  color: $blue-mayday;
}

.switch {
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  border: 0.5px solid $grey-4-mayday;
  align-items: center;
  display: flex;
}

.icon-keyStep {
  color: $yellow-mayday;
  border-radius: 4px;
  background-color: rgba($yellow-mayday, 0.1);
  width: 16px;
  height: 16px;
  padding: 7px;
  margin-right: -2px;
}

.icon-step {
  color: $blue-mayday;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.1);
  width: 16px;
  height: 16px;
  padding: 7px;
  margin-left: -0.5px !important;
  margin-right: -2px !important;
}

.header-title {
  justify-content: start;
  text-align: left;
  width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: bold;
}

.img-root-wrapper {
  width: 30px;
  height: 30px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
}

.img-root {
  max-height: 24px;
  max-width: 24px;
}

.mayday-editor {
  max-height: 40vh;
  overflow: auto;
}
