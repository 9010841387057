@import "@/assets/scss/main.scss";

.ask-wrapper {
  background-color: $purple-1-mayday;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  &__expanded {
    max-height: 1000px;
  }
}
.ask-wrapper-show-less {
  max-height: 150px;
}
.ask-wrapper-show-more {
  max-height: 400px;
}
.open-ask {
  background-color: white;
  padding: 10px 24px 0px 24px;
  display: flex;
  justify-content: right;
  transition: all 0.5s ease-in-out;
}
.open-icon-wrapper {
  height: 24px;
  width: 24px;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.expand-enter-active,
.expand-leave-active,
.expand {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  &.ask-wrapper,
  &.open-ask {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.expand-enter-to {
  max-height: 400px;
}

.show-more-button-wrapper {
  width: fit-content;
  height: 25px;
  position: absolute;
  z-index: 1;
  top: 10px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $purple-5-mayday;
  color: $purple-5-mayday;
  padding: 8px 5px 8px 5px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-more {
  display: flex;
  justify-content: center;
  position: relative;
  &:hover .show-more-button-wrapper {
    color: white;
    background-color: $purple-5-mayday;
    cursor: pointer;
  }
}
