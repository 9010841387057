@import "@/assets/scss/main.scss";

.skeleton {
  background-image: linear-gradient(
    90deg,
    $grey-2-mayday calc(40% - 12px),
    $grey-1-mayday 50%,
    $grey-2-mayday calc(60% + 12px)
  );
  background-size: var(--skeletonWidth) 100%;
  background-position-x: -12px;
  animation: 1s loading ease-in infinite;
  border-radius: 2px;
}

@keyframes loading {
  to {
    background-position-x: calc(var(--skeletonWidth) - 12px);
  }
}
