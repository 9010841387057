@import "@/assets/scss/main.scss";

.md-stepper-group {
  :deep() .el-step__main .el-step__description {
    padding-top: 10px;
  }
}

.body-wrapper {
  max-height: 50vh;
  overflow: auto;
}

.modal-steps {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}
