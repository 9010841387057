@import "@/assets/scss/main.scss";

.common-navigator-node-icon {
  cursor: pointer;
  display: flex;
  z-index: 100;
}

.toggle-btn {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  margin-right: 4px;
}

.toggle-btn:hover {
  background-color: $grey-1-mayday;
}

.collection-focus-toggle-btn {
  background-color: $grey-1-mayday;
}

.toggle-icon {
  max-width: 20px;
}

.dropdown_icon {
  max-width: 20px;
  margin-right: 5px;
}
