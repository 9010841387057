@import "@/assets/scss/main.scss";

.satisfaction-title {
  font-size: 1em !important;
  font-weight: bold;
  min-width: max-content;
}

.satisfaction-feedback {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9em;
  color: $grey-5-mayday !important;
}

.satisfaction-feedback:hover {
  text-decoration: underline !important;
  color: $blue-mayday !important;
}

.thumb-button {
  border: 1px solid lightgray;
  width: 60px !important;
  height: 60px !important;
  padding: 20px;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey-6-mayday;
}

.thumb-up:hover {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday;
}

.is-success {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-color: $blue-mayday;
}

.thumb-down {
  &:hover {
    color: $red-mayday;
    background-color: rgba($red-mayday, 0.1);
    border-color: $red-mayday;
  }
}

.is-danger {
  color: $red-mayday;
  background-color: rgba($red-mayday, 0.1);
  border-color: $red-mayday;
}

.thumb-button .fa {
  font-size: 1.8em;
}

.thumbs-icon {
  width: 28px;
  height: 28px;
}
