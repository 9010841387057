@import "@/assets/scss/main.scss";

.product__attached__item-row {
  width: 100%;
  .product__attached__link {
    width: 100%;
    color: $grey-9-mayday;
    cursor: pointer;
    border-left: 2px solid $grey-2-mayday;
    &:hover {
      border-color: $blue-mayday;
      background-color: $grey-1-mayday;
    }
    .content-icon {
      height: 16px;
      width: 16px;
    }
  }
}
