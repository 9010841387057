@import "@/assets/scss/main.scss";

.hidden {
  display: none;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}
.image-manipulation {
  width: 100%;
}
:deep() .el-slider {
  width: 50%;
  margin-right: 10px;
  margin-left: 55px;
}

.action-icons {
  color: $grey-6-mayday;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: $grey-4-mayday;
}

.cropped-image img {
  max-width: 100%;
}

.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}

.cropped-img-display {
  cursor: pointer;
}

.cropper-display {
  width: 100%;
  max-height: 50vh;
}
