@import "@/assets/scss/main.scss";

.automations-menu {
  width: 100%;
  overflow-x: hidden;
}

.automations-menu-section-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: $grey-9-mayday;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey-2-mayday;
}

.automations-menu-section-option {
  width: 100%;
  overflow: hidden;
  margin-bottom: 4px;
}
