@import "@/assets/scss/main.scss";

.scope-switch {
  padding-top: 8px;

  & :deep() h4 {
    font-size: 13px;
  }
}
.scope-main-container {
  padding: 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  &.error {
    border: 1px solid $red-mayday;
  }
}

.actions-container {
  display: flex;
  justify-content: space-between;
}

.divider {
  padding-bottom: 8px;
  border-bottom: 1px solid $grey-4-mayday;
}
.checkbox {
  display: flex;
  width: 70px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
label {
  font-size: 14px;
  margin-bottom: 0;
}
.checkboxes-container {
  display: flex;
  width: 100%;
  max-width: 270px;
  justify-content: space-between;
  align-items: flex-start;
}
.checkbox-button {
  display: flex;
  width: 70px;
  flex-direction: column-reverse;
  align-items: center;
  gap: 4px;
  margin: 0px;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}

.description {
  margin-bottom: 0 !important;
}
