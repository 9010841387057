@import "@/assets/scss/main.scss";

#dropdown-button {
  visibility: hidden;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
}

.item-row:hover {
  &:hover {
    #dropdown-button {
      visibility: visible;
    }
  }
}
