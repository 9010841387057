@import "@/assets/scss/main.scss";

.add-workflow-action {
  &__form {
    margin: 14px;
    border: 1px solid $grey-5-mayday;
    border-radius: 8px;
    padding: 8px;
    &__action-selection {
      display: flex;
      flex-direction: row;
    }
    &__cta {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
}
