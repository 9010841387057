@import "@/assets/scss/main.scss";

.upload-demo {
  :deep() .el-upload {
    width: 100%;
  }

  :deep() .el-upload-dragger {
    width: 100%;
    height: 150px;
    padding: 16px;
  }
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-7-mayday;
  font-size: 14px;
  &:hover {
    background-color: $grey-1-mayday;
    color: $red-mayday;
  }
}

.download-link {
  font-size: 14px;
  color: $blue-mayday;
  &:hover {
    text-decoration: underline;
  }
}

:deep() .el-divider--horizontal {
  margin: 8px 0;
}
.files-upload-modal-body {
  height: 40%;
}
