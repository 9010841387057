@import "@/assets/scss/main.scss";

.danger-icon {
  font-size: 10px;
}

.orange {
  color: $yellow-mayday;
}

.red {
  color: $red-mayday;
}

.transparent {
  color: transparent;
}
