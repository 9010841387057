@import "@/assets/scss/main.scss";

.base-collapse {
  margin-bottom: 16px;
}

.base-collapse__wrapper {
  border: 1px solid $grey-3-mayday;
  border-radius: 4px;
}

.base-collapse__header {
  margin: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  color: $grey-8-mayday;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  &-icon {
    font-size: 12px;
    transition: transform 250ms ease-in-out;
    &:hover {
      color: $blue-mayday;
    }
    &-rotate {
      transform: rotate(-180deg);
      color: $blue-mayday;
    }
  }

  &:hover,
  &-active {
    color: black;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-title {
    font-weight: 700;
    font-size: 14px;
  }
}
