@import "@/assets/scss/main.scss";

.list-item-question {
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba($purple-5-mayday, 0.1);
  color: $purple-5-mayday;
  display: flex;
  align-items: center;
  justify-content: left;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  .title {
    font-size: 14px;
  }
}
.list-item-question.clickable:hover {
  cursor: pointer;
  background-color: $purple-5-mayday;
  color: white;
}
