@import "@/assets/scss/main.scss";

.path {
  color: $grey-4-mayday;
}

.diag-icon {
  max-height: 20px;
}

.diag-element {
  display: table;
}

.rule-to-string {
  :deep() .variable {
    font-weight: bold;
  }
}

.rule-to-string {
  :deep() .connector {
    color: $red-mayday;
  }
}

.rule-to-string {
  :deep() .rule {
    color: $yellow-mayday;
  }
}

.rule-to-string {
  :deep() .value {
    color: $blue-mayday;
  }
}

.rule-to-string {
  :deep() .final {
    text-decoration: underline;
  }
}

.nav-link-icon:hover {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}
