@import "@/assets/scss/main.scss";

input {
  font-size: 24px;
  line-height: 29px;
  border: none;
}
input::placeholder {
  font-weight: bold;
  color: $grey-6-mayday;
}
.title {
  font-size: 12px;
  margin-bottom: 10px;
  color: $grey-8-mayday;
  font-weight: bold;
}
.icon-delete {
  font-size: 22px;
  color: $grey-5-mayday;
  cursor: pointer;
}
.icon-filters {
  font-size: 16px;
  color: $grey-7-mayday;
  cursor: pointer;
}
