@import "@/assets/scss/main.scss";

.carousel-main {
  max-height: 60vh;
  overflow: auto;
}

:deep() .modal-header {
  align-items: center;
}

:deep() .modal-body {
  padding-top: 0px;
}

.trigger-slideshow {
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.carousel-slide {
  cursor: crosshair;
}
