@import "@/assets/scss/main.scss";

.content-title-container {
  display: flex;
  gap: 24px;

  button {
    outline: none;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    .title {
      align-self: flex-end;
      margin-left: 16px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.02em;
      color: $unknown-blue-1-mayday;
    }
  }
}

.subtitle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  .collection-icon {
    max-height: 24px;
    max-width: 24px;
  }
  .collection-name {
    margin-left: 16px;
    font-size: 16px;
  }
  .satisfaction-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 6px;
    border-radius: 4px;
    width: 86px;
    gap: 4px;

    .satisfaction-icon {
      width: 14px;
      height: 14px;
    }

    &.liked {
      background: rgba($green-5-mayday, 0.1);
      color: $green-5-mayday;
    }

    &.disliked {
      background: rgba($red-5-mayday, 0.1);
      color: $red-5-mayday;
    }
  }
}

%type-icon {
  display: block;
  background-size: cover;
  width: 34px;
  height: 34px;
}

.article-icon {
  @extend %type-icon;
  background-image: url('~@/assets/article-icon-v2.1.svg');
}
.diagnostic-icon {
  @extend %type-icon;
  background-image: url('~@/assets/diag-icon-v2.1.svg');
}
.collection-icon {
  color: $grey-5-mayday;
  width: 34px;
  height: 34px;
}
