@import "@/assets/scss/main.scss";

.icon-selector {
  cursor: pointer;
  width: 28px;
  height: 28px;
  color: $grey-5-mayday;
  border-radius: 2px;
  border: 1px solid transparent;
  &:hover {
    color: $grey-6-mayday;
    background-color: rgba($blue-mayday, 0.1);
    border-color: $blue-mayday;
  }
}

.icon-selector--selected {
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}

.label__span {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid;
  margin-right: 8px;
  &:hover {
    box-shadow: $shadow-mayday;
    cursor: pointer;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.color-selector-button {
  width: 24px;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 2px;
  background: none;
  padding: 0;
  margin-right: 8px;
  background-color: white;
  &:hover {
    box-shadow: $shadow-mayday;
  }
}
