@import "@/assets/scss/main.scss";

.keyword {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  border-bottom: 1px solid $grey-8-mayday;
  cursor: pointer;
}
.tag-wrapper {
  padding: 8px 16px 12px 16px;
}

.label {
  font-size: 12px;
  color: $grey-7-mayday;
}
.font-bold {
  font-weight: bold;
}
