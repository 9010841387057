@import "@/assets/scss/main.scss";

.no-cursor {
  cursor: not-allowed;
}

.unclickable {
  pointer-events: none;
}

.icon-col {
  display: inline-block;
}

.no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.toggle-btn {
  height: 25px;
  width: 25px;
  margin-top: 3px;
}
.toggle-btn:hover {
  border-radius: 3px;
  background-color: $grey-2-mayday;
}

.toggle-icon {
  max-width: 20px;
  cursor: pointer;
}

.node-item {
  padding-left: 5px;
}

.node-item .focus {
  z-index: 0;
}

.without_bullet {
  list-style: none;
}

#parent {
  height: 30px;
}

.inputNode {
  font-size: 80%;
  font-weight: 600;
  word-break: break-all;
  border: 0px;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85%;
  height: 20px;
  line-height: 1.7;
}

.inputNode:focus {
  background-color: white;
  color: grey;
}

.disable {
  color: grey;
  font-style: italic;
  cursor: not-allowed;
}
