@import "@/assets/scss/main.scss";

.download-link {
  font-size: 14px;
  color: $blue-mayday;
  &:hover {
    text-decoration: underline;
  }
}

.empty-state {
  font-size: 14px;
  color: $grey-7-mayday;
}
