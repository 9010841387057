@import "@/assets/scss/main.scss";

button {
  background: transparent;
  border: none;
  outline: none;
}

.thread__input {
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;

  &-focused {
    border: 1px solid $blue-mayday !important;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0;
  }

  &-editor {
    width: 100%;
    margin-top: 3px;
    max-height: 48px;
    overflow: auto;
    padding: 8px 0;
  }

  &-info {
    width: 100%;
    display: flex;
    gap: 8px;
  }

  &-username {
    padding: 8px 0;
  }

  &-actions {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    padding: 6px 0;

    &-buttons {
      display: flex;
      gap: 2px;

      &-disabled .thread__input-actions-button {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey-8-mayday;
      height: 24px;
      width: 24px;
      font-size: 14px;
      border-radius: 4px;
      transition: background-color 250ms ease-in-out;

      &-disabled {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }

      &:hover {
        background-color: $grey-3-mayday !important;
      }
      &:active {
        background-color: $grey-4-mayday !important;
      }
    }

    &-send {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      font-size: 14px;
      background-color: $blue-mayday;
      color: white;
      border-radius: 4px;
      transition: background-color 250ms ease-in-out;
      &-disabled {
        background-color: $grey-2-mayday !important;
        color: $grey-5-mayday !important;
        cursor: not-allowed !important;
      }
      &:hover {
        background-color: $blue-6-mayday;
      }
      &:active {
        background-color: $blue-7-mayday;
      }
    }
  }
}
