@import "@/assets/scss/main.scss";

.counts-container {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.language-container {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.flag {
  line-height: 22px;
}
.left-container {
  display: flex;
  gap: 4px;
}
.count-text {
  font-weight: 700;
}
