@import "@/assets/scss/main.scss";

.admin-contribution-modal-footer {
  padding-top: 0px;
}

.administration-contribution-modal-footer {
  width: 100%;
}

.overflow-modal {
  max-height: 80vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.contribution-modal__section {
  max-height: 50vh;
  overflow: auto;
}

.case-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}

.info-icon {
  color: $grey-5-mayday;
  margin-right: 8px;
}
