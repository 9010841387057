@import "@/assets/scss/main.scss";

.view-container {
  width: fit-content;
  padding-right: 8px;
}
.label-tag {
  margin-right: 4px;
}
.analytics-usages-table-container {
  .header {
    display: flex;
  }

  .views-filter-select {
    margin-left: 10px;

    :deep() .content-filter-select {
      .el-input > .el-input__inner {
        max-width: 160px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $grey-9-mayday;
      }

      .el-input .el-select__caret {
        color: $grey-9-mayday;
      }
    }
  }

  .analytics-usages-table-search {
    margin-left: auto;
    width: 320px;
    height: 40px;

    :deep() .el-input__prefix {
      padding-left: 5px;
      .search-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .analytics-usages-table {
    margin-top: 20px;
    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .no-label-icon,
      .article-icon,
      .diagnostic-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .rating {
        display: inline-flex;
        align-items: center;
        color: $grey-5-mayday;
        font-size: 12px;
        gap: 2px;

        .rating-icon {
          width: 10px;
          height: 10px;
          color: $yellow-mayday;
        }
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .user-icon {
        max-width: 16px;
        color: $grey-5-mayday;
        margin-right: 8px;
      }

      .user-group-icon {
        max-width: 16px;
        color: $grey-5-mayday;
        margin-right: 8px;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .user-name.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }

    :deep() .labels > .cell {
      white-space: nowrap;
    }
  }
}
