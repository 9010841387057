@import "@/assets/scss/main.scss";

.link {
  color: $purple-5-mayday;
  background-color: $purple-2-mayday;
  font-size: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.link-quote {
  color: $purple-5-mayday;
  background-color: $purple-2-mayday;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.label-tooltip {
  max-width: 300px;
}
.outdated-warning {
  color: $red-5-mayday;
}
