@import "@/assets/scss/main.scss";

.brain-task-toast__header {
  padding: 0px 8px 0px 8px;
  background: $grey-1-mayday;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  height: 40px;
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
  }
  &__close-icon {
    height: 16px;
    cursor: pointer;
  }
}
