@import "@/assets/scss/main.scss";

.sub-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 16px;
  font-family: Lato;
  font-style: normal;
  letter-spacing: 0.02em;

  .subtitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;
    color: $grey-5-mayday;
  }
  .group-label {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $grey-8-mayday;
  }
  .group-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .group-icon {
      color: $grey-5-mayday;
      margin-right: 15px;
    }
  }
  .user-labels-container {
    .label-tags {
      display: flex;
      column-gap: 8px;
      margin-top: 8px;
    }
  }
}
.upper-modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .graph {
    flex-basis: 70%;
  }

  .analytics-usage-stats-card {
    padding: 0px 16px;
    :deep() .stat-container {
      margin-top: 0px;
    }
    :deep() .stat-container:not(:first-child) {
      border-top: 2px solid $grey-2-mayday;
    }
    :deep() .stat-wrapper {
      padding: 16px 0px;
    }
  }
  .stats {
    width: 28%;
    .analytics-card-container {
      height: 100%;
    }
    .stat-footer {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.02em;
      color: $grey-6-mayday;
    }
  }
  .trend-badge {
    width: 90%;
    height: 90%;

    &.up {
      transform: rotate(-45deg);
    }
    &.down {
      transform: rotate(45deg);
    }
  }
}

.analytics-usage-modal-table {
  :deep() .analytics-card-body-container {
    padding: 0px 16px;
  }
  :deep() .custom-table {
    padding: 0 8px;
  }
  :deep() .item-count {
    padding: 0px 8px;
  }
  :deep() .usage-table-cell-info {
    margin-left: 8px;
  }
}

.star {
  color: $yellow-mayday;
  width: 12px;
  height: 12px;
}

.search-icon {
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
}
