@import "@/assets/scss/main.scss";

.parent-label {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
.is-last {
  color: $blue-mayday;
}
.not-last {
  border-right: 1px solid black;
}

.home-icon{
  width: 12px;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover{
    color: $blue-mayday;
  }
}
