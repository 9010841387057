@import "@/assets/scss/main.scss";

.archive-banner-wrapper {
  position: sticky;
  top: 0;
  background-color: $red-1-mayday;
  color: $grey-9-mayday;
  padding: 6px 12px;
  border-top-left-radius: 16px;
  display: flex;
  align-items: center;
}

.archive-banner-wrapper-icon {
  color: $red-mayday;
  margin-right: 6px;
}

.archive-banner {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions-container {
  display: inline-flex;
  gap: 8px;
  margin-left: 24px;
}
