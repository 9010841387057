@import "@/assets/scss/main.scss";

.tabs-results {
  :deep() .el-tabs__item {
    padding: 0px 10px;
  }
}
.tab-actions {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
}
.active-bar {
  color: $blue-3-mayday;
}
.icon {
  width: 16px;
  height: 16px;
}
.select-all {
  padding-left: 8px;
  margin-bottom: 6px;
  color: $grey-6-mayday;

  &:hover {
    color: $grey-7-mayday;
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    padding-left: 12px;
    cursor: pointer;
  }
}
.delete-cta {
  border-bottom: 1px solid $blue-mayday;
  cursor: pointer;
}
