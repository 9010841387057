@import "@/assets/scss/main.scss";

.product {
  background-color: white;
  box-shadow: $shadow-sm-mayday;
  overflow: auto;
  height: calc(100vh - 68px);
  width: 100%;
}

@media only screen and (max-device-width: 992px) {
  .side-pannel {
    display: none;
  }
}

.side-pannel {
  z-index: 0;
}
