@import "@/assets/scss/main.scss";

.view-container {
  width: fit-content;
  padding-right: 8px;
}
.analytics-contents-table-container {
  .header {
    display: flex;
  }

  .views-filter-select {
    margin-left: 10px;

    :deep() .content-filter-select {
      .el-input > .el-input__inner {
        max-width: 160px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $grey-9-mayday;
      }

      .el-input .el-select__caret {
        color: $grey-9-mayday;
      }
    }
  }

  .analytics-contents-table-search {
    margin-left: auto;
    width: 320px;
    height: 40px;

    :deep() .el-input__prefix {
      padding-left: 5px;
      .search-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .analytics-contents-table {
    margin-top: 20px;

    :deep() .el-table__body-wrapper {
      max-height: 40vh;
    }

    .satisfaction-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 6px;
      border-radius: 4px;
      width: 86px;
      gap: 4px;

      .satisfaction-icon {
        width: 14px;
        height: 14px;
      }

      &.liked {
        background: rgba($green-5-mayday, 0.1);
        color: $green-5-mayday;
      }

      &.disliked {
        background: rgba($red-5-mayday, 0.1);
        color: $red-5-mayday;
      }
    }

    .cell {
      .content-icon,
      .collection-icon,
      .views-icon,
      .no-feedback-icon,
      .article-icon,
      .diagnostic-icon,
      .analyze-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .content-icon {
        display: inline-block;
      }
      .article-icon {
        background-image: url('~@/assets/article-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }
      .diagnostic-icon {
        background-image: url('~@/assets/diag-icon-v2.1.svg');
        background-size: cover;
        background-position: center;
      }

      .analyze-icon {
        color: $grey-5-mayday;
        &:hover {
          color: $blue-mayday;
          cursor: pointer;
        }
      }

      .collection-title.deleted {
        color: $grey-5-mayday;
        font-style: italic;
      }

      .views-icon {
        &.flat {
          color: rgba($yellow-mayday, 0.8);
        }
        &.up {
          transform: rotate(-45deg);
          color: rgba($green-mayday, 0.8);
        }
        &.down {
          transform: rotate(45deg);
          color: rgba($red-mayday, 0.8);
        }
      }

      .no-feedback {
        color: $grey-5-mayday;
      }
    }
  }
  :deep() .collections > .cell {
    white-space: nowrap;
  }
}
