@import "@/assets/scss/main.scss";

.knowledge-cases {
  :deep() .el-collapse-item {
    background: transparent;
  }
}

.section-title {
  color: $grey-6-mayday;
  cursor: pointer;
  margin-bottom: 0px;
}

.product__list {
  border-left: 2px solid $grey-4-mayday;
  margin-left: 1.5rem;
  padding-left: 10px !important;
  list-style-type: none;
}

.product__button {
  background-color: transparent;
  border: none;
  color: $blue-mayday;
}

.product__button--collapsed {
  color: $grey-5-mayday;
}

.product__manual__collapsable,
.product__manual__item-row {
  width: 100%;

  a .product__manual__link {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
    color: $grey-9-mayday;
    &:hover {
      background-color: $grey-1-mayday;
      border-radius: 2px;
    }
  }
}

.content__result__link {
  border-left: 2px solid $grey-4-mayday;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

@media only screen and (min-device-width: 1024px) {
  .content-label-row {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    &:hover {
      overflow: visible;
      white-space: unset;
    }
  }
}

.Article {
  &:hover {
    border-color: $yellow-mayday;
  }
}

.Diagnostic {
  &:hover {
    border-color: $blue-mayday;
  }
}

.content__list {
  margin-left: 1.5rem;
  padding-inline-start: 0px;
}

.content__list__item-row {
  list-style: none;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.search-field {
  border-radius: 4px;

  :deep() .form-control {
    height: 26px;
  }
  :deep() .input-group-text {
    padding: 0 8px;
  }
}

.content-icon {
  max-width: 16px;
  max-height: 16px;
}

.display-more {
  cursor: pointer;
  &:hover {
    color: $blue-mayday !important;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.search__button {
  background-color: transparent;
  border: none;
  color: $grey-5-mayday;
}

.search__button--active {
  color: $blue-mayday;
}

.trigger-search-field {
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
