@import "@/assets/scss/main.scss";

.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s;
}

.scale-enter,
.scale-leave-to {
  transform: scale(0);
}

.scale-leave-from,
.scale-enter-to {
  transform: scale(1);
}

.connected-users-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  :last-child {
    margin-right: 0;
  }
  * {
    transition: all 0.2s;
    margin-right: -16px;
  }
  &:hover > * {
    margin-right: 0;
  }
}

.extra-users {
  width: 40px;
  height: 40px;
  flex: none;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: $grey-7-mayday;
  border: 3px solid white;
  font-size: 12px;
  font-weight: 700;
}
