@import "@/assets/scss/main.scss";

.mayday-feedback-editor {
  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 5px 0px;
  }
}

.mayday-contribution-editor {
  :deep() div[id^='tippy-'],
  :deep() .tippy-content,
  :deep() .tippy-box,
  :deep() .mayday-editor__bubble-menu-text-selection {
    visibility: hidden !important;
    display: none !important;
  }
}
