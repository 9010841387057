@import "@/assets/scss/main.scss";

button {
  background: transparent;
  border: none;
  outline: none;
}

input[type='file'] {
  display: none;
}
.md-btn:hover {
  box-shadow: $shadow-mayday;
  transform: translateY(-1px);
  cursor: pointer;
}
.add-button {
  min-width: 57px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-icon {
  color: $grey-5-mayday;
}
