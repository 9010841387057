@import "@/assets/scss/main.scss";

.icon {
  width: 24px;
  height: 24px;
}

.rule-step-option {
  border-right: 2px solid $grey-4-mayday;
}

.and-operator {
  color: $blue-mayday;
}

.add-condition-enable {
  cursor: pointer;
}

.add-condition-enable:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.add-condition-disable {
  cursor: not-allowed;
  font-size: 14px;
  color: $grey-5-mayday;
}
a {
  color: $blue-mayday !important;
}
.rule-section {
  color: $grey-5-mayday;
  font-size: 14px;
}

.collapse-actions {
  color: $unknown-blue-1-mayday;
  font-weight: 400;
  .collapse-actions-special {
    font-weight: 700;
    color: $blue-mayday;
  }
}
.collapse-condition-content {
  padding: 10px;
  border: 1px solid #d5dae3;
  background: $grey-1-mayday;
  border-radius: 5px;
}
.and-operator {
  color: $blue-mayday;
}

.rule-section {
  color: $grey-8-mayday;
}

.add-condition-btn {
  border-color: $grey-5-mayday;
  color: $grey-5-mayday;
}

.add-condition-btn:hover {
  border-color: $blue-mayday;
  color: white;
}

.rule-modal {
  max-height: 50vh;
  overflow: auto;
}

.add-condition-enable {
  cursor: pointer;
  font-size: 13px;
  color: $grey-4-mayday;
}
.add-condition-disable {
  cursor: not-allowed;
}
.logic-operator {
  width: 60px;
  border-right: 1px solid $grey-4-mayday;
}

.logic-select {
  :deep() .el-input__inner {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__inner:focus {
    height: 20px;
    border: none;
    padding-left: 0px;
    font-size: 13px;
  }
}

.logic-select {
  :deep() .el-input__icon {
    line-height: 20px;
  }
}

.name-input {
  :deep() .form-control {
    height: 20px;
    font-size: 13px;
    border-radius: 3px;
    width: auto;
    padding: 0px;
    border-color: transparent;
    color: black;
  }
}

.empty-automation-icon {
  color: $grey-6-mayday;
  width: 40px;
  height: 40px;
}
