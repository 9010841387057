@import "@/assets/scss/main.scss";

label {
  margin-bottom: 0px;
}
.parameter-row {
  padding-left: 16px;
  padding-right: 0px;
}

@media only screen and (max-device-width: 768px) {
  .parameter-row {
    padding-left: 0px !important;
  }
  .el-collapse__main {
    padding-left: 12px;
    border-left: 2px solid $grey-2-mayday;
  }
}

.show-more-text {
  cursor: pointer;
  color: $grey-6-mayday;
  font-size: 12px;
  &:hover {
    color: $blue-mayday;
  }
}

.el-collapse__main {
  padding-left: 12px;
  border-left: 4px solid $grey-2-mayday;
}
// EL-COLLAPSE DESIGN

.el-collapse {
  border-top: none;
  border-bottom: none;
}
:deep() .el-collapse-item__header,
:deep() .el-collapse-item__wrap {
  border-bottom: none;
}

:deep() .el-collapse-item__content {
  padding-bottom: 0px !important;
}

// RADIO DESIGN
.radio-button {
  border: 1px solid $grey-2-mayday;
  border-radius: 2px;
  padding: 2px 4px;
  &.is-checked {
    border-color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}

.el-radio {
  margin-left: 0px !important;
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  :deep() .el-radio__label {
    font-size: 12px;
  }
}

// CHECKBOX DESIGN
.el-checkbox {
  height: unset;
  margin-left: 0px !important;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 2px 4px;
  :deep() .el-checkbox__input {
    margin-bottom: 1px;
    // .el-checkbox__inner {
    //   border-radius: 8px;
    // }
  }
  :deep() .el-checkbox__label {
    font-size: 12px;
  }
}
