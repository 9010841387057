@import "@/assets/scss/main.scss";

.draft {
  color: $grey-5-mayday;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  border-color: 1px solid $grey-4-mayday;

  &.is-open {
    background-color: $grey-5-mayday;
    color: white;
  }
}
.published {
  color: $blue-mayday;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  border: 1px solid $blue-mayday;

  &.is-open {
    background-color: $blue-mayday;
    color: white;
  }
}

.publication-btn {
  border: 1px solid;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 4px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.text-small {
  font-size: 1.1em;
}

.item-icon-wrapper {
  width: 12px !important;
  height: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
