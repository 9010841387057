@import "@/assets/scss/main.scss";

.thread__timeline {
  position: absolute;
  height: 100%;
  width: 64px;
  top: 0px;
  right: 0px;

  &-date {
    font-weight: 500;
    font-size: 8px;
    line-height: 16px;
    color: $grey-6-mayday;
  }

  &-scroll {
    height: calc(100% - 32px);
  }

  &-bar {
    height: 100%;
    width: 3px;
    background-color: $grey-3-mayday;
    cursor: pointer;
  }

  &-handle {
    left: -2.5px;
    position: absolute;
    user-select: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $grey-7-mayday;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:hover {
      background-color: $blue-mayday;
    }
    &:active {
      background-color: $blue-mayday;
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    display: flex;
    align-items: center;
    &-date {
      margin-left: 12px;
      font-weight: 500;
      font-size: 8px;
      color: $grey-8-mayday;
    }
  }
}
