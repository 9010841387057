@import "@/assets/scss/main.scss";

.settings__general-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.settings__general-user {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.title {
  font-weight: 700;
  font-size: 12px;
  color: $grey-9-mayday;
}
.initials-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: $grey-6-mayday;
  b {
    font-weight: 700;
    color: $grey-9-mayday;
  }
}

.date-ago {
  margin-top: -8px;
}

:deep() .time-ago {
  font-weight: 700;
}

.divider {
  margin-top: 8px;
  margin-left: 28px;
}

.settings__general-policy {
  padding: 4px 8px;
  background: rgba($green-mayday, 0.1);
  border: 1px solid $green-mayday;
  border-radius: 2px;
  font-weight: 700;
  font-size: 12px;

  .title {
    text-transform: uppercase;
    color: $green-mayday;
  }

  .text {
    font-weight: 700;
  }
}
.untrusted {
  background: rgba($red-mayday, 0.1);
  border: 1px solid $red-mayday;
  .title {
    color: $red-mayday;
  }
}

.action {
  background-color: $grey-1-mayday;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cta {
    &__btn {
      border: none;
      background-color: $blue-mayday;
      color: white;
      border-radius: 4px;
    }
  }
}
