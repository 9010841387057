@import "@/assets/scss/main.scss";

.max-width {
  max-width: calc(100% - 100px);
}

.max-width-w-checkbox {
  max-width: calc(100% - 120px);
}

.list-item-label-wrapper {
  margin-left: 16px;
  width: 100%;
}

.item-label-wrapper {
  min-width: 0;
}

.content-label {
  z-index: 1;
}

.content-node-status {
  z-index: 1;
}

.option-selection-dropdown {
  :deep() .dropdown-menu {
    box-shadow: $shadow-mayday;
  }
}

.item-row {
  padding-left: 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.content-icon {
  max-width: 16px;
  max-height: 16px;
}

.options-selection {
  color: $grey-5-mayday;
  max-width: 12px;
  visibility: hidden;
  &:hover {
    color: $blue-mayday;
  }
}

.item-row {
  &:hover {
    .options-selection {
      visibility: visible;
    }
    .checkbox {
      :deep() .el-checkbox__input {
        visibility: visible;
      }
    }
  }
}

.checkbox {
  :deep() .el-checkbox__input {
    margin: auto;
    width: 14px;
    height: 14px;
  }
  :deep() .is-checked {
    visibility: visible;
  }
  :deep() .el-checkbox__inner {
    height: 14px;
    width: 14px;
  }
}

.dropdown-item {
  font-size: 12px !important;
  &:hover {
    color: $blue-mayday !important;
  }
}

.dropdown-item-danger {
  color: $red-mayday;
  background-color: rgba($yellow-mayday, 0.1);
}

.edit-icon {
  color: $grey-5-mayday;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  height: 29px;
}
