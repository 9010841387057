@import "@/assets/scss/main.scss";

.stat-footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: $grey-6-mayday;
}

.trend-badge {
  width: 90%;
  height: 90%;

  &.up {
    transform: rotate(-45deg);
  }
  &.down {
    transform: rotate(45deg);
  }
}

.analytics-ask-stats-card {
  :deep() .stat-cards-wrapper.flex-wrapper .stat-container {
    flex-basis: 33%;
  }
}

.top-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.bottom-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.status-container {
  height: 100%;
  padding: 12px;
}
.duration-container {
  padding: 12px;
  border-left: 1px solid $grey-4-mayday;
}

.duration-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.empty-state-container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &-icon {
    width: 100px;
    height: 100px;
    background-image: url('~@/assets/empty-state-data.svg');
    background-size: cover;
    background-position: center;
    display: inline-block;
  }

  &-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: black;
  }

  &-text {
    margin-bottom: unset;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-5-mayday;
  }
}
