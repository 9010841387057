@import "@/assets/scss/main.scss";

.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

:deep() {
  .code-editor .el-textarea__inner {
    background-color: $grey-1-mayday;
    border: none;
    padding: 1rem;
    resize: none;
  }
}

.action-list-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-plugin-entity-button {
  padding: 10px;
  border: none;
  cursor: pointer;
}

.add-plugin-entity-button:hover {
  background-color: $grey-1-mayday;
}

.delete-plugin-entity-button {
  border: none;
  cursor: pointer;
}

.item {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 8px;
  font-size: 14px;

  &__title {
    font-size: 18px;
    font-weight: 600;
  }
  &__description {
    color: $grey-6-mayday;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    max-width: 390px;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__icon {
    background: none;
    margin-right: 4px;
    color: $grey-6-mayday;
  }
}
.active {
  background: none !important;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s ease;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 100%;
}
.collapse-enter,
.collapse-leave-to {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
}

.delete-btn {
  width: 20px;
  cursor: pointer;
}
