@import "@/assets/scss/main.scss";

.card-item,
.eye-icon {
  &:hover {
    color: $blue-mayday;
  }
}

.node-wrapper {
  overflow: auto;
  max-height: 128px;
  padding-left: 16px;
  padding-right: 16px;

  .description {
    word-break: break-word;
  }
}

.footer-row {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 16px;
  margin-bottom: 4px;

  .description {
    border-left: 1px solid $grey-4-mayday;
    margin-left: 8px;
    padding-left: 8px;
  }

  .cta {
    cursor: pointer;
  }
}

.text-black {
  color: $grey-9-mayday;
}

.header-row {
  padding: 4px;
}

.home-card-icon {
  color: $grey-4-mayday;
  font-size: 48px;
  margin-top: 32px;
}

.collection-info {
  margin: 0 auto;
  margin-top: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;

  h3 {
    font-size: 18px;
    line-height: 24px;
    width: 200px;
    max-height: 48px;
    color: $grey-9-mayday;
    font-weight: bold;
    margin-bottom: 0;
    position: relative;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: $grey-7-mayday;
  }
}

.root-card-translation-tooltip {
  position: absolute;
  top: 6px;
  right: -24px;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px;
  border-radius: 4px;
  // Fix options dropdown going behind other with dynamic z-index based on item's index
  position: relative;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: black;
  box-shadow: $shadow-mayday;
  border-radius: 4px;
}

.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
  box-shadow: $shadow-mayday;
  border-radius: 4px;
}

.inputNodeLabel::placeholder {
  color: $grey-4-mayday;
}

.inputNodeLabel:focus,
.inputNodeLabel:active,
.inputNodeLabel {
  margin: 0px;
  padding: 0px;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
  color: $blue-9-mayday;
}

.empty-collection-icon {
  max-width: 70px;
}

/* Content Style */
.card-icon {
  max-width: 40px;
}

.card-title {
  margin-bottom: 0px !important;
  word-break: break-word;
  color: $grey-9-mayday;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.card-title-wrapper {
  margin-left: 12px;
  min-width: 0;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: calc(100% - 36px);
}

.card-title-wrapper-translation {
  width: calc(100% - 60px) !important;
}
