@import "@/assets/scss/main.scss";

.empty-state-main-container {
  margin-top: 68px;
  height: calc(100vh - 68px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: $shadow-mayday;
}

.empty-state-text-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
