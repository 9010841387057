@import "@/assets/scss/main.scss";

.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.red-mayday {
  color: $red-mayday;
}

.icon {
  width: 26px;
  height: 26px;
}
