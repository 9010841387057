@import "@/assets/scss/main.scss";

.label-modal-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.1);
  margin-bottom: 16px;
  .empty-label-icon {
    max-height: 60px;
    margin-top: -30px;
  }
  .explanation {
    word-break: break-word;
    margin-top: 8px;
  }
  .assign-label-subtitle {
    word-break: break-word;
  }
}

.label-select-container {
  padding: 16px;
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  word-wrap: normal;
  margin: auto 24px;
}
.option-group-title {
  display: flex;
  margin-left: 12px;
  .option-icon {
    margin-right: 4px;
  }
}
.el-select-dropdown__item {
  margin-left: 16px;
}

.label-select {
  :deep() .el-tag {
    background-color: rgba($blue-mayday, 0.1);
    box-shadow: unset;
    border-color: $blue-mayday;
    border-radius: 4px;
    color: $blue-mayday;
  }
  :deep() .el-tag__close {
    color: $blue-mayday !important;
  }
}

.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
