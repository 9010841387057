@import "@/assets/scss/main.scss";

.content-view-global-drawer {
  width: 50px;
  background-color: $grey-1-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &__is-active {
    background-color: $blue-mayday;
  }
  &__is-not-active {
    background-color: white;
  }
}
.icon {
  &__is-active {
    color: white;
  }
  &__is-not-active {
    color: black;
  }
}
