@import "@/assets/scss/main.scss";

.content-preview-diagnostic-step {
  padding: 8px;
  border: 1px solid $blue-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-mayday;
  &:hover {
    background-color: $blue-mayday;
    color: white;
    cursor: pointer;
  }
}
