@import "@/assets/scss/main.scss";

.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.large-select {
  width: 90%;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
