@import "@/assets/scss/main.scss";

.editor-navigator-vue-header {
  height: 68px;
}

.header-panel {
  width: 100%;
  padding-left: 12px;
}

.page-title {
  font-size: 12px;
  color: $grey-6-mayday;
  font-weight: 600;
}

.red-banner {
  background-color: $red-mayday !important;
  padding-left: 0;
  padding-right: 0;
}

.breadcrumb-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.options-header {
  div {
    margin: 0 4px;
  }
}
.import-files__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  color: $grey-7-mayday;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .import-files__icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}

.flex-none {
  flex: none;
}

.switch-label {
  font-size: 14px;
  margin-right: 10px;
  color: $grey-9-mayday;
}
