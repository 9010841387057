@import "@/assets/scss/main.scss";

.automations-container {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
  padding-left: 2px;
  padding-top: 24px;
}

.automations-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
}

.automations-menu-wrapper {
  width: 280px;
  padding: 24px 32px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $grey-2-mayday;
}

.automations-list-wrapper {
  flex: 1;
  padding: 24px 32px;
  overflow-y: auto;
  overflow-x: hidden;
}
