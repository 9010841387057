@import "@/assets/scss/main.scss";

.super-search-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $grey-1-mayday;
  background-clip: border-box;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}

.filter-wrapper-container {
  margin-top: -79px;
}

.colored-background {
  height: 15vh;
  width: 100vw;
  background-color: var(--custom-color);
}
