@import "@/assets/scss/main.scss";

.side-container {
  width: 100%;
  padding-top: 4.5rem;
  padding-inline: 30px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;
  span {
    margin-left: 10px;
  }
}

.date-since {
  color: $grey-8-mayday;
  .date {
    font-weight: 700;
  }
}
