@import "@/assets/scss/main.scss";

.plus-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.category-name {
  width: 250px;
}

.labels-count {
  font-size: 14px;
  font-weight: 500;
  width: 100px;
}
