@import "@/assets/scss/main.scss";

.knowledge-header {
  height: 64px;
}
.knowledge-label {
  color: var(--company-color);
}

.import-files__icon {
  color: $grey-5-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}

.translation-row {
  padding-right: 33px;
}
