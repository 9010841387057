@import "@/assets/scss/main.scss";

.analytics-card-container {
  :deep() .el-card__header,
  :deep() .el-card__body {
    padding: unset;
  }
  .analytics-card-body-container {
    position: relative;
    min-height: 200px;

    :deep() canvas {
      padding: 16px;
    }
  }

  .analytics-card-header-wrapper {
    padding: 0px 15px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .analytics-card-header {
      margin-left: 23px;
      flex-basis: 70%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 55px;
      letter-spacing: 0.02em;
    }
  }
}
