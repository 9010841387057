@import "@/assets/scss/main.scss";

.tags-wrapper {
  margin: 0px -4px;
}
.fix-height {
  height: var(--height);
}
.overflow {
  overflow: hidden;
}
.flex-none {
  flex: none;
}
.label {
  font-size: 12px;
  color: $grey-7-mayday;
}
.font-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
