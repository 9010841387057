@import "@/assets/scss/main.scss";

.icon-edit-icon-modal {
  width: 32px;
  height: 32px;
  color: $grey-7-mayday;
}

.icon-edit-hover {
  color: var(--company-color);
}

.chosen-icon {
  color: var(--company-color);
}

.icon-div {
  margin: 9px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid white;
  &:hover {
    background-color: $grey-1-mayday;
    cursor: pointer;
  }
}
.icon-div-chosen {
  background-color: $grey-1-mayday;
  border-color: $grey-5-mayday;
  color: v-bind(focusedIconColor) !important;
}
