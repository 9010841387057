@import "@/assets/scss/main.scss";

.min-w-0 {
  min-width: 0;
}

.inputNodeLabel:focus,
.inputNodeLabel,
.inputNodeLabel:active {
  outline: none;
  border: none;
  color: $grey-7-mayday;
  margin-bottom: 0px !important;
}

.card-title {
  color: $grey-9-mayday;
  margin-bottom: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  line-height: 29px;
}

.is-draft :deep() {
  color: $grey-5-mayday;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
