@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.empty-state-user-labels {
  margin-left: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .empty-state-labels-img {
    cursor: pointer;
    max-width: 40px;
    margin-right: 16px;
  }
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-title {
    color: black;
    margin-right: 1px;
  }
}
.settings-card-header-description {
  color: $grey-6-mayday;
  font-size: 80%;
}
.label-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.label-category {
  margin-top: 8px;
}
.new-category-input {
  margin-top: 24px;
}
