@import "@/assets/scss/main.scss";

.root-collection-card {
  color: $grey-7-mayday;
  border-radius: 2px;
  background: white;
  box-shadow: $shadow-mayday;
  cursor: pointer;
  &:hover {
    color: $blue-mayday;
  }
}
.collapse-parameter-icon {
  color: $grey-5-mayday;
}
