@import "@/assets/scss/main.scss";

.add-plugin-entity-box {
  border: 1px dashed;
  border-radius: 4px;
  border-color: $grey-4-mayday;
  color: $grey-5-mayday;
  transition: border-color 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: $grey-7-mayday;
  }
}
