@import "@/assets/scss/main.scss";

.https-warning {
  border-radius: 4px;
  padding: 8px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 14px;
  word-break: normal;
}
