@import "@/assets/scss/main.scss";

.title-wrapper {
  display: flex;
  align-items: center;

  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }

  .info-icon {
    margin-left: 10px;
    color: $grey-5-mayday;
    &:hover {
      color: $blue-mayday;
    }
  }
}
