@import "@/assets/scss/main.scss";

.stats-card-container {
  &.stat-border-left {
    border-left: 2px solid $grey-2-mayday;
  }

  .stat-cards-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    &.flex-wrapper {
      display: flex;
      .stat-container {
        flex-basis: 20%;
        padding-right: 16px;
      }
      .stat-container:not(:first-child) {
        border-left: 2px solid $grey-2-mayday;
        padding-left: 16px;
      }
    }
  }

  .empty-state-icon {
    width: 56px;
    height: 56px;
    display: block;
    align-self: flex-end;
  }

  .stat-container {
    margin-top: 16px;

    .stats-header {
      display: flex;
    }
    .stat-wrapper {
      display: flex;
      &:not(.no-extra-space) {
        justify-content: space-between;
      }
      align-items: center;

      .stat-name {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: $grey-8-mayday;
        margin-bottom: unset;
      }

      .stat-value-wrapper {
        height: 50px;

        %base-flex {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .empty-state-placeholder {
          @extend %base-flex;
          padding-top: 8px;
          gap: 16px;

          .empty-state-label {
            font-family: Lato;
            margin-bottom: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: black;
          }

          .empty-state-text {
            font-family: Lato;
            margin-bottom: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.02em;
            color: $grey-6-mayday;
          }
        }

        .stat-value {
          @extend %base-flex;

          > span:not(.empty-state) {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 0.02em;
            color: black;
          }

          .stat-badge {
            border-radius: 100%;
            height: 20px;
            width: 20px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            padding: 2px;

            &.el-tag--error {
              background: rgba($red-mayday, 0.1);
              color: $red-mayday;
              border: 1px solid $red-mayday;
            }

            &.el-tag--success {
              color: $green-mayday;
              border: 1px solid $green-mayday;
            }

            &.el-tag--flat {
              background: rgba($yellow-mayday, 0.5);
              color: $yellow-mayday;
              border: 1px solid $yellow-mayday;
            }
          }
        }
      }

      .stat-icon {
        color: $grey-5-mayday;
      }
      .blue {
        color: $blue-mayday;
      }

      .stat-icon,
      .custom-icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}
