@import "@/assets/scss/main.scss";

.builder-side-bar {
  width: 40px;
  margin-right: 24px;
  margin-left: calc(16% - 96px);
}

.builder-side-bar .sticky-top {
  top: 24px;
}
