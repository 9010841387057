@import "@/assets/scss/main.scss";

.breadcrumb-container {
  max-width: 100%;
}
.parent {
  position: relative;
}
.case-path-navigator__separator {
  width: 14px;
  height: 14px;
}
.house-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__clickable:hover {
    background-color: $grey-2-mayday;
    cursor: pointer;
  }
  &__not-clickable {
    color: $grey-6-mayday;
  }
}
.element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  &__clickable:hover {
    background-color: $grey-2-mayday;
    cursor: pointer;
  }
  &__clickable {
    padding: 2px;
    color: black;
  }
  &__not-clickable {
    color: $grey-6-mayday;
  }
  &__tooltip {
    font-size: 14px;
  }
}
