@import "@/assets/scss/main.scss";

:deep() .mayday-editor {
  padding: 0px;
}

.text-edition {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  word-break: break-word;
  padding-inline: 12px;
  min-height: 150px;
}
