@import "@/assets/scss/main.scss";

.sso-profile-settings {
  width: 100%;
  &__main {
    width: 100%;
    &__profiles {
      width: 100%;
      overflow: hidden;
      &.fill-height {
        min-height: calc(5 * 58px);
      }
      &__item {
        margin: 0;
        :deep(.title-container) {
          margin: 0;
        }
        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          overflow: hidden;
          height: 40px;
          color: $grey-8-mayday;
          &__container {
            display: flex;
            align-items: center;
            width: 100%;
            overflow: hidden;
            &__icon {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid $grey-3-mayday;
              border-radius: 2px;
              margin-right: 8px;
            }
            &__text {
              display: flex;
              flex-direction: column;
              width: calc(100% - 48px);
              overflow: hidden;
              font-weight: 700;
              &-error {
                color: $red-mayday;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}

:deep(.el-pagination) {
  margin: 10px auto 0;
  width: fit-content;
}
