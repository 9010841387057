@import "@/assets/scss/main.scss";

.actions-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.actions-card {
  font-size: 14px;
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actions-header {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
