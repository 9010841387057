@import "@/assets/scss/main.scss";

.title {
  font-size: 14px;
}

.states-container {
  padding-inline: 4px;
}

:deep() .dropdown-container {
  padding-left: 4px;
  padding-top: 0;
}
