@import "@/assets/scss/main.scss";

.template-disabled {
  opacity: 0.8;
  cursor: not-allowed;

  :deep() .add-template {
    pointer-events: none;
  }
}

.template {
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  background-color: transparent;
  padding: 4px 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 4px;
  }

  .template-info {
    display: flex;
    min-width: 0;
    .template-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .template-label {
      min-width: 0;
      .name {
        width: 100%;
        font-size: 14px;
      }

      .description {
        font-size: 12px;
        color: $grey-6-mayday;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .template-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: 8px;
    cursor: pointer;
    height: 24px;
    width: 24px;
    &:hover {
      background-color: $grey-2-mayday;
      border-radius: 4px;
    }
    font-size: 14px;
  }
}
