@import "@/assets/scss/main.scss";

.ai-drawer {
  position: fixed;
  background-color: white;
  bottom: 16px;
  width: 50%;
  z-index: 10;
  box-shadow: $shadow-mayday;
  border-radius: 12px;
  overflow: hidden;
}

.ai-drawer--header {
  background-color: $grey-1-mayday;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .ai-drawer--tabs {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: $grey-5-mayday;
    gap: 8px;

    .ai-drawer--tab {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 8px;
      gap: 4px;
      transition: all 0.2s ease-in-out;

      &.expanded {
        &.active {
          background-color: white;
          border-radius: 8px 8px 0 0;
          padding-bottom: 14px;
          margin-bottom: -8px;
        }
      }

      &.active {
        color: $purple-5-mayday;
      }

      &:hover {
        color: $purple-5-mayday;
      }

      .ai-drawer__badge-container {
        margin-top: -4px;
      }
    }
  }

  .ai-drawer--actions {
    display: flex;
    gap: 4px;
    align-items: center;

    .ai-drawer--button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-7-mayday;
      cursor: pointer;
      font-size: 16px;
      width: 24px;
      height: 24px;
      padding: 2px;
      border-radius: 4px;

      &.cancel {
        width: fit-content;
        font-size: 14px;

        &:hover {
          color: $red-mayday;
          background-color: transparent;
        }
      }

      svg {
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        color: $purple-5-mayday;
        background-color: $grey-3-mayday;
      }

      &.expanded svg {
        transform: rotate(180deg);
      }
    }
  }
}

.ai-drawer--body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;

  &.expanded {
    max-height: 300px;
    overflow: auto;
  }

  .ai-drawer--content-container {
    padding: 16px;
  }
}
