@import "@/assets/scss/main.scss";
.indicator {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: transparent;
  border-radius: 1px;
  background-color: #ced4d4;
}

.knowledge-editor-scrollbar-container {
  position: relative;
}

.knowledge-label {
  color: var(--company-color);
}

.parametric-home-knowledge-editor {
  .header-logo {
    max-width: 120px;
    max-height: 40px;
  }
}

.path-breadcrumb {
  width: calc(100vw - 620px);
  overflow: hidden;
}

.dropdown-toggle {
  padding: 5px;
  font-size: 12px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 3px;
}

.draft {
  border: 1px solid $grey-3-mayday !important;
  color: $grey-4-mayday !important;
}

.published {
  border: 1px solid $blue-mayday !important;
  color: $blue-mayday !important;
}

.dropdown-item {
  font-size: 12px;
}

.isDraft .last-step {
  color: $grey-4-mayday;
}

.first {
  display: none;
}

.breadcrumb-step {
  display: flex;
}

.breadcrumb-separator {
  max-width: 15px;
  max-height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.last-step {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-node {
  color: grey;
}

.article-node {
  color: $yellow-5-mayday;
}

.diag-node {
  color: $blue-mayday;
}

.main-breadcrumb {
  display: flex;
}

.header-icon-folder {
  height: 60px;
  width: 60px;
  text-align: center;
  margin-top: 3px;
}

.header-icon-div {
  height: 60px;
  width: 60px;
  text-align: center;
  border-right: 1px solid lightgrey;
}

.header-icon {
  max-height: 50px;
  margin-top: 5px;
}

.add-collection-card:hover .add-icon {
  opacity: 1;
}

.add-collection-card {
  border: 1px solid;
  border-color: $grey-2-mayday;
  border-radius: 4px;
  border-style: dashed;
  height: 200px;
  width: 300px;
  transition: all 0.3s;
  &:hover {
    background-color: white;
    border-color: #0081f905;
    box-shadow: $shadow-mayday;
  }
}

.creation {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.article-card {
  border-top: 5px solid $yellow-5-mayday;
}

.diag-card {
  border-top: 5px solid $blue-mayday;
}

.header-row {
  border-bottom: 1px solid lightgrey;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
}

.folder-builder {
  margin-top: 0px !important;
}

.hosting-card {
  background-color: #f2f4f9;
  padding: 10px;
}

.content-row {
  height: calc(100vh - 165px);
  margin-right: 0px;
  margin-left: 0px;
}

.content-col {
  padding: 0px;
}

.wrapper-div {
  height: calc(100vh - 80px);
  background-color: white;
}

/**/

.whole-card-pannel {
  width: calc(100vw - 60px);
  height: calc(100vh);
  border-radius: 0px;
}

.parametric-home-knowledge-editor {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-radius: 0px;
}

.hosting-container {
  bottom: 0;
  position: fixed;
  background-color: white;
}

/*TRASH*/
.trash-path {
  font-size: 12px;
}

.delete-btn {
  max-width: 20px;
}

.delete {
  margin: auto;
}

.trash-row {
  display: flex;
  cursor: pointer;
}

.trash-row:hover img {
  background-color: $grey-0_5-mayday;
}

.trash-elements {
  margin-right: 10px;
  background-color: $grey-0_5-mayday;
}

.trash-item:hover {
  background-color: rgba(116, 116, 116, 0.05);
  cursor: pointer;
}
/*END TRASH*/

.card-icon {
  max-width: 25px;
}

.row-title {
  text-transform: uppercase;
  color: $grey-4-mayday;
}

.delete-icon {
  width: 25px;
}

.delete-icon:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/*Choice card in modal*/
.language-selected {
  display: inline-flex;
}

.choice-row {
  min-height: 280px;
}

.choice-folder:hover {
  border-top: 5px solid $green-5-mayday;
  transition: all 0.1s ease-in-out;
  box-shadow: $shadow-sm-mayday;
  cursor: pointer;
}

.choice-diag:hover {
  border-top: 5px solid $blue-mayday;
  transition: all 0.1s ease-in-out;
  box-shadow: $shadow-sm-mayday;
  cursor: pointer;
}

.choice-article:hover {
  transition: all 0.1s ease-in-out;
  box-shadow: $shadow-sm-mayday;
  cursor: pointer;
}
/*End choice card in modal*/
.el-tooltip__popper {
  font-size: 80%;
  font-weight: 200;
  color: $blue-mayday;
}

.public-private {
  display: inline-flex;
  height: 20px;
}

.public {
  border-top: 5px solid $red-5-mayday;
}

.form-group {
  margin-bottom: 0px !important;
}
.path_badge {
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
}

.MyCustomHighlight {
  :deep() em {
    filter: brightness(70%);
    background-color: transparent;
  }
}

#secondary {
  color: $grey-4-mayday;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#secondary:hover {
  color: grey;
  cursor: pointer;
}

.edition-row {
  min-height: 350px;
  margin-left: 0px;
  margin-right: 0px;
}
.left-bar {
  padding-top: 25px;
  padding-left: 40px;
  width: 300px;
  min-height: 100vh;
  max-height: calc(100vh);
  overflow: auto;
  padding-bottom: 25px;
}

.active-item {
  background-color: rgba(0, 129, 249, 0.05);
  color: $blue-mayday;
}

.search-choice:hover {
  background-color: $grey-0_5-mayday;
  cursor: pointer;
}

.search-choice {
  list-style: none;
  margin-bottom: 8px;
  padding: 10px;
  padding-bottom: 1px;
}

.search-item-ancestors {
  color: $green-4-mayday;
  margin-bottom: 10px;
}

.search-item-body {
  :deep() p {
    color: $green-4-mayday;
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.active-item-Step,
.Step-li:hover {
  border-left: 3px solid $blue-mayday;
  border-radius: 5px;
}

.active-item-Article,
.Article-li:hover {
  border-left: 3px solid $yellow-5-mayday;
  border-radius: 5px;
}

.active-item-Folder,
.Folder-li:hover {
  border-left: 3px solid $green-5-mayday;
  border-radius: 5px;
}

.active-item-Diagnostic,
.Diagnostic-li:hover {
  border-left: 3px solid $blue-mayday;
  border-radius: 5px;
}

.Step-class {
  color: $blue-mayday;
}

.Diagnostic-class {
  color: $blue-mayday;
}

.Article-class {
  color: $yellow-5-mayday;
}

.Folder-class {
  color: $green-5-mayday;
}

.ais-StateResults {
  /*box-shadow: 10px 10px 10px #EAEAEA;*/
  border-radius: 5px;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  z-index: 1;
  background: white;
  margin-top: 10px;
}

.el-select {
  min-width: 100% !important;
}

.loader {
  position: absolute;
  top: 50vh;
  bottom: 50vh;
  width: 100%;
}
