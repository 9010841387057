@import "@/assets/scss/main.scss";

.workflow-input-wrapper {
  padding: 0 4px;
  &__header {
    &__title {
      font-weight: bold;
      color: black;
    }
  }
  &__footer {
    font-size: 12px;
    font-weight: 700;
    color: $grey-8-mayday;
  }
}
