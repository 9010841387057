@import "@/assets/scss/main.scss";

.results-input-section {
  padding-bottom: 16px;
}
.border-top {
  padding-top: 16px;
  border-top: 1px solid $grey-2-mayday;
}
.padding-bottom {
  padding-bottom: 16px;
}
.results-list-section {
  padding-top: 4px;
  border-top: 1px solid $grey-2-mayday;
}
.text-small {
  font-size: 12px;
}
