@import "@/assets/scss/main.scss";

.backlink-button {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  &:hover {
    cursor: pointer;
    background-color: $grey-2-mayday;
    border-radius: 4px;
  }
  &__icon {
    font-size: 14px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__dropdown {
    &__title {
      padding: 2px 8px;
      top: 0px;
      position: sticky;
      border-bottom: 1px solid $grey-4-mayday;
      background-color: white;
      z-index: 1;
    }
    :deep() #dropdown {
      padding: 0 0;
      left: 0px;
      right: auto;
      max-width: 350px;
      max-height: 280px;
      overflow: scroll;
      // width: fit-content;
    }
    :deep() .dropdown-divider {
      margin: 0px;
    }
    :deep() .content__list__item-row {
      padding-top: 8px;
    }

    // :deep() .content__result__link {
    //   width: 50%;
    // }
  }
}
