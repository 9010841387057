@import "@/assets/scss/main.scss";

.users-invitations-title {
  font-size: 24px;
  font-weight: bold;
}

.users-invitations-header {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  z-index: 10 !important;
}

.header-icon-wrapper {
  width: 36px;
  height: 36px;
  background-color: $blue-mayday;
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitations-header-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: white;
}

.users-invitations-footer {
  background-color: white;
  padding: 16px;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 64px;
  position: fixed;
  bottom: 0;
  z-index: 10 !important;
}

.users-invitation-main {
  padding: 64px 0;
  margin: 0 68px;
  overflow-y: auto;
}

.users-invitation-infos {
  margin-top: 16px;
  max-width: 512px;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    color: $grey-5-mayday;
    font-size: 12px;
  }
}

.users-invitation-body {
  margin-top: 16px;
}
.invitations-count {
  color: $grey-7-mayday;
  font-size: 14px;
  margin-right: 8px;
}
.text-error {
  color: $red-mayday;
}
