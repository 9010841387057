@import "@/assets/scss/main.scss";

.settings-list {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.settings-wrapper {
  max-width: 60vw;
}
