@import "@/assets/scss/main.scss";

.case-icon {
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 2px;
  color: $grey-5-mayday;
}
.case-option {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px;
  .case-icon {
    border: 1px solid transparent;
  }
  .case-option-label {
    padding: 4px;
    line-height: 16px;
  }
  &:hover {
    .case-option-label {
      background-color: $grey-1-mayday;
    }
    .case-icon {
      background-color: $grey-1-mayday;
      cursor: pointer;
      border: 1px solid $grey-4-mayday;
    }
  }
}

.case-options-row {
  &:hover {
    .case-count {
      color: $blue-mayday;
    }
  }
}

.new-case-option {
  width: fit-content;
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  color: $grey-4-mayday;
  &:hover {
    color: white;
    background-color: $blue-mayday;
    cursor: pointer;
    border-color: $blue-mayday;
  }
}
