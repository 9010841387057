@import "@/assets/scss/main.scss";

:deep() .el-tabs__header {
  margin: 0;
  height: 40px;
  border-bottom: 1px solid $grey-4-mayday;
}

:deep() .el-tabs__item {
  padding: 0 !important;
  margin: 8px 0;
  height: 24px;
  line-height: 24px;
  color: $grey-7-mayday;
  &:hover {
    color: $blue-3-mayday;
  }
}

:deep() .el-tabs__active-bar {
  height: 2px;
}

:deep() .el-tabs__item.is-active {
  margin: 8px 0px;
  background: rgba($blue-mayday, 0.1);
  border-radius: 2px;
  color: $blue-mayday;
}

:deep() .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.settings__menu-tabs {
  user-select: none !important;
  // box-shadow: inset 0 0 0 2px black;
}

.settings__menu-tab {
  margin: 0 8px;
}

.tabs-icon {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}
