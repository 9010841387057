@import "@/assets/scss/main.scss";

.loading-bar {
  width: 100%;
  height: 16px;
  background-size: 400% !important;
  background: linear-gradient(
    100deg,
    $grey-2-mayday 30%,
    $grey-1-mayday 50%,
    $grey-2-mayday 70%
  );
  border-radius: 4px;
  animation: bar-idle 1.2s ease-in-out infinite, bar-open 0.4s ease-in-out;
}

.grow-leave-active {
  animation: bar-close 0.4s ease-in-out;
}

@keyframes bar-idle {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes bar-close {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes bar-open {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
