@import "@/assets/scss/main.scss";

.flag-icon {
  width: 10px;
  height: 10px;
}
.summary-container {
  background-color: $grey-1-mayday;
  border-radius: 5px;
}

.restriction-details {
  margin-left: 24px;
}

.authorized-collection {
  list-style: georgian inside url('~assets/folder-open-do.svg');
}
