@import "@/assets/scss/main.scss";

.product-agora-documentation__error {
  background-color: rgba($red-mayday, 0.1);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  small {
    color: $red-mayday;
  }

  &__description {
    line-height: 14px;
  }

  &__cta {
    padding: 0px 10px 0px 10px;
    border-radius: 4px;
    background-color: white;
    color: $grey-8-mayday;
    cursor: pointer;
    font-size: 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
  }
}
