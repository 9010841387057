@import "@/assets/scss/main.scss";

.thread__container {
  margin-bottom: 16px;
}

.thread__wrapper {
  border: 1px solid $grey-2-mayday;
  border-radius: 4px;
}

.thread__scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.thread__scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  width: calc(100% - 64px);
}

.thread__header {
  margin: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: $grey-1-mayday;
  color: $grey-8-mayday;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  &-icon {
    font-size: 12px;
    transition: transform 250ms ease-in-out;
    &:hover {
      color: $blue-mayday;
    }
    &-rotate {
      transform: rotate(-180deg);
      color: $blue-mayday;
    }
  }

  &:hover,
  &-active {
    color: black;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-title {
    font-weight: 700;
    font-size: 14px;
  }

  &-badge {
    content: '';
    width: 10px;
    height: 10px;
    background-color: $grey-6-mayday;
    border-radius: 50%;
    &-active {
      background-color: $blue-mayday;
    }
  }
}

.thread__content {
  position: relative;

  &-delete {
    z-index: 2;
    position: absolute;
    inset: 0;
    background-color: $grey-1-mayday;
    color: $blue-mayday;
    animation: fade-in 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &-loader {
      font-size: 24px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fade-in 250ms ease-in-out;
    }
    &-confirm {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin-bottom: 8px;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 200px;
    width: 300px;
    margin: 16px auto;
    gap: 4px;

    &-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    &-description {
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $grey-6-mayday;
      margin-bottom: 8px;
    }
  }
}

.thread__messages {
  padding: 12px;
  max-height: 200px;
  overflow: auto;
}

.thread__dropdown-wrapper + .thread__dropdown-wrapper {
  margin-top: 8px;
}

.thread__input-wrapper {
  padding: 0 12px 12px 12px;
}
