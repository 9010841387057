@import "@/assets/scss/main.scss";

.new-knowledge-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.new-knowledge-header {
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 68px;
  width: 100%;
}

.new-knowledge-wrapper {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-top: 8px;
}

.new-knowledge-choose {
  flex: 1;
  overflow: hidden;
  background-color: white;
  padding: 24px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-mayday;
}

.new-knowledge-footer {
  padding: 10px;
}
