@import "@/assets/scss/main.scss";

.link {
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.callouts-header {
  background: $purple-5-mayday;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 16px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.callouts-content {
  background: $purple-1-mayday;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 8px;
}
