@import "@/assets/scss/main.scss";

.view-container {
  width: fit-content;
  padding-right: 8px;
}

.article-icon {
  background-image: url('~@/assets/article-icon-v2.1.svg');
  background-size: cover;
  background-position: center;
}
.diagnostic-icon {
  background-image: url('~@/assets/diag-icon-v2.1.svg');
  background-size: cover;
  background-position: center;
}

.content-icon {
  display: inline-block;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.collections-cell {
  display: flex;
  align-items: center;
}

.collection-title.deleted {
  color: $grey-5-mayday;
  font-style: italic;
}

.collection-icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
:deep() .collections > .cell {
  white-space: nowrap;
}

.views-icon {
  &.flat {
    color: rgba($yellow-mayday, 0.8);
  }
  &.up {
    transform: rotate(-45deg);
    color: rgba($green-mayday, 0.8);
  }
  &.down {
    transform: rotate(45deg);
    color: rgba($red-mayday, 0.8);
  }
}
