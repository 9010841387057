@import "@/assets/scss/main.scss";

.search-admin-modal {
  :deep() .modal-dialog {
    max-width: 100% !important;
    margin: 0px;
    height: 100%;
  }

  :deep() .modal-body {
    height: 80vh !important;
  }

  :deep() .modal-content {
    background-color: transparent !important;
    box-shadow: unset;
    -webkit-box-shadow: unset;
    height: 100%;
  }
}

.search-main {
  width: 50%;
  margin-top: 30px;
  max-height: calc(100% - 30px);
}

.search-admin-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100% !important;
}

.content-sidebar-viewer {
  height: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 8px 0px 0px 8px;
}
.search-input {
  box-shadow: $shadow-lg-mayday;
  -webkit-box-shadow: $shadow-lg-mayday;
  border-radius: 8px 8px 0px 0px;
}
.search-dropdown {
  max-height: calc(100% - 60px);
  overflow: auto;
  background: white;
  border-radius: 0px 0px 8px 8px;
  box-shadow: $shadow-lg-mayday;
  -webkit-box-shadow: $shadow-lg-mayday;
}

.overflow {
  max-height: 40vh;
  overflow: auto;
}

.search-correction {
  margin-left: 4px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  white-space: break-spaces;
  &:hover {
    color: $blue-mayday !important;
    text-decoration: underline !important;
  }
}

.search-autocomplete {
  margin-left: 4px;
  font-weight: 700;
  cursor: pointer;
}

.sidebar-transition-wrapper {
  width: calc(25% - 70px);
  height: 100%;
  margin-left: 70px;
  position: fixed;
  top: 0;
  right: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}

.pointer-events-none {
  pointer-events: none;
}
.search-header {
  position: absolute;
  right: 12px;
}
