@import "@/assets/scss/main.scss";

.text-small {
  color: $grey-5-mayday;
  font-size: 0.9em;
}

.grey-six {
  color: $grey-6-mayday;
}

.section {
  background-color: $grey-1-mayday;
  border-radius: 4px;

  :deep() .el-cascader {
    width: 100%;
  }
}

.case-cascader {
  :deep() .el-checkbox {
    margin-bottom: 0px;
  }
}
