@import "@/assets/scss/main.scss";

.tag {
  font-size: 14px;
  padding: 5px 8px;
  line-height: 0.93;
  color: $grey-8-mayday;
  background: $grey-1-mayday;
  border-radius: 2px;
  &__clickable {
    cursor: pointer;
  }
}

.tag-icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
}

.icon-close {
  margin-left: 8px;
  font-size: 12px;
  color: $grey-6-mayday;
}
