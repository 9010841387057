@import "@/assets/scss/main.scss";

.select-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.select-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
.select-sub-description {
  font-size: 12px;
  color: $grey-7-mayday;
  font-style: italic;
}
.selector {
  width: 80px;
}
