@import "@/assets/scss/main.scss";

.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.category {
  :deep() .item-editor__input--category {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: inherit;
    text-transform: none;
    text-align: left;
  }
}
