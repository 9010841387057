@import "@/assets/scss/main.scss";

.case-viewer-header-title__title {
  margin-bottom: 8px;
  line-height: 57.6px;
}

.case-viewer-header-title {
  input {
    outline: none;
    border: none;
    line-height: 55.6px;
  }
}

.edit-title {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.fake-loader {
  width: 100%;
  height: 37px;

  &__wrapper {
    display: flex;
    padding: 14px 0;
  }
}

.case-viewer-header-title:hover .edit-title {
  visibility: visible;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    border-radius: 3px;
    background-color: $grey-2-mayday;
    color: $blue-mayday;
  }
}
.modify-icon {
  width: 12px;
  height: 12px;
}
